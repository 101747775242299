import './WabaTemplateHeader.scss';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { wabaTemplateComponentHeaderFormats } from '../../../../templatesTypes';
import { WabaTemplateSchema } from '../../../../api';
import { SelectPosition, useVariable } from '../../../../utils/waba';
import Button from '../../../../../../components/Button';
import Switch from '../../../../../Settings/Switch/Switch';
import Textarea from '../Textarea';
import Counter from '../Counter';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';


const VARIABLES_MAX = 1;
const HEADER_MAX_LENGTH = 60;

function WabaTemplateHeader() {
  const { t } = useTranslation();
  const [position, setPosition] = useState<SelectPosition>();
  const { setValue } = useFormContext<WabaTemplateSchema>();
  
  const formatTabs = wabaTemplateComponentHeaderFormats.map((format) => ({
    id: format, payload: format, name: t(`templates.waba_list.form.header.formats.${format}`)
  }));
  
  const format = useWatch({ name: 'header.format' });
  const text = useWatch({ name: 'header.text', defaultValue: '' });
  const { variables, addVariable } = useVariable(VARIABLES_MAX, text);

  useEffect(() => {
    setValue('header.text', undefined, { shouldDirty: true, shouldValidate: true });
  }, [format]);

  const onVariableClick = () => {
    if (!addVariable()) return;
    setValue('header.text', [text.slice(0, position?.end), `{{${variables + 1}}}`, text.slice(position?.end)].join(''),
      { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div className='wabaTemplateHeader'>
      <div className='wabaTemplateHeader__header'>
        <h3>{t('templates.waba_list.form.header.title')}</h3>
        <Button type='button' color='white' textType='small' text={t('templates.waba_list.form.variable')}
          image={<PlusIcon />} onClick={onVariableClick} disabled={variables >= VARIABLES_MAX} />
      </div>
      <div className='wabaTemplateHeader__switch'>
        <Switch name='header.format' items={formatTabs} />
      </div>
      {format === 'TEXT' && <>
        <Textarea name='header.text' className='regularText' autoComplete='off' setPosition={setPosition}
          placeholder={t('templates.waba_list.form.header.placeholder')} maxLength={HEADER_MAX_LENGTH} />
        <Counter counter={text.length || 0} maximum={HEADER_MAX_LENGTH}
          text='templates.waba_list.form.character.character' />
      </>}
    </div>
  );
}

export default WabaTemplateHeader;
