import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../ChatField.scss'
import { ReactComponent as BackwardIcon } from '../../../../../assets/arrow-back.svg';
import { WabaTemplate } from '../../../../Templates/templatesTypes';
import { uploadFile } from '../../../ChatAPI';
import { sendWabaTemplate } from '../../../chatsSlice';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import WabaTemplateBase from "../../WabaTemplateBase/WabaTemplateBase";
import Button from "../../../../../components/Button";


type Props = {
  template: WabaTemplate;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<WabaTemplate | null | undefined>>;
  onCloseDropdown: (e: React.MouseEvent<unknown, MouseEvent>) => void;
};

type InputCollection = string[];

type TextConvertHeaderWithUrlType = {
  url?: string;
  text?: string;
};

const WabaTemplateComponent: React.FC<Props> = ({ template, setSelectedTemplate, onCloseDropdown }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');

  const [inputCollection, setInputCollection] = useState<InputCollection>([]);
  const [inputCollectionHeader, setInputCollectionHeader] = useState<InputCollection>([]);
  const [attachedFiles, setAttachedFiles] = useState<FileList>(([] as unknown) as FileList);


  const validateAllVariables = () => {
    if (template.components.header && !attachedFiles[0] && template.components.header.format !== 'text') {
      dispatch(addAlertWithCustomText({
        type: 'alarm',
        message: t('chats.waba_templates.file_error'),
      }))
      return false;
    }
    if (template.components.body.paramsCount > 0) {
      const isAllInputsHavePayload = inputCollection.filter(input => input.length)
      if (template.components.body.paramsCount !== isAllInputsHavePayload.length) {
        dispatch(addAlertWithCustomText({
          type: 'alarm',
          message: t('chats.waba_templates.input_error'),
        }))
        return false;
      }
    }
    return true;
  };

  const onSendTemplate = async (e: React.MouseEvent<unknown, MouseEvent>) => {
    if (!validateAllVariables()) return;

    if (attachedFiles[0] && currentCompanyId?.params.companyId) {
      uploadFile({ file:attachedFiles[0],companyId:Number(currentCompanyId?.params.companyId) }).then(({ url }) => {
        const textConvertHeaderWithUrl:TextConvertHeaderWithUrlType[]=[]

          if (inputCollectionHeader.length>0){
            inputCollectionHeader.forEach(i=> textConvertHeaderWithUrl.push({ text:i, url }))
          }else {
            textConvertHeaderWithUrl.push({ url })
          }

        dispatch(
          sendWabaTemplate({
            companyId:Number(currentCompanyId?.params.companyId),
            waba_template: {
              template_locale: template.locale,
              template_name: template.name,
              header_params: textConvertHeaderWithUrl,
              body_params: inputCollection,
            },
          })
        )
      })
    } else {
      const textConvertHeader=inputCollectionHeader.map(i=> ({ text:i }))
      dispatch(
        sendWabaTemplate({
          companyId:Number(currentCompanyId?.params.companyId),
          waba_template: {
            template_locale: template.locale,
            template_name: template.name,
            header_params: textConvertHeader,
            body_params: inputCollection,
          },
        })
      )
    }
    setInputCollectionHeader([])
    setInputCollection([]);
    onCloseDropdown(e);
  };

  return (
    <div className="templateContainer">
      <WabaTemplateBase template={template} setAttachedFiles={setAttachedFiles} attachedFiles={attachedFiles}
                        setInputCollection={setInputCollection} setInputCollectionHeader={setInputCollectionHeader}/>
      <div className="templateContainer__buttonsContainer">
        <Button text={t('back')} image={<BackwardIcon />} color='white' textType='small'
                onClick={() => setSelectedTemplate(null)} id='back' />
        <Button text={t('send')} color='orange' textType='small' onClick={onSendTemplate} />
      </div>
    </div>
  );
};

export default WabaTemplateComponent;

