import { useContext } from 'react';
import './WabaPreview.scss';
import moment from 'moment';
import {
  WabaTemplateComponentBody, WabaTemplateComponentButtonsIn, WabaTemplateComponentButtonsOutput,
  WabaTemplateComponentFooter, WabaTemplateComponentHeader, WabaTemplateComponentHeaderCreateRequest
} from '../../templatesTypes';
import { configWabaTemplateButton, configWabaTemplateFile, parseWabaBodyText } from '../WabaTemplatesModeration/utils';
import { wabaModeration } from '../WabaTemplatesModeration/Context';
import { ReactComponent as MessageCheckmark } from '../../../../assets/message-checkmark.svg';
import { ReactComponent as Tail } from '../../../../assets/message-tail.svg';


type Props = {
  header?: WabaTemplateComponentHeader | WabaTemplateComponentHeaderCreateRequest;
  body?: WabaTemplateComponentBody;
  footer?: WabaTemplateComponentFooter;
  buttons?: WabaTemplateComponentButtonsOutput | WabaTemplateComponentButtonsIn;
};

type ParamsType = {
  id: number;
  value: string;
};

const WabaPreview = ({ header, body, footer, buttons }: Props) => {
  const { arrayHeaderParams, arrayBodyParams } = useContext(wabaModeration);

  const getComponentText = (
    arrayParams: ParamsType[],
    component: WabaTemplateComponentHeader  | WabaTemplateComponentHeaderCreateRequest | WabaTemplateComponentBody
  ) => {
    if (component && component.text && (arrayParams && arrayParams.length > 0)) {
      const findParams = component.text.split(/{{(\d+)}}/gm);

      return findParams.map((param, index) => {
        if (index % 2 === 1) {
          const paramItem = arrayParams.find((item) => item.id === Number(param));
          return paramItem && paramItem.value.length > 0 ? paramItem.value : `{{${param}}}`;
        }

        return param;
      });
    } else if (component) {
      return component.text;
    }
  };

  if (!header && !body) {
    return <div className="wabaPreview">{}</div>;
  }

  return (
    <div className="wabaPreview">
      <div className="wabaPreview__body">
        {header ? (
          header.format !== 'TEXT' ? (
            <div className="wabaPreview__body_preview">{configWabaTemplateFile[header.format].icon}</div>
          ) : (
            <p className="smallText semibold">{getComponentText(arrayHeaderParams, header)}</p>
          )
        ) : null}
        {body && <p className="smallText">{parseWabaBodyText(getComponentText(arrayBodyParams, body))}</p>}
        {footer && <p className="footerText">{footer.text}</p>}
        <div className="messageDate">
          {moment(Date()).format('HH:mm')}
          <MessageCheckmark />
        </div>
        <Tail className="wabaPreview__body_tail" />
      </div>
      <div className="wabaPreview__buttons">
        {buttons &&
          buttons.buttons.map((button, index) => {
            return (
              <button key={`${button.type}${index}`} className="wabaPreview__buttons_button" type="button">
                {configWabaTemplateButton[button.type].icon && configWabaTemplateButton[button.type].icon}
                {configWabaTemplateButton[button.type].text(button)}
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default WabaPreview;
