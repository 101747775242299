import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Connections, NewConnection, WabaConnection } from '../../../api/CompanyAPI';
import * as api from './ConnectionsAPI';
import * as con from './ConnectionsTypes';


export const BLOCKED_CHATS_LIMIT = 10;

export type ConnectionsStoreType = {
  [service in con.ConnectionsType]: NewConnection[];
};

export type BlackListChatsType = Record<number, {
  blockedChats: con.BlockedChatType[];
  hasMore: boolean;}>;

const wabaCatalogErrorStatus: Record<number, con.FacebookCatalogStatus> = {
  25002: "disconnected",
  25001: "expired"
};

export type ConnectionsSlice = {
  companyId: number;
  connectionState: con.ConnectionState;
  connectionsStore: ConnectionsStoreType;
  connectionsLoad: boolean;
  whatsappStatus: Record<number, con.ConnectionStatusType> | null;
  blackListChats: BlackListChatsType;
  hasMore: boolean;
  wabaLinkError: WabaConnection | null;
  wabaCatalogsStatus: Record<number, con.WabaCatalogStatus> | null;
};

const initConnections = con.connectionTypes.map((item) => [item, []]);

const initialState: ConnectionsSlice = {
  companyId: 0,
  connectionState: {} as con.ConnectionState,
  connectionsStore: Object.fromEntries(initConnections),
  connectionsLoad: false,
  whatsappStatus: null,
  blackListChats: {} as BlackListChatsType,
  hasMore: true,
  wabaLinkError: null,
  wabaCatalogsStatus: null
};

export const fetchConnectionsList = createAsyncThunk<Connections, con.ConnectionsListRequest, { rejectValue: number }>(
  'connections/fetchConnectionsList', async (requestOptions, { dispatch, rejectWithValue }) => {
    try {
      return await api.getConnectionsList(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const fetchConnectionById = createAsyncThunk<NewConnection, con.CompanyConnectionType, { rejectValue: number }>(
  'connections/fetchConnectionsById', async (requestOptions, { dispatch, rejectWithValue }) => {
    try {
      return await api.getConnectionById(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const fetchConnectionState = createAsyncThunk<con.ConnectionState, number, { rejectValue: number }>(
  'connections/createState', async (companyId, { rejectWithValue }) => {
    try {
      return await api.postConnectionState(companyId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

 export const updateConnection = createAsyncThunk<NewConnection, con.ConnectionsUpdateRequest, { rejectValue: number}>(
  'connections/updateItem', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.patchConnection(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
});

export const createConnection = 
  createAsyncThunk<NewConnection, con.ConnectionsCreateRequest, { rejectValue: number }>(
  'connections/createItem', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.postConnection(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
});

export const removeConnection = createAsyncThunk<unknown, con.CompanyConnectionType, { rejectValue: number }>(
  'connections/deleteItem', async (requestOptions, { rejectWithValue }) => {
    try {
      await api.deleteConnection(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
});

export const updateConnectionStatus =
  createAsyncThunk<con.ConnectionStatusType, { companyId: number, connectionId: number }, { rejectValue: number }>(
    'connections/getWhatsappInstanceStatus', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getConnectionStatus(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const fetchChatsBlackList =
  createAsyncThunk<con.BlockedChatType[], con.BlackListChatsRequest, { rejectValue: number }>(
    'connections/getChatsBlackList', async (requestOptions, { rejectWithValue }) => {
      try {
        const { companyId, connectionId, limit, offset } = requestOptions;
        const requestLimit = limit !== undefined ? limit : BLOCKED_CHATS_LIMIT;
        return await api.getChatsBlackList({ companyId, connectionId, limit: requestLimit + 1, offset });
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const addChatToBlackList =
  createAsyncThunk<con.BlockedChatType, con.BlackListChatAddRequest, { rejectValue: number }>(
    'connections/addChatToBlackList', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.postChatToBlackList(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const removeChatFromBlackList =
  createAsyncThunk<unknown, con.BlackListChatDeleteRequest, { rejectValue: number }>(
  'connections/deleteChatFromBlackList', async (requestOptions, { rejectWithValue }) => {
    try {
      await api.deleteChatFromBlackList(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const getWabaCatalogStatus =
  createAsyncThunk<con.FacebookCatalog, con.WabaConnectionRequest, { rejectValue: number }>(
  'facebook/getFacebookCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getFacebookCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
});

export const disableWabaCatalog = createAsyncThunk<unknown, con.WabaConnectionRequest, { rejectValue: number }>(
  'facebook/disableFacebookCatalog', async (requestOptions, { rejectWithValue }) => {
    try {
      await api.disableFacebookCatalog(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const connectionsSlice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    clearConnectionState: (state) => {
      state = { ...initialState };
    },
    setConnectionsLoad: (state, action) => { state.connectionsLoad = action.payload;},
    setWhatsappStatusGood: (state, action) => { 
      if (state.whatsappStatus) {
        state.whatsappStatus[action.payload] = { status: 'ok' };
      } else {
        state.whatsappStatus = { [action.payload]: { status: 'ok' } };
      }
    },
    clearBlackListChats: (state, action) => {
      if (state.blackListChats[action.payload]) {
        state.blackListChats = Object.fromEntries(Object.entries(state.blackListChats)
          .filter(([key, _]) => key !== action.payload.toString()));
      }
    },
    addWabaLinkError: (state, action) => {
      state.wabaLinkError = action.payload;
    },
    deleteWabaLinkError: (state) => {
      state.wabaLinkError = null;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchConnectionState.fulfilled, (state, action) => {
      state.connectionState = action.payload;
    })
    .addCase(fetchConnectionsList.fulfilled, (state, action) => {
      if (state.companyId !== action.meta.arg.companyId) {
        state.connectionsStore = Object.fromEntries(initConnections);
        state.companyId = action.meta.arg.companyId;
      }
      if (action.meta.arg.types) {
        let types = [];
        if (typeof action.meta.arg.types === 'string') {
          types.push(action.meta.arg.types);
        } else {
          types = action.meta.arg.types;
        }
        types.forEach((type) => {
          const connections = state.connectionsStore[type as con.ConnectionsType];
          if (connections) {
            const ids = action.payload.connections.filter((item) => item.type === type).map((item) => item.id);
            state.connectionsStore[type as con.ConnectionsType] = 
              [...connections.filter((item) => !ids.includes(item.id)),
                ...action.payload.connections.filter((item) => item.type === type)];
          } else {
            state.connectionsStore[type as con.ConnectionsType] = action.payload.connections
              .filter((item) => item.type === type);
          }
        })
      } else {
        action.payload.connections.forEach((connection) => {
          const connections = state.connectionsStore[connection.type];
          if (connections) {
            const ids = connections.map((item) => item.id);
            if (!ids.includes(connection.id)) state.connectionsStore[connection.type] = [...connections, connection];
          } else {
            state.connectionsStore[connection.type] = [connection];
          }
        })
      }
      state.connectionsLoad = false;
        if (action.meta.arg.limit) {
          state.hasMore = action.meta.arg.limit === action.payload.count;
        }
    })
    .addCase(fetchConnectionsList.rejected, (state) => {
      state.connectionsLoad = false;
    })
    .addCase(fetchConnectionById.fulfilled, (state, action) => {
      if (state.companyId !== action.meta.arg.companyId) {
        state.connectionsStore = Object.fromEntries(initConnections);
        state.companyId = action.meta.arg.companyId;
      }
      const connections = state.connectionsStore[action.payload.type as con.ConnectionsType];
      if (connections.length > 0) {
        state.connectionsStore[action.payload.type as con.ConnectionsType].push(action.payload);
      } else {
        state.connectionsStore[action.payload.type as con.ConnectionsType] = [action.payload];
      }
    })
    .addCase(updateConnection.fulfilled, (state, action) => {
      const index = state.connectionsStore[action.payload.type].findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        const connections = [...state.connectionsStore[action.payload.type]];
        state.connectionsStore[action.payload.type] = connections.map((connection, i) => {
          if (i === index) {
            const fieldArray = Object.entries(action.payload).filter(([_, value]) => value !== null);
            return { ...connection, ...Object.fromEntries(fieldArray) };
          }
          return connection;
        })
      }
    })
    .addCase(createConnection.fulfilled, (state, action) => {
      state.connectionsStore[action.payload.type].push(action.payload);
    })
    .addCase(updateConnectionStatus.fulfilled, (state, action) => {
      if (state.whatsappStatus) {
        state.whatsappStatus[action.meta.arg.connectionId] = action.payload;
      } else {
        state.whatsappStatus = { [action.meta.arg.connectionId]: action.payload };
      }
    })
    .addCase(removeConnection.fulfilled, (state, action) => {
      if (state.companyId === action.meta.arg.companyId) {
        Object.keys(Object.fromEntries(initConnections)).forEach((key) => {
          const storedConnections = state.connectionsStore[key];
          if (storedConnections && storedConnections.length > 0) {
            const index = state.connectionsStore[key]
              .findIndex((item: NewConnection) => item.id === action.meta.arg.connectionId);
            if (index !== -1) {
              const connections = [...state.connectionsStore[key]];
              state.connectionsStore[key] = connections.filter((con) => con.id !== action.meta.arg.connectionId);
            }
          }
        });
      }
    })
    .addCase(fetchChatsBlackList.fulfilled, (state, action) => {
      const hasMore = action.payload.length > BLOCKED_CHATS_LIMIT;
      const blockedChats = hasMore ? action.payload.slice(0, BLOCKED_CHATS_LIMIT) : action.payload;
      if (!state.blackListChats[action.meta.arg.connectionId]) {
        state.blackListChats[action.meta.arg.connectionId] = { blockedChats, hasMore };
      } else {
        const blackList = state.blackListChats[action.meta.arg.connectionId];
        state.blackListChats[action.meta.arg.connectionId] =
          { blockedChats: blackList ? [...blackList.blockedChats, ...blockedChats] : blockedChats, hasMore };
      }
    })
    .addCase(removeChatFromBlackList.fulfilled, (state, action) => {
      const blackList = state.blackListChats[action.meta.arg.connectionId];
      if (blackList) {
        blackList.blockedChats = blackList.blockedChats
        .filter(item => item.id !== action.meta.arg.itemId);
      }
    })
    .addCase(addChatToBlackList.fulfilled, (state, action) => {
      if (!state.blackListChats[action.meta.arg.connectionId]) {
        state.blackListChats[action.meta.arg.connectionId] = { blockedChats: [action.payload], hasMore: false };
      } else {
        const blackList =  state.blackListChats[action.meta.arg.connectionId];
        blackList?.blockedChats.push(action.payload);
      }
    })
    .addCase(getWabaCatalogStatus.fulfilled, (state, action) => {
      if (state.wabaCatalogsStatus) {
        state.wabaCatalogsStatus[action.payload.wabaConnectionId] = {
          status: 'connected', payload: action.payload.name };
      } else {
        state.wabaCatalogsStatus = { [action.payload.wabaConnectionId]: {
          status: 'connected', payload: action.payload.name } };
      }
    })
    .addCase(getWabaCatalogStatus.rejected, (state, action) => {
      if (action.payload) {
        if (state.wabaCatalogsStatus) {
          state.wabaCatalogsStatus[action.meta.arg.waba_connection_id] = {
            status: wabaCatalogErrorStatus[action.payload], payload: action.payload.toString() };
        } else {
          state.wabaCatalogsStatus = { [action.meta.arg.waba_connection_id]: {
            status: wabaCatalogErrorStatus[action.payload], payload: action.payload.toString() } };
        }
      }
    })
    .addCase(disableWabaCatalog.fulfilled, (state, action) => {
      if (state.wabaCatalogsStatus) {
        const statusCatalog = state.wabaCatalogsStatus[action.meta.arg.waba_connection_id];
        if (statusCatalog) {
          state.wabaCatalogsStatus = Object.fromEntries(Object.entries(state.wabaCatalogsStatus)
          .filter(([key, _]) => key !== action.meta.arg.waba_connection_id.toString()));
        }
      }
    })
  }
});

 export const {
  clearConnectionState, setConnectionsLoad, setWhatsappStatusGood, clearBlackListChats,
  addWabaLinkError, deleteWabaLinkError } = connectionsSlice.actions;

 export default connectionsSlice.reducer;
 