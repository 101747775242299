import { createContext, useState } from 'react';
import { CreateWabaTemplateRequest } from '../../templatesTypes';


type WabaTemplateProviderProps = {
  children: React.ReactNode
};

export type WabaTemplateContext = {
  connectionId: number;
  setConnectionId: React.Dispatch<React.SetStateAction<number>>;
  template?: CreateWabaTemplateRequest;
  setTemplate: React.Dispatch<React.SetStateAction<CreateWabaTemplateRequest | undefined>>
};

export const wabaTemplateContext = createContext<WabaTemplateContext>({} as WabaTemplateContext);

function WabaTemplateProvider({ children }: WabaTemplateProviderProps) {
  const [connectionId, setConnectionId] = useState<number>(0);
  const [template, setTemplate] = useState<CreateWabaTemplateRequest>();

  return (
    <wabaTemplateContext.Provider value={{ connectionId, setConnectionId, template, setTemplate }} >
      {children}
    </wabaTemplateContext.Provider>
  );
}

export default WabaTemplateProvider;
