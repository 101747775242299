import './WabaTemplateForm.scss';
import { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useAppSelector } from '../../../../store/store';
import { useWabaTemplateRequest, useWabaTemplateSchema, WabaTemplateSchema } from '../../api';
import {
  CreateWabaTemplateRequest, wabaTemplateCategories, WabaTemplateComponentBody,
  WabaTemplateComponentButtonsOutput, WabaTemplateComponentFooter, WabaTemplateComponentHeader
} from '../../templatesTypes';
import { locales } from './locales';
import { wabaTemplateContext } from '../WabaTemplateProvider/WabaTemplateProvider';
import { countVariables } from '../../utils/waba';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import Input from '../../../../components/Input';
import Dropdown from '../../../Settings/Dropdown/Dropdown';
import Button from '../../../../components/Button';
import WabaTemplateHeader from './components/WabaTemplateHeader';
import WabaTemplateBody from './components/WabaTemplateBody';
import WabaTemplateFooter from './components/WabaTemplateFooter';
import WabaTemplateButtons from './components/WabaTemplateButtons';
import SwitchControl from '../../../../components/SwitchControl';
import Switcher from '../../../../components/Switcher';
import WabaPreview from '../WabaPreview';
import { ReactComponent as SmallLoader } from '../../../../assets/small-loader.svg';


function WabaTemplateForm() {
  const { t } = useTranslation();
  const { push, replace, goBack } = useHistory();
  const loading = useAppSelector((state) => state.templates.wabaListLoading);
  const [expiration, setExpiration] = useState<boolean>(false);
  const [draft, setDraft] = useState<CreateWabaTemplateRequest>();
  const [checked, setChecked] = useState<boolean>(false);
  const schema = useWabaTemplateSchema();
  const { makeWabaTemplateRequest, makeWabaTemplateData, makeWabaTemplate } = useWabaTemplateRequest();
  const { connectionId, template, setTemplate } = useContext(wabaTemplateContext);

  const methods = useForm<WabaTemplateSchema>({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: {
    category: 'MARKETING',
    allow_category_change: true,
    header: { format: 'TEXT', text: '' } 
  } });

  const category = useWatch({ name: 'category', control: methods.control });
  const header = useWatch({ name: 'header', control: methods.control });
  const body = useWatch({ name: 'body', control: methods.control });
  const footer = useWatch({ name: 'footer', control: methods.control });
  const buttons = useWatch({ name: 'buttons', control: methods.control });
  const codeExpiration = useWatch({ name: 'code_expiration_minutes', control: methods.control });
  const securityRecommendation = useWatch({ name: 'add_security_recommendation', control: methods.control });

  const categories = wabaTemplateCategories.map((category) => 
    ({ id: category, name: t(`templates.waba_list.categories.${category}`) }));

  const answers = ['yes', 'no'].map((answer) => ({ id: answer, name: t(answer), value: answer === 'yes' }));

  useEffect(() => {
    const data = methods.getValues();
    methods.reset({
      category: data.category,
      custom_name: data.custom_name,
      template_locale: data.template_locale,
      allow_category_change: data.allow_category_change,
      body: data.category === 'AUTHENTICATION' 
        ? t('templates.waba_list.form.security_prompt', { interpolation: { skipOnVariables: true } })
        : data.body,
      header: data.category === 'AUTHENTICATION' ? {} : { format: 'TEXT', text: '' },
      buttons: []
    });
  }, [category]);

  useEffect(() => {
    if (template) {
      methods.reset(makeWabaTemplateData(template), { keepDefaultValues: true });
    }
  }, [template]);

  useEffect(() => {
    setDraft(makeWabaTemplateRequest(methods.getValues()));
  }, [header, body, footer, buttons]);

  const expirationPrompt = (minutes: number) => [
    t('templates.waba_list.form.code_prompt'),
    `${minutes} `,
    t('templates.waba_list.form.minute.minute', { count: Number(minutes) }),
    '.'
  ].join('');

  useEffect(() => {
    if (category !== 'AUTHENTICATION') return;
    if (codeExpiration && expiration) methods.setValue(
      'footer',
      expirationPrompt(codeExpiration),
      { shouldValidate: true }
    );
  }, [codeExpiration]);

  useEffect(() => {
    if (category !== 'AUTHENTICATION') return;
    if (securityRecommendation) {
      methods.setValue('body', `${body} ${t('templates.waba_list.form.security_warning')}`);
    } else {
      methods.setValue('body',
        t('templates.waba_list.form.security_prompt', { interpolation: { skipOnVariables: true } }));
    }
  }, [securityRecommendation]);

  const onExpirationChange = () => {
    setExpiration((prev) => !prev);
    if (expiration) {
      methods.setValue('code_expiration_minutes', undefined, { shouldValidate: true });
      methods.setValue('footer', '', { shouldValidate: true });
    } else {
      methods.setValue( 'footer', 
        expirationPrompt(methods.getValues('code_expiration_minutes') ?? 0), { shouldValidate: true });
    }
  };

  const onSubmit = async (data: WabaTemplateSchema) => {
    setChecked(true);
    const template = makeWabaTemplateRequest(data);
    setTemplate(template);
    if ((category === 'MARKETING' || category === 'UTILITY') 
      && (['DOCUMENT', 'IMAGE', 'VIDEO'].includes(data.header?.format ?? '')
      || (data.header?.format === 'TEXT' && countVariables(data.header.text ?? '') > 0)
      || (countVariables(data.body ?? '') > 0)
      || (data.buttons && data.buttons.some((button) => button.type === 'URL' && countVariables(button.url ?? '') > 0))
    )) {
      push(location.pathname.replace('create', 'moderation'));
    }
    else {
      makeWabaTemplate(template).then((res) => {
        if (res) {
          replace({ pathname: location.pathname.replace('create', 'list'), state: { connectionId } });
          goBack();
        }
        setChecked(res);
      });
    }
  };

  return (
    <div className='wabaTemplateForm'>
      <GoBackWrapper title={t('templates.create_templates')}>
        <FormProvider {...methods}>
          <form>
          <Input name='custom_name' label={t('templates.waba_list.form.name')} />
          <Dropdown name='category' label={t('templates.waba_list.form.category')}
            items={categories} labelKey='name' valueKey='id' />
          <Dropdown name='template_locale' label={t('templates.waba_list.form.locale')}
            items={locales} labelKey='name' valueKey='id' placeholder={t('select')} />
          <Dropdown name='allow_category_change' label={t('templates.waba_list.form.allow_category_change')}
            items={answers} labelKey='name' valueKey='value' />
          {category === 'AUTHENTICATION' && <>
            <SwitchControl name='add_security_recommendation'
              label={t('templates.waba_list.form.security_recommendation')} />
            <div className='wabaTemplateForm__switcher'>
              <Switcher lable={t('templates.waba_list.form.code_expiration')}
                toggled={expiration} onToggle={onExpirationChange} />
              <Input name='code_expiration_minutes' label={t('templates.waba_list.form.code_validity')}
                inputType='number' disabled={!expiration} />
            </div>
          </>}
          {category !== 'AUTHENTICATION' && <WabaTemplateHeader />}
          <WabaTemplateBody />
          {(category !== 'AUTHENTICATION' || expiration) && <WabaTemplateFooter />}
          <WabaTemplateButtons />
          <div className='wabaTemplateForm__submit'>
            {loading && <SmallLoader />}
            <Button type='submit' color='orange' textType='regular' text={t('create')}
              disabled={!methods.formState.isDirty || checked}
              onClick={methods.handleSubmit(onSubmit)} />
          </div>
          </form>
        </FormProvider>
        <WabaPreview 
          body={category === 'AUTHENTICATION'
            ? { type: 'BODY', text: body } as WabaTemplateComponentBody
            : draft?.components.find((component) => component.type === 'BODY') as WabaTemplateComponentBody}
          header={draft?.components.find((component) => component.type === 'HEADER') as WabaTemplateComponentHeader}
          footer={category === 'AUTHENTICATION'
            ? { type: 'FOOTER', text: footer } as WabaTemplateComponentFooter
            : draft?.components.find((component) => component.type === 'FOOTER') as WabaTemplateComponentFooter}
          buttons={draft?.components.find((component) => component.type === 'BUTTONS') as WabaTemplateComponentButtonsOutput}
        />
      </GoBackWrapper>
    </div>
  );
}

export default WabaTemplateForm;
