import { createContext } from 'react';


type ParamsType = {
  id: number;
  value: string;
};

type WabaModerationContext = {
  onAttachmentFile: File | undefined;
  setOnAttachmentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  arrayHeaderParams: ParamsType[];
  setArrayHeaderParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  arrayBodyParams: ParamsType[];
  setArrayBodyParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  firstURLParams: ParamsType[];
  setFirstURLParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  secondURLParams: ParamsType[];
  setSecondURLParams: React.Dispatch<React.SetStateAction<ParamsType[]>>;
  isError: boolean;
};

export const wabaModeration = createContext<WabaModerationContext>({} as WabaModerationContext);
