import React, { useRef } from 'react';
import './Textarea.scss';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { BaseEmoji, Picker } from 'emoji-mart';
import { ReactComponent as EmojiPickerIcon } from '../../../../assets/smiley.svg';
import { useOutsideClick } from '../../../../components/utils/hooks';


type Props = {
  title?: string;
  subtitle?: string;
  minRows: number;
  maxRows: number;
  emojiPicker?: {
    isOpened: boolean;
    setCurrentOpenedEmojiPickerId: (value: number | undefined) => void;
    id: number;
    /* eslint-disable-next-line */
    onPickEmoji: any;
    /* eslint-disable-next-line */
    textAreaValue: any;
    textAreaId: string;
  };
  width?: string;
  errorMessage?: JSX.Element | false;
  maxLength?: number;
  placeholder?: string
} & TextareaAutosizeProps;

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
    const { title, minRows, maxRows, subtitle, emojiPicker, maxLength, width,
      errorMessage, onBlur, onChange, name, placeholder } = props;
    const emojiPickerRef = useRef(null);
    const emojiPickerTrigger = useRef<SVGSVGElement>(null);

    const onCloseEmojiPicker = () => {
      if (emojiPicker) {
        emojiPicker.setCurrentOpenedEmojiPickerId(undefined);
      }
    };

    const onPickEmoji = (emoji: BaseEmoji) => {
      if (emojiPicker && emojiPicker.textAreaValue) {
        const currentFieldValue = emojiPicker.textAreaValue(emojiPicker.textAreaId);
        emojiPicker.onPickEmoji(emojiPicker.textAreaId, currentFieldValue + emoji.native, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    };

    useOutsideClick(emojiPickerRef, onCloseEmojiPicker, emojiPickerTrigger);

    return (
      <div className="textArea">
        <div className="textArea__header">
          {title && <p className="textArea__header_title">{title}</p>}
          {subtitle && <p className="textArea__header_subtitle">{subtitle}</p>}
        </div>
        <TextareaAutosize
          name={name}
          ref={ref}
          className="textArea__textarea"
          style={{ width: width || 'inherit' }}
          minRows={minRows}
          maxRows={maxRows}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
        />
        {emojiPicker && (
          <>
            {emojiPicker.isOpened && (
              <div ref={emojiPickerRef}>
                <Picker
                  showPreview={false}
                  showSkinTones={false}
                  native
                  style={{
                    position: 'absolute',
                    right: '41px',
                    bottom: '7px',
                    zIndex: 1,
                  }}
                  onSelect={onPickEmoji}
                />
              </div>
            )}
            <EmojiPickerIcon
              ref={emojiPickerTrigger}
              className="pickerIcon"
              onClick={() => {
                emojiPicker.setCurrentOpenedEmojiPickerId(emojiPicker.id);
              }}
            />
          </>
        )}
        {errorMessage}
      </div>
    );
  }
);

export default Textarea;
