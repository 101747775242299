import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Textarea from '../../Components/Textarea/Textarea';
import { CreateInteractiveTemplateData } from '../../templatesTypes';


type Props = {
  payloadType: string;
  setEditTemplateFileUrl: React.Dispatch<React.SetStateAction<{ fileUrl: string; documentName: string | null } | null>>;
};

const TextPayloadContent: React.FC<Props> = ({ setEditTemplateFileUrl }) => {
  const { t } = useTranslation();
  const [currentOpenedEmojiPickerId, setCurrentOpenedEmojiPickerId] = useState<number>();
  const methods = useFormContext<CreateInteractiveTemplateData>();

  useEffect(() => {
    setEditTemplateFileUrl(null);
  }, []);

  return (
    <div className="textPayloadContent">
      <Textarea
        title={t('templates.add_interactive_templates.template_header.header_text_title')}
        subtitle={t('error.form.max_length', { limit: 60 })}
        minRows={1}
        maxRows={2}
        {...methods.register('waba_interactive.header.text', {
          minLength: {
            value: 1,
            message: t('templates.add_interactive_templates.template_header.header_text_title_errors.min_length'),
          },
          maxLength: {
            value: 60,
            message: t('error.form.max_length', { limit: 60 }),
          },
          required: true,
        })}
        maxLength={128}
        errorMessage={
          (methods.formState.errors.waba_interactive?.header?.text?.type === 'required' && (
            <span className="errorMessage">
              {t('templates.add_interactive_templates.template_header.header_text_title_errors.required')}
            </span>
          )) ||
          (methods.formState.errors.waba_interactive?.header?.text && (
            <span className="errorMessage">{methods.formState.errors.waba_interactive.header.text.message}</span>
          ))
        }
      />

      <Textarea
        title={t('templates.add_interactive_templates.template_header.header_body_title')}
        subtitle={t('templates.add_interactive_templates.template_header.header_body_subtitle')}
        emojiPicker={{
          isOpened: currentOpenedEmojiPickerId === 1,
          setCurrentOpenedEmojiPickerId,
          id: 1,
          onPickEmoji: methods.setValue,
          textAreaValue: methods.getValues,
          textAreaId: 'waba_interactive.body',
        }}
        minRows={2}
        maxRows={4}
        {...methods.register('waba_interactive.body', {
          minLength: {
            value: 1,
            message: t('templates.add_interactive_templates.template_header.header_body_errors.min_length'),
          },
          maxLength: {
            value: 1024,
            message: t('templates.add_interactive_templates.template_header.header_body_errors.max_length'),
          },
          required: true,
        })}
        maxLength={1024}
        errorMessage={
          (methods.formState.errors.waba_interactive?.body?.type === 'required' && (
            <span className="errorMessage">
              {t('templates.add_interactive_templates.template_header.header_body_errors.required')}
            </span>
          )) ||
          (methods.formState.errors.waba_interactive?.body && (
            <span className="errorMessage">{methods.formState.errors.waba_interactive.body.message}</span>
          ))
        }
      />
      <Textarea
        title={t('templates.add_interactive_templates.template_header.header_footer_title')}
        subtitle={t('templates.add_interactive_templates.template_header.header_footer_subtitle')}
        emojiPicker={{
          isOpened: currentOpenedEmojiPickerId === 2,
          setCurrentOpenedEmojiPickerId,
          id: 2,
          onPickEmoji: methods.setValue,
          textAreaValue: methods.getValues,
          textAreaId: 'waba_interactive.footer',
        }}
        minRows={2}
        maxRows={4}
        {...methods.register('waba_interactive.footer', {
          minLength: {
            value: 1,
            message: t('templates.add_interactive_templates.template_header.header_footer_errors.min_length'),
          },
          maxLength: {
            value: 60,
            message: t('templates.add_interactive_templates.template_header.header_footer_errors.max_length'),
          },
        })}
        maxLength={60}
        errorMessage={
          methods.formState.errors.waba_interactive?.footer && (
            <span className="errorMessage">{methods.formState.errors.waba_interactive.footer.message}</span>
          )
        }
      />
    </div>
  );
};

export default TextPayloadContent;
