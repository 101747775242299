import { createContext } from 'react';
import { useAppDispatch } from '../../../store/store';
import { CompanyRequisite, PartnerRequisitePostRequest, UpdatePartnerRequisite } from '../PartnerCabinetTypes';
import { createPartnerRequisite, updatePartnerRequisite } from '../PartnerCabinetSlice';


type RequisiteItemContextType = {
  onEdit?: (requisite: CompanyRequisite) => void;
  onDelete?: (requisite: CompanyRequisite) => void;
};

export const requisiteItemContext = createContext<RequisiteItemContextType>({});

export function useRequisiteRequest() {
  const dispatch = useAppDispatch();

  const makeRequisite = async (requisite: PartnerRequisitePostRequest, editData?: UpdatePartnerRequisite) => {
    let result = false;

    if (editData) {
      await dispatch(updatePartnerRequisite(editData)).then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });
    } else {
      await dispatch(createPartnerRequisite(requisite)).then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });
    }

    return result;
  };

  return { makeRequisite };
}
