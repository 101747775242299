import './RemoveActionButton.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';


type RemoveActionButtonProps = {
  onRemoveAction: () => void;
};

function RemoveActionButton({ onRemoveAction }: RemoveActionButtonProps) {
  const { t } = useTranslation();

  return (
    <button className="removeActionButton" onClick={onRemoveAction}>
      <DeleteIcon />
      <span className='smallText'>{t('delete')}</span>
    </button>
  );
}

export default RemoveActionButton;
