import React, { useState } from 'react';
import './CardButtons.scss';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeCustomTemplate } from '../../templatesSlice';
import { CustomTemplate } from '../../templatesTypes';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';
import ActionDialog from '../../../../components/Modals/ActionDialog';


type Props = {
  template: CustomTemplate;
  setTemplateEdit: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>> | undefined;
};

function CardButtons({ template, setTemplateEdit }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const onClickEditButton = () => {
    if (setTemplateEdit) {
      dispatch(push(`${url}/edit`));
      setTemplateEdit(template);
    }
  };

  const onHandlerDelete = () => setDeleteOpen(true);

  const deleteCatalog = () => {
    if (currentCompanyId?.params.companyId) {
      dispatch(removeCustomTemplate({
        companyId: Number(currentCompanyId?.params.companyId),
        templateId: template.id,
      }));
    }
  };

  const getDeleteConfimMessage = () => t('templates.delete_confirm.warning');

  return (
    <div className='cardButtons'>
      {deleteOpen &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          title={t('templates.delete_confirm.head')} message={getDeleteConfimMessage()}
          onAction={deleteCatalog} setIsOpen={setDeleteOpen} />}
      <WrapperPermission permission='templatesEdit'>
        <button className='cardButtons__editButton' onClick={onClickEditButton}>
          <EditIcon />
          {t('edit')}
        </button>
      </WrapperPermission>
      <WrapperPermission permission='templatesDelete'>
        <button className='cardButtons__deleteButton' onClick={onHandlerDelete}>
          <DeleteIcon />
          {t('delete')}
        </button>
      </WrapperPermission>
    </div>
  );
}

export default CardButtons;
