import React from 'react';
import TextPayloadContent from './TextPayloadContent';
import FilePayloadContent from './FilePayloadContent';
import { CustomTemplate } from '../../templatesTypes';
import WithoutAttachmentPayloadContent from './WithoutAttachmentPayloadContent';


type FilePayloadContentProps = {
  payloadType: string;
  onChangeAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  editTemplateData?: CustomTemplate;
  setEditTemplateFileUrl: React.Dispatch<React.SetStateAction<{ fileUrl: string; documentName: string | null } | null>>;
  editTemplateDataFileUrl: {
    fileUrl: string;
    documentName: string | null;
  } | null;
  fileAttachmentError: boolean;
};

type FilePayloadContentType = React.FC<FilePayloadContentProps>;

type PayloadTypes = {
  [key: string]: FilePayloadContentType;
  text: FilePayloadContentType;
  image: FilePayloadContentType;
  video: FilePayloadContentType;
  document: FilePayloadContentType;
};

export const payloadTypes: PayloadTypes = {
  text: TextPayloadContent,
  image: FilePayloadContent,
  video: FilePayloadContent,
  document: FilePayloadContent,
  withoutAttachment: WithoutAttachmentPayloadContent,
};
