import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import addNotification from 'react-push-notification';
import { push } from 'connected-react-router';
import {
  getChatById,
  getChatsWithContacts,
  getDialogsCounters,
  mailInteractiveTemplate,
  postChat,
  postMessage,
  postWabaTemplate,
  uploadFile,
} from './ChatAPI';
import {
  ChatsWithContacts,
  ChatType,
  ContactChatsWithMetaData, DialogsCountsType,
  MessageModeType,
  MessageRequest,
  MessageTemplateRequestType,
  SendMessageRequest,
  SocketDeliveryStatus,
} from './ChatTypes';
import { fetchChatsWithContact, fetchMessages } from './messagesThunks';
import {
  createMessageData,
  generateChatSortWeight,
  generateChatsWithContactSortWeight,
  sortChats,
} from './utils';
import { CompaniesSlice, setCurrentConnectionId } from './companiesSlice';
import { messageRequestBodyFactory } from './messageRequestBodyFactory';
import {
  MarkChatAnswered,
  markChatAnswered,
  MarkChatAnsweredReqParams,
} from '../../api/CompanyAPI';
import { fetchContact } from '../Contacts/contactsSlice/contactsSlice';
import { RequestInteractiveTemplate } from '../Templates/templatesTypes';

type NotificationData = {
  chat: ChatType;
  message: MessageRequest;
  contactId: number;
};

type SearchedChats = Array<{ connectionId: number; chatId: number }> | null;

type SearchedChatsWithContacts = {
  contactId: number;
  chats: number[];
};

export type ChatsSlice = {
  chatsWithContacts: ContactChatsWithMetaData | null;
  currentContactId: number | null;
  currentChat: ChatType;
  searchedChats: SearchedChats;
  searchedChatsWithContacts: SearchedChatsWithContacts[] | null;
  searchedValueChats: string;
  notificationData: NotificationData | null;
  isBlurred: boolean;
  messages: MessageRequest[] | null;
  currentFileUrl: string;
  currentFileType: string;
  errors: {
    [key: string]: null | {
      errorMessage: string;
      payload?: {
        contactId: number;
        chatId: number;
      };
    };
    createNewChat: null | {
      errorMessage: string;
      payload: {
        contactId: number;
        chatId: number;
      };
    };
  };
  loaders: {
    fetchMessages: boolean;
    fetchChatsWithContact: boolean;
  };
  countsDialogs: DialogsCountsType[] | null
};

export const fetchNextChatsWithContact = createAsyncThunk<ContactChatsWithMetaData,
  { companyId: number; limit: number; cursor: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { fulfilledMeta: any }>('chat/fetchNextChatsWithContact', async (requestOption, { fulfillWithValue }) => {
  const contactChatsWithMetaData = await getChatsWithContacts(requestOption);
  return fulfillWithValue(contactChatsWithMetaData, null);
});

export const onNewMessageHandler = createAsyncThunk<unknown,
  {
    convertedMessage: { connectionId: number; chatId: number; message: string; contactId: number };
    message: MessageRequest;
  },
  {
    state: {
      chats: ChatsSlice;
      companies: CompaniesSlice;
    };
  }>('chat/newMessageEvent', async ({ message, convertedMessage }, {
  getState,
  dispatch,
}) => {
  const { chats, companies } = getState();

  // поступило новое сообщения, посмотри в сторе есть такой контакт, которому пришло сообщения ?
  if (chats.chatsWithContacts?.data) {
    let contactWithChats = chats.chatsWithContacts.data.find(
      (chatWithContact) => chatWithContact.contactId === convertedMessage.contactId
    );

    // нет, тогда найди на бэке/бд
    if (!contactWithChats) {
      // сделай запрос с фильтром и присвой
      contactWithChats = await dispatch(
        fetchChatWithContactByContactId({
          contactId: convertedMessage.contactId,
          companyId: companies.currentCompany.id,
        })
      ).unwrap();
    }

    if (contactWithChats) {
      // есть у него в чатах, кому принадлежит новое сообщения с таким ChatId
      const chat = contactWithChats.chats.find(
        (chatInContact) =>
          chatInContact.chatId === convertedMessage.chatId &&
          chatInContact.connectionId === convertedMessage.connectionId
      );

// посмотри если есть входящие сообщение, то обнови счетчик неотвеченных у (или действительно нашел такой контакт с таким чатам)
      dispatch(updateChatWithContacts({ message, convertedMessage }));

      if (chat) {
        if (message.direction === 'inbound' && companies.profileSettings.notificationsEnabled) {
          if (chat.chatId !== chats.currentChat.chatId || chats.isBlurred) {
            addNotification({
              title: `Новое сообщение от ${chat.name}`,
              message: message.text?.text,
              native: true,
              duration: 5000,
              onClick: () => {
                dispatch(selectChat(chat));
                dispatch(setCurrentConnectionId(chat.connectionId));
                window.focus();
                dispatch(
                  push(
                    `/companies/${companies.currentCompany.id}/chats/${convertedMessage.contactId}/${convertedMessage.chatId}`
                  )
                );
              },
            });
            dispatch(updateNotificationData({
              chat,
              message,
              contactId: convertedMessage.contactId,
            }));
          }
        }
        if (chats.currentChat.chatId === chat.chatId && chats.currentChat.connectionId === chat.connectionId) {
          dispatch(addMessageFromSocket(message));
        }
      }
    }
  }
  // отсортируй все контакты и отсортируй внутри контакты чаты его и добавь в поле новое сообщение
  // НО сортировка остается в переменной лежать и в сторе не сохраняет, почему?
  dispatch(updateChatWithContactOrder());
});

export const sendFile = createAsyncThunk('messages/sendFile', async (requestOption: { companyId: number, file: File }) =>
  await uploadFile(requestOption));

export const markChatIsAnswered = createAsyncThunk<
  MarkChatAnswered, MarkChatAnsweredReqParams, { rejectValue: number }>(
  'companies/markChatAnswered', async (requestOption, { rejectWithValue }) => {
    try {
      return await markChatAnswered(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const sendMessage = createAsyncThunk<MessageModeType, SendMessageRequest,
  {
    rejectValue: number,
    state: {
      chats: ChatsSlice;
    };
  }>('messages/sendMessage', async (requestOption, {
  getState,
  rejectWithValue,
}) => {
  try {
    const state = getState();
    const { currentChat } = state.chats;

    return await postMessage(
      messageRequestBodyFactory(
        requestOption.messageType,
        requestOption.caption,
        requestOption.templateName,
        requestOption.chatId ? requestOption.chatId : currentChat,
        requestOption.url,
        requestOption.companyId
      )
    );

  } catch (e) {
    return rejectWithValue(e.response.data.error_code);
  }
});

export const sendWabaTemplate = createAsyncThunk<MessageModeType,
  MessageTemplateRequestType,
  {
    rejectValue: number;
    state: {
      chats: ChatsSlice;
    };
  }>('messages/sendWabaTemplate', async (requestOption: MessageTemplateRequestType, {
  getState,
  rejectWithValue,
}) => {
  const state = getState();
  const { currentChat } = state.chats;
  try {
    return await postWabaTemplate(requestOption, currentChat);
  } catch (e) {
    return rejectWithValue(e.response.data.error_code);
  }
});

export const sendInteractiveTemplate = 
  createAsyncThunk<unknown,RequestInteractiveTemplate, { state: { chats: ChatsSlice; };}>(
    'message/sendInteractiveTemplate', async (requestOptions, { getState }) => {
    const state = getState();
    const { currentChat } = state.chats;
    await mailInteractiveTemplate(requestOptions, currentChat);
});

export const fetchDialogsCounters =
  createAsyncThunk<DialogsCountsType[], number, { rejectValue: number }>(
    'subscriptions/fetchDialogsCounters',
    async (companyId, { dispatch, rejectWithValue }) => {
      try {
        return await getDialogsCounters(companyId);
      }
      catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

const initialState: ChatsSlice = {
  chatsWithContacts: null,
  currentChat: {} as ChatType,
  searchedChats: null,
  searchedValueChats: '',
  notificationData: null,
  isBlurred: false,
  searchedChatsWithContacts: null,
  currentContactId: null,
  messages: null,
  currentFileUrl: '',
  currentFileType: '',
  errors: {
    createNewChat: null,
  },
  loaders: {
    fetchMessages: false,
    fetchChatsWithContact: false,
  },
  countsDialogs: null
};

type ContactChatsRequest = {
  companyId: number;
  contactId: number;
  chatId?: number;
};

export const fetchChatWithContactByContactId = createAsyncThunk<ChatsWithContacts, ContactChatsRequest>(
  'chat/fetchChatWithContactByContactId',
  async (requestOptions: ContactChatsRequest, { dispatch }) => {
    // отправь запрос API за контактами с чатами
    const chatsWithContact = await getChatsWithContacts(requestOptions);
    // пробегись по всем контактам, отсортируй у них внутренние чаты - по новым сообщения на верх
    // и добавь к полю lastMessageInfo - последние сообщение
    chatsWithContact.data.forEach((chatWithContact) => {
      const { chats } = chatWithContact;
      if (chats.length) {
        sortChats(chats);

        chatWithContact.lastMessageInfo = {
          chatId: chats[0].chatId,
          connectionId: chats[0].connectionId,
          lastMessage: chats[0].lastMessage,
        };
      } else {
        chatWithContact.lastMessageInfo = undefined;
      }
    });

// найди в первом контакте, есть такой чат ? и положи в стор currentChat этот первый контакт
    if (chatsWithContact && requestOptions.chatId) {
      const findContactChat = chatsWithContact.data[0].chats.find((chat) =>
        chat.chatId === requestOptions.chatId);
      if (findContactChat) {
        dispatch(setCurrentChatById(findContactChat));
      }
    }
// и всегда возвращай первый контакт
    return chatsWithContact.data[0];
  }
);

export const fetchChatsWithContactsBySearchedValue = createAsyncThunk<unknown,
  { searchedValue: string },
  { state: { chats: ChatsSlice; companies: CompaniesSlice } }>('chat/fetchChatsWithContactsBySearchedValue', async ({ searchedValue }, {
  getState,
  dispatch,
}) => {
  const {
    chats,
    companies: { currentCompany },
  } = getState();
  const fetchedChatsWithContacts = await getChatsWithContacts({
    companyId: currentCompany.id,
    searchedValue,
  });

  if (chats.chatsWithContacts?.data) {
    const { chatsWithContacts } = chats;
    const absentChatsWithContacts = fetchedChatsWithContacts.data.filter((fetchedChatWithContact) => {
      const foundChatWithContact = chatsWithContacts.data.find(
        (currentChatWithContact) => currentChatWithContact.contactId === fetchedChatWithContact.contactId
      );
      return !foundChatWithContact;
    });

    if (absentChatsWithContacts.length > 0) {
      absentChatsWithContacts.forEach((absentChat) => {
        const { chats: absentChats } = absentChat;
        if (absentChats && absentChats.length) {
          for (let i = 0; i < absentChats.length; i++) {
            absentChats[i].sortWeight = generateChatSortWeight(absentChats[i]);
          }

          sortChats(absentChats);

          absentChat.lastMessageInfo = {
            chatId: absentChats[0].chatId,
            connectionId: absentChats[0].connectionId,
            lastMessage: absentChats[0].lastMessage,
          };
        } else {
          absentChat.lastMessageInfo = undefined;
        }
      });

      dispatch(pushSearchedChatsWithContacts(absentChatsWithContacts));
    }

    const searchedArray = fetchedChatsWithContacts.data.map((absentChatWithContacts) => ({
      contactId: absentChatWithContacts.contactId,
      chats: absentChatWithContacts.chats.map((chat) => chat.chatId),
    }));
    dispatch(updateSearchedChatsWithContact(searchedArray));

    dispatch(updateChatWithContactOrder);
  }
});

export const CHAT_ALREADY_EXISTS = '100001';
export const createChat = createAsyncThunk<unknown,
  { companyId: number; connectionId: number, contactId?: number; phone?: string, username?: string },
  { state: { companies: CompaniesSlice; chats: ChatsSlice } }>('chat/createChat', async (requestOption, {
  dispatch,
  getState,
}) => {
  const { companies: { currentCompany } } = getState();
  try {
    const postChatResponse = await postChat(requestOption);
    if (postChatResponse.createdContact) {
      dispatch(fetchContact({
        contactId: postChatResponse.contactId,
        companyId: currentCompany.id,
      }));
      await dispatch(
        fetchChatWithContactByContactId({
          contactId: postChatResponse.contactId,
          companyId: currentCompany.id,
        })
      )
        .unwrap()
        .then((res) => {
          const currentChat = res.chats.find((chat) => chat.chatId === postChatResponse.chatId);
          dispatch(selectChat(currentChat!));
          dispatch(
            push(`/companies/${currentCompany.id}/chats/${postChatResponse.contactId}/${postChatResponse.chatId}`)
          );
        });
        dispatch(updateErrors({
          key: 'createNewChat',
          value: { errorMessage: 'fulfilled' },
        }));
    }
    if (postChatResponse.createdChat && !postChatResponse.createdContact && postChatResponse.contactId) {
      const chat = await getChatById({
        companyId: currentCompany.id,
        connectionId: requestOption.connectionId,
        chatId: postChatResponse.chatId,
      });
      dispatch(updateChatsWithContact({
        chat,
        contactId: postChatResponse.contactId,
      }));
      dispatch(selectChat(chat));
      dispatch(
        push(`/companies/${currentCompany.id}/chats/${postChatResponse.contactId}/${postChatResponse.chatId}`)
      );
      dispatch(updateErrors({
        key: 'createNewChat',
        value: { errorMessage: 'fulfilled' },
      }));
    }
    if (!postChatResponse.createdChat && !postChatResponse.createdChat) {
      dispatch(
        updateErrors({
          key: 'createNewChat',
          value: {
            errorMessage: CHAT_ALREADY_EXISTS,
            payload: {
              contactId: postChatResponse.contactId,
              chatId: postChatResponse.chatId,
            },
          },
        })
      );
    }
  } catch (e) {
    const { response } = e;
    dispatch(updateErrors({
      key: 'createNewChat',
      value: { errorMessage: response.data.error_code },
    }));
  }
});

const chatsSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateSearchedChats: (state) => {
      state.searchedChats = null;
    },
    selectChat: (state, action: PayloadAction<ChatType>) => {
      if (state.currentChat.chatId !== action.payload.chatId) {
        state.currentChat = action.payload;
        state.messages = null;
      }
    },
    selectedChatById: (state, action: PayloadAction<number>) => {
      if (state.chatsWithContacts) {
        let chat: ChatType | undefined;
        state.chatsWithContacts.data.forEach((chatWithContact) => {
          const chatInChatWithContact = chatWithContact.chats.find(
            (chatInArray) => chatInArray.chatId === action.payload
          );
          if (chatInChatWithContact) chat = chatInChatWithContact;
        });

        if (chat) {
          state.currentChat = chat;
        }
      }
    },
    updateSearchedChatsWithContact: (state, action: PayloadAction<SearchedChatsWithContacts[] | null>) => {
      state.searchedChatsWithContacts = action.payload;
    },
    updateSearchedChatsValue: (state, action: PayloadAction<string>) => {
      state.searchedValueChats = action.payload;
    },
    updateIsBlurred: (state, action: PayloadAction<boolean>) => {
      state.isBlurred = action.payload;
    },
    updateNotificationData: (state, action: PayloadAction<NotificationData | null>) => {
      state.notificationData = action.payload;
    },
    updateErrors: (
      state,
      action: PayloadAction<{
        key: string;
        value: { errorMessage: string; payload?: { contactId: number; chatId: number } } | null;
      }>
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
    updateChatsWithContact: (state, action: PayloadAction<{ contactId: number; chat: ChatType }>) => {
      if (state.chatsWithContacts?.data) {
        const chatWithContact = state.chatsWithContacts.data.find(
          (chat) => chat.contactId === action.payload.contactId
        );
        if (chatWithContact) {
          chatWithContact.chats.push(action.payload.chat);
        }
      }
    },
    clearContactsChats: (state) => {
      state.chatsWithContacts = null;
    },
    // пришло новое сообщения, отсортируй у всех контактов и внутри их чаты, для отрисовки обновленного списка в чатах
    // но я его уже сортирую прям в компоненте ConversationList, думаю можно удалять?
    updateChatWithContactOrder: (state) => {
      const chatsWithContact = state.chatsWithContacts?.data;

      if (chatsWithContact) {
        // пробегись по всем контактам и отсортируй все чаты по дате/что бы свежие сообщение были впереди
        // По свойству sortWeight отсортируй чаты и положи в lastMessageInfo самую свежую сообщения
        chatsWithContact.forEach((chatWithContact) => {
          const { chats } = chatWithContact;
          if (chats && chats.length) {
            for (let i = 0; i < chats.length; i++) {
              chats[i].sortWeight = generateChatSortWeight(chats[i]);
            }
            sortChats(chats);

            // после сортировки, в данное свойства кладем самый свежую сообщение
            chatWithContact.lastMessageInfo = {
              chatId: chats[0].chatId,
              connectionId: chats[0].connectionId,
              lastMessage: chats[0].lastMessage,
            };
          } else {
            // если чатов, нет, то значение undefined
            chatWithContact.lastMessageInfo = undefined;
          }

          // здесь формируется sortWeight для контакты, что бы в дальнейшим сортировать их по значению sortWeight
          chatWithContact.sortWeight = generateChatsWithContactSortWeight(chatWithContact);
        });

        // сортировка по значение sortWeight
        sortChats(chatsWithContact);
      }
    },
    // для обновления неотвеченных счетчиков
    // пришло новое сообщения, найди этот контакт
    // у этого контакта lastMessageInfo, положи это сообщения
    // найди у этого контакта, тот чат куда пришло новое сообщения
    // в это чате: добавь lastMessage:новое сообщения и если вх. сообщения, то увеличь на одну - счетчик неотвеченных
    // и увеличь счетчик в самом контакте и в сторе у currentChat
    updateChatWithContacts: (
      state,
      {
        payload: { convertedMessage, message },
      }: PayloadAction<{
        convertedMessage: { connectionId: number; chatId: number; message: string; contactId: number };
        message: MessageRequest;
      }>
    ) => {
      if (state.chatsWithContacts && state.chatsWithContacts.data) {

        // пришло новое сообщения, найди его контакт
        const chatWithContact = state.chatsWithContacts.data.find(
          (chat) => chat.contactId === convertedMessage.contactId
        );

        // нашел контакт связанной с сообщением
        if (chatWithContact) {
          // нашел, сразу положи в lastMessageInfo, данные из бэка
          chatWithContact.lastMessageInfo = {
            chatId: convertedMessage.chatId,
            connectionId: convertedMessage.connectionId,
            lastMessage: message,
          };

          // найди это сообщения в контакте
          const chat = chatWithContact.chats.find(
            (chatInContact) =>
              chatInContact.chatId === convertedMessage.chatId &&
              chatInContact.connectionId === convertedMessage.connectionId
          );
          // нашел
          if (chat) {
            // положи в свойства lastMessage = сообщение
            chat.lastMessage = message;
            // данное сообщения входящее? да добавь +1 в поле неотвеченных в чате
            if (message.direction === 'inbound') {
              chat.unansweredCount += 1;
            }
          }
          // и добавь в самом в контакте
          if (message.direction === 'inbound') {
            chatWithContact.unansweredCount += 1;

            // добавь +1 в поле неотвеченных в текущий чат в store
            if (state.currentChat.chatId && convertedMessage.chatId === state.currentChat.chatId) {
              state.currentChat.unansweredCount += 1;
            }
          }
        }
      }
    },
    pushSearchedChatsWithContacts: (state, action: PayloadAction<ChatsWithContacts[]>) => {
      if (state.chatsWithContacts) {
        state.chatsWithContacts.data.push(...action.payload);
      }
    },
    updateCurrentChat: (state, action: PayloadAction<unknown>) => {
      state.currentChat = action.payload as ChatType;
    },
    updateFileType: (state, action: PayloadAction<string>) => {
      state.currentFileType = action.payload;
    },
    addUploadFileMessage: (state, action) => {
      const payload = createMessageData(action.payload);

      if (state.messages) {
        const isMessage = state.messages.find(i => i.messageId === payload.messageId);
        if (!isMessage)
          // @ts-ignore
          state.messages.push(payload);
      } else {
        state.messages = [];
        // @ts-ignore
        state.messages.push(payload);
      }
    },
    deleteUploadFileMessage: (state, action) => {
      if (state.messages) {
        state.messages = state.messages.filter(i => i.messageId !== action.payload);
      }
    },
    addMessageFromSocket: (state, action: PayloadAction<MessageRequest>) => {

      if (state.messages) {
        const isMessage = state.messages.find(i => i.messageId === action.payload.messageId);
        if (!isMessage)
          state.messages.push(action.payload);
      } else {
        state.messages = [];
        state.messages.push(action.payload);
      }
    },
    updateMessages: (state, action: PayloadAction<unknown>) => {
      state.messages = action.payload as MessageRequest[];
    },
    setCurrentContactId: (state, action: PayloadAction<number>) => {
      state.currentContactId = action.payload;
    },
    setCurrentChatById: (state, action: PayloadAction<ChatType>) => {
      state.currentChat = action.payload;
    },
    updateMessageDeliveryStatus: (state, action: PayloadAction<SocketDeliveryStatus>) => {
      if (!state.messages) return;
      const message = state.messages.find((nextMessage) => nextMessage.messageId === action.payload.messageId);
      if (!message) {
        return;
      }
      const { status } = action.payload;
      switch (status) {
        case 'read':
          message.readAt = 'read';
          break;
        case 'delivered':
          message.deliveredAt = 'delivered';
          break;
        case 'error':
          message.errorAt = 'error';
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state) => {
      state.loaders.fetchMessages = true;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      action.payload.reverse();
      if (state.messages) {
        state.messages.unshift(...action.payload);
      } else {
        state.messages = [];
        state.messages.unshift(...action.payload);
      }
      state.loaders.fetchMessages = false;
    });
    builder.addCase(sendFile.fulfilled, (state, action) => {
      state.currentFileUrl = action.payload.url;
    });
    builder.addCase(fetchChatsWithContact.fulfilled, (state, action) => {
      state.chatsWithContacts = action.payload;
      state.loaders.fetchChatsWithContact = false;
    });
    builder.addCase(fetchChatsWithContact.pending, (state) => {
      state.loaders.fetchChatsWithContact = true;
    });
    builder.addCase(fetchChatsWithContact.rejected, (state) => {
      state.loaders.fetchChatsWithContact = false;
    });
    builder.addCase(markChatIsAnswered.fulfilled, (state, action) => {
      const { chatId, connectionId } = action.meta.arg;
      if (state.chatsWithContacts && state.chatsWithContacts.data.length > 0) {
        const contact = state.chatsWithContacts.data.find(contact => contact.chats.some(
          chat => chat.chatId === chatId && chat.connectionId === connectionId));
        if (contact) {
          const chat = contact.chats.find(chat => chat.chatId === chatId);
          if (chat) {
            contact.unansweredCount -= chat.unansweredCount;
            chat.unansweredCount = 0;
          }
        }
      }
    });
    builder
      .addCase(fetchChatWithContactByContactId.fulfilled, (state, action) => {
        if (state.chatsWithContacts) {
          const duplicate = state.chatsWithContacts.data.find(i => i.contactId === action.payload.contactId);
          if (!duplicate) {
            state.chatsWithContacts.data.push(action.payload);
          }
        }
      })
      .addCase(fetchNextChatsWithContact.fulfilled, (state, action) => {
        const chats = [...action.payload.data];
        const { nextCursor } = action.payload.responseMetadata;

        chats.forEach((chat) => {
          if (state.chatsWithContacts && state.chatsWithContacts.data) {
            const foundChatIndex = state.chatsWithContacts.data.findIndex(
              (currentChat) => currentChat.contactId === chat.contactId
            );
            const { chats: contactChats } = chat;

            if (contactChats && contactChats.length) {
              for (let i = 0; i < contactChats.length; i++) {
                contactChats[i].sortWeight = generateChatSortWeight(contactChats[i]);
              }

              sortChats(contactChats);

              chat.lastMessageInfo = {
                chatId: contactChats[0].chatId,
                connectionId: contactChats[0].connectionId,
                lastMessage: contactChats[0].lastMessage,
              };
            } else {
              chat.lastMessageInfo = undefined;
            }

            if (foundChatIndex !== -1) {
              const updatingChat = chats.find(
                (nextChat) => nextChat.contactId === state.chatsWithContacts!.data[foundChatIndex].contactId
              );
              if (updatingChat) {
                state.chatsWithContacts.data[foundChatIndex] = updatingChat;
              }
            } else {
              state.chatsWithContacts.data.push(chat);
            }
          }
        });

        if (state.chatsWithContacts) {
          state.chatsWithContacts.responseMetadata.nextCursor = nextCursor;
        }
      })
      .addCase(fetchChatsWithContactsBySearchedValue.fulfilled, (state) => {
        if (!state.searchedValueChats.length) {
          state.searchedChatsWithContacts = null;
        }
      })
      .addCase(sendMessage.fulfilled, (state, action: PayloadAction<MessageModeType>) => {
        const { message } = action.payload;

        if(state.currentChat.sourceChatId === message.chatId){
          if (state.messages) {
            const isMessage = state.messages.find(i => i.messageId === message.messageId);
            if (!isMessage)
              state.messages.push(message);
          } else {
            state.messages = [];
            state.messages.push(message);
          }
        }
      })
      .addCase(fetchDialogsCounters.fulfilled, (state, action: PayloadAction<DialogsCountsType[]>) => {
        state.countsDialogs = action.payload;
    })
  },
});

export const {
  selectChat,
  updateSearchedChats,
  updateSearchedChatsValue,
  updateChatsWithContact,
  updateErrors,
  updateCurrentChat,
  updateIsBlurred,
  updateNotificationData,
  updateFileType,
  pushSearchedChatsWithContacts,
  addUploadFileMessage,
  deleteUploadFileMessage,
  updateChatWithContactOrder,
  updateSearchedChatsWithContact,
  updateMessageDeliveryStatus,
  updateMessages,
  addMessageFromSocket,
  setCurrentContactId,
  updateChatWithContacts,
  selectedChatById,
  setCurrentChatById,
  clearContactsChats
} = chatsSlice.actions;

export default chatsSlice.reducer;
