import { useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../store/store';
import { updateIsBlurred } from '../views/Chat/chatsSlice';
import {
  availableQuestioningSelector,
  currentCompanySelector,
  getCurrentCompanyRoleSelector
} from '../views/Chat/companiesSelector';
import { usePermittedRoutes } from './routesConfig';
import { useSocketSetup, useUpdateCompany } from './api';
import { useAccessMobile, useMobile } from '../components/utils/hooks';
import { convertUrl } from './helpers';
import { fetchQuestioning } from "../views/Chat/companiesSlice";
import { useNotificationsSetup } from '../views/PartnerCabinet/Notifications/api/socket';
import { useNotificationSocketSetup } from "../views/Settings/utility/socket"
import SettingsRoute from '../views/Settings/SettingsRoute';
import Error404 from '../pages/Error404/Error404';
import NewMessageNotification from '../components/NewMessageNotification/NewMessageNotification';
import Onboarding from '../modals/Onboarding/Onboarding';
import Alert from '../components/Alert/Alert';
import NavbarMobile from '../pages/Home/Navbar/NavbarMobile';
import Navbar from '../pages/Home/Navbar/NavbarDesktop';
import Notifications from 'react-push-notification/dist/notifications/Notifications';
import loader from '../assets/grid.svg';
import Questioning from '../modals/Questioning';


const CompaniesRoute = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentCompany = useSelector(currentCompanySelector);
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const availableQuestioning = useSelector(availableQuestioningSelector)
  const windowOnBlurEventListener = useRef<() => void>();
  const windowOnFocusEventListener = useRef<() => void>();
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const updateCompany = useUpdateCompany();
  const permittedRoutes = usePermittedRoutes();
  const isMobile = useMobile();
  const isMobileComponent = useAccessMobile();
  useSocketSetup();
  useNotificationsSetup();
  useNotificationSocketSetup();

  useEffect(() => {
    if (currentRole && params) {
      if ((Number(params?.params.companyId) === currentCompany.id && params.isExact)
        || (Number(params?.params.companyId) !== currentCompany.id && !params.isExact)) {
        let path = '';
        if (permittedRoutes.length > 0 && permittedRoutes[0].permission.endsWith('view')) {
          path = permittedRoutes[0].path;
        } else {
          path = '/settings';
        }
        let url = params.url;
        if (Number(params?.params.companyId) !== currentCompany.id) url = convertUrl(url, currentCompany.id);
        history.push(`${url}${path}`);
      } else if (Number(params?.params.companyId) !== currentCompany.id && params?.isExact) {
        // upload another company
        updateCompany(Number(params?.params.companyId));
      }
    }
  }, [params?.params.companyId, currentCompany, currentRole]);

  useEffect(() => {
    windowOnBlurEventListener.current = () => {
      dispatch(updateIsBlurred(true));
    };
    windowOnFocusEventListener.current = () => {
      dispatch(updateIsBlurred(false));
    };
    window.addEventListener('blur', windowOnBlurEventListener.current);
    window.addEventListener('focus', windowOnFocusEventListener.current);

    return () => {
      window.removeEventListener('blur', windowOnBlurEventListener.current!);
      window.removeEventListener('focus', windowOnFocusEventListener.current!);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchQuestioning());
  }, []);

  if (currentCompany.id) {
    if (!currentRole?.permissions || params?.params.companyId !== currentCompany.id.toString()) {
      return (
        <div className='loaderContainer'>
          <img src={loader} alt='loader' />
        </div>
      );
    }
  }

  return (<>
    <Notifications />
    <Onboarding />
    <Alert />
    <div className="contentContainer">
      {availableQuestioning === 'ROLE_AND_NICHE' && <Questioning />}
      {(isMobileComponent && isMobile) ? <NavbarMobile /> : <Navbar />}
      {currentRole && (
        <>
          <Switch>
            {permittedRoutes.map(({ path, exact, Component }) =>
              <Route key={path} path={params!.url + path} exact={exact}>
                <Component />
              </Route>
            )}
            <Route path={`${params!.url}/settings`}>
              <SettingsRoute />
            </Route>
            <Route path="*">
              <Error404 />
              <Redirect from="*" to="/404" />
            </Route>
          </Switch>
          <NewMessageNotification />
        </>
      )}
    </div>
  </>);
};

export default CompaniesRoute;
