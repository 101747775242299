import React from 'react';
import './CustomTemplateCard.scss';
import { CustomTemplate } from '../../templatesTypes';
import CardButtons from '../../Components/CardButtons/CardButtons';


type Props = {
  template: CustomTemplate;
  setTemplateEdit: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>> | undefined;
};

const CustomTemplateCard: React.FC<Props> = ({ template, setTemplateEdit }) => (
  <div className="customTemplateCard">
    <div className="customTemplateCard__content">
      <p className="regularText semibold">{template.name}</p>
      <p className="regularText">{template.text}</p>
    </div>
    <CardButtons template={template} setTemplateEdit={setTemplateEdit} />
  </div>
);

export default CustomTemplateCard;
