import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddRoleParamType,
  addRoleMemberAPI,
  AmoCRMConnection,
  Company,
  Connections,
  createCompany,
  CreateCompanyRequest,
  deleteMember,
  EditProfileSettings,
  getCompanies,
  getCompanyName,
  getConnections,
  getLink,
  getMembers,
  getProfileSettings,
  getRolesCompanyAPI,
  getUserInfo,
  InviteLink,
  joinCompany,
  Members,
  patchProfileSettings,
  ProfileSettings,
  refreshLink,
  RemoveMemberType,
  RoleType,
  unsetFirstLogin,
  UserInfo,
  UsersType,
  WabaConnection,
  ChangeRoleParamType,
  changePermissionRoleMemberAPI,
  DeleteRoleParamType,
  deleteRoleMemberAPI,
  ChangeRoleMemberParamType,
  changeRoleMemberAPI,
  ResGetAllConnForChatType,
  ReqGetAllConnForChatType,
  getConnForChatAPI,
  getCompany,
  CompaniesListRequest,
  ConnectionLimits,
  getConnectionLimits,
  ConnectionLimitsRequest,
  UpdateCompanyParam,
  updateCompanyAPI,
  getCompanyOnboarding,
  updateCompanyOnboarding, createQuestioningAPI, getAvailableQuestioning,
} from '../../api/CompanyAPI';
import { startOnboarding } from '../../modals/Onboarding/onboardingSlice';
import { clearSubscriptionsInfo } from '../Settings/Subscriptions/SubscriptionsSlice';
import { clearSales } from '../Sales/SalesSlice';
import { clearShops } from '../Sales/Shops/shopsSlice';
import { YandexMetrikaCall } from '../../utils/utils';
import { clearContactsChats } from './chatsSlice';
import {
  AvailableQuestioning,
  CompanyOnboarding,
  CompanyOnboardingStep,
  CompanyOnboardingSteps,
  CompanyOnboardingUpdate,
  QuestioningCreate,
  QuestioningType
} from '../../api/types';
import { clearTemplates } from '../Templates/templatesSlice';


export type CompaniesSlice = {
  count: number;
  companies: Array<Company> | null;
  invitationCompanyName: string | undefined;
  connectionLimit: ConnectionLimits;
  connections: Array<WabaConnection>;
  connectionsAmo: Array<AmoCRMConnection>;
  currentCompany: Company;
  profileSettings: ProfileSettings;
  url: string;
  currentConnectionId: number | 'Все чаты' | null;
  currentConnectionAmoId: number | string | null;
  members: Members;
  userInfo: UserInfo | null
  errors: {
    apiKey: null | boolean;
    editNumberPhoneApiKey: null | boolean;
  };
  errorsBePaid: {
    secretKey: null | boolean;
    editBePaidSecretKey: null | boolean;
  };
  isLoadingAddNewCompany: boolean;
  isDirtyEditConnection: boolean;
  rolesCompany: Array<RoleType>;
  currentCompanyRole: RoleType | null;
  allConnectionsForChat: ResGetAllConnForChatType[];
  isViewNavbarMobile: boolean;
  isOpenNavbarMobile: boolean;
  onboarding: CompanyOnboarding | null;
  onboardingGuideOn: CompanyOnboardingSteps | null;
  onboardingSwitchModal: boolean;
  availableQuestioning: (QuestioningType | null);
};

const initialState: CompaniesSlice = {
  count: 0,
  companies: null,
  invitationCompanyName: undefined,
  connectionLimit: {} as ConnectionLimits,
  connections: [],
  connectionsAmo: [],
  profileSettings: {} as ProfileSettings,
  url: '',
  currentCompany: {} as Company,
  currentConnectionId: null,
  currentConnectionAmoId: null,
  members: {} as Members,
  userInfo: null,
  errors: {
    apiKey: null,
    editNumberPhoneApiKey: null,
  },
  errorsBePaid: {
    secretKey: null,
    editBePaidSecretKey: null,
  },
  isLoadingAddNewCompany: false,
  isDirtyEditConnection: false,
  rolesCompany: [],
  currentCompanyRole: null,
  allConnectionsForChat: [],
  isOpenNavbarMobile: false,
  isViewNavbarMobile: true,
  onboarding: null,
  onboardingGuideOn: null,
  onboardingSwitchModal: true,
  availableQuestioning: null
};

export const fetchInitialData = createAsyncThunk<Company[], number | undefined>(
  'companies/fetchCompanies', async (requestOption, { dispatch }) => {
    dispatch(fetchUserInfo());
    dispatch(profileSettings());
    const { companies } = await getCompanies({});
    if (companies.length > 0) {
      let companyId: number;
      if (requestOption) {
        const findCompany = companies.find((company) => company.id === requestOption);
        if (findCompany === undefined) {
          try {
            const company = await getCompany(requestOption);
            companies.push(company);
            companyId = company.id;
          } catch {
            companyId = companies[0].id;
          }
        } else {
          companyId = findCompany.id;
        }
      } else {
        companyId = companies[0].id;
      }
      dispatch(getMember(companyId));
    }
    return companies;
  }
);

export const fetchCompanies = createAsyncThunk<Array<Company>, CompaniesListRequest, { rejectValue: number }>(
  'companies/fetchCompaniesList', async (requestOptions, { rejectWithValue }) => {
    try {
      const { companies } = await getCompanies(requestOptions);
      return companies;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const addNewCompany = createAsyncThunk<Company, CreateCompanyRequest, { rejectValue: number }>(
  'companies/addNewCompany', async (requestOptions, { dispatch, rejectWithValue }) => {
    try {
      const company = await createCompany(requestOptions);
      await dispatch(getMember(company.id));
      dispatch(clearConnections());
      dispatch(clearSubscriptionsInfo());
      dispatch(clearSales());
      dispatch(clearShops());
      dispatch(clearContactsChats());
      dispatch(clearTemplates());
      return company;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const updateCompany = createAsyncThunk<Company,UpdateCompanyParam,{ rejectValue: number }>(
  'companies/updateCompany',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await updateCompanyAPI(requestOptions);
    }catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchCompanyName = createAsyncThunk('companies/fetchCompanyName', async (requestOptions: string) =>
  getCompanyName(requestOptions)
);

export const fetchConnectionLimits = createAsyncThunk<ConnectionLimits,
  ConnectionLimitsRequest,
  { rejectValue: number }>('companies/fetchConnectionsLimits', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getConnectionLimits(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const fetchConnections = createAsyncThunk<Connections,
  { companyId: number, nameConnection?: string },
  { rejectValue: number }>('companies/fetchConnections', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getConnections(requestOptions.companyId);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getAllConnectionForChat = createAsyncThunk<Array<ResGetAllConnForChatType>,
  ReqGetAllConnForChatType,
  { rejectValue: number }>('companies/getAllConnectionForChat', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getConnForChatAPI(requestOptions.companyId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);



export const profileSettings = createAsyncThunk('companies/settingsProfile', async () => getProfileSettings());

export const editProfileSettings = createAsyncThunk(
  'companies/editProfileSettings',
  async (requestOptions: EditProfileSettings) => patchProfileSettings(requestOptions)
);

export const getInviteLink = createAsyncThunk('companies/getInviteLink', async (company_id: number) =>
  getLink(company_id)
);

export const postRefreshLink = createAsyncThunk('companies/postRefreshLink', async (company_id: number) =>
  refreshLink(company_id)
);

export const joinMemberToCompany = createAsyncThunk<UsersType,
  string,
  { rejectValue: number }>(
  'companies/joinCompany',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await joinCompany(requestOption);
    } catch (err) {
      return rejectWithValue(err.response.data.error_code);
    }
  }
);

export const fetchUserInfo = createAsyncThunk('companies/getUserInfo', async () => getUserInfo());

export const getMember = createAsyncThunk<Members, number, { rejectValue: number }>(
  'companies/getMember', async (requestOption, { dispatch, rejectWithValue }) => {
    try {
      const { userId } = await getProfileSettings();
      const members = await getMembers(requestOption);
      const roles = await getRolesCompanyAPI(requestOption);
      dispatch(setCompanyRoles(roles));
      if (userId && roles) {
        const currentMember = members.members.find(i => i.userId === userId);
        if (currentMember) {
          const memberRole = roles.find(i => i.id === currentMember?.roleId);
          if (memberRole) {
            dispatch(setCurrentCompanyRole(memberRole));
            members.currentMemberRole = memberRole;
          }
        }
      }
      return members;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const getRolesCompany = createAsyncThunk<Array<RoleType>, number, { rejectValue: number }>(
  'companies/getRolesCompany', async (companyId) => {
  try {
    return await getRolesCompanyAPI(companyId);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const addRoleMember = createAsyncThunk<RoleType, AddRoleParamType, { rejectValue: number }>(
  'companies/addRoleMember',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await addRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);
export const changePermissionRoleMember = createAsyncThunk<RoleType, ChangeRoleParamType, { rejectValue: number }>(
  'companies/changePermissionRole',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await changePermissionRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);


export const deleteRoleMember = createAsyncThunk<void, DeleteRoleParamType, { rejectValue: number }>(
  'companies/deleteRoleCompany',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await deleteRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);
export const changeRoleMember = createAsyncThunk<UsersType, ChangeRoleMemberParamType, { rejectValue: number }>(
  'companies/changeRoleMember',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await changeRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const removeMember = createAsyncThunk<void, RemoveMemberType, { rejectValue: number }>(
  'companies/removeMember',
  async (requestOption, {
    rejectWithValue, dispatch,
  }) => {
    try {
      const res = await deleteMember(requestOption);
      dispatch(
        removeDeletedMember({
          memberId: requestOption.memberId,
        })
      );
      return res;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const updateFirstLogin = createAsyncThunk<void,
  void,
  { rejectValue: number }>(
  'companies/updateFirstLogin',
  async (_, { dispatch, rejectWithValue }) => {

    try {
      dispatch(startOnboarding());
      return await unsetFirstLogin();

    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const fetchOnboarding = createAsyncThunk<CompanyOnboarding, number, { rejectValue: number }>(
  'companies/getOnboarding', async(companyId, { rejectWithValue }) => {
    try {
      return await getCompanyOnboarding(companyId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const patchOnboarding = createAsyncThunk<CompanyOnboarding, CompanyOnboardingUpdate, { rejectValue: number }>(
  'companies/updateOnboarding', async(requestOptions, { rejectWithValue }) => {
    try {
      return await updateCompanyOnboarding(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchQuestioning = createAsyncThunk<AvailableQuestioning>(
  'companies/fetchQuestioning', async (requestOption, { rejectWithValue }) => {
    try {
      return await getAvailableQuestioning();
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const createQuestioning = createAsyncThunk<AvailableQuestioning, QuestioningCreate, { rejectValue: number }>(
  'companies/createQuestioning', async (requestOption, { rejectWithValue }) => {
    try {
      return await createQuestioningAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCurrentConnectionId: (state, action) => {
      state.currentConnectionId = action.payload;
    },
    setCurrentCompanyRole: (state, action: PayloadAction<RoleType | null>) => {
      state.currentCompanyRole = action.payload;
    },
    setCompanyRoles: (state, action: PayloadAction<Array<RoleType>>) => {
      state.rolesCompany = action.payload;
    },
    setDeleteRoleCompany: (state, action: PayloadAction<number>) => {
      state.rolesCompany = state.rolesCompany.filter((i) => i.id !== action.payload);
    },
    setCurrentCompany: (state, action) => {
      if (state.companies) {
        const companyById = state.companies.find((company) => company.id === action.payload);
        if (companyById) {
          state.currentCompany = companyById;
        }
      }
    },
    setHadConnections: (state, action: PayloadAction<boolean>) => {
      state.currentCompany = { ...state.currentCompany, hadConnections: action.payload };
      if (state.companies) {
        const indexCompany = state.companies.findIndex((company) => company.id === state.currentCompany.id);
        state.companies[indexCompany].hadConnections = action.payload;
      }
    },
    updateErrors: (state, action: PayloadAction<{ type: string; value: boolean | null }>) => {
      if (action.payload.type === 'apiKey') {
        state.errors.apiKey = action.payload.value;
      }
      if (action.payload.type === 'editNumberPhoneApiKey') {
        state.errors.editNumberPhoneApiKey = action.payload.value;
      }
    },
    updateErrorsBePaid: (state, action: PayloadAction<{ type: string; value: boolean | null }>) => {
      if (action.payload.type === 'secretKey') {
        state.errors.apiKey = action.payload.value;
      }
      if (action.payload.type === 'editBePaidSecretKey') {
        state.errors.editNumberPhoneApiKey = action.payload.value;
      }
    },
    removeDeletedMember: (state, action: PayloadAction<{ memberId: number }>) => {
      const memberIdx = state.members.members.findIndex((member) => member.id === action.payload.memberId);
      if (memberIdx) {
        state.members.members.splice(memberIdx, 1);
      }
    },
    setIsDirtyEditConnection: (state, action: PayloadAction<boolean>) => {
      state.isDirtyEditConnection = action.payload;
    },
    setUpdateSettingPhonesLanguage: (state, action: PayloadAction<string>) => {
      state.profileSettings.language = action.payload;
    },
    clearConnections: (state) => {
      state.connections = [];
      state.connectionsAmo = [];
      state.currentConnectionId = null;
    },
    updateIsViewNavbarMobile: (state, action: PayloadAction<boolean>) => {
      state.isViewNavbarMobile = action.payload;
    },
    updateIsOpenNavbarMobile: (state, action: PayloadAction<boolean>) => {
      state.isOpenNavbarMobile = action.payload;
    },
    setOnboardingGuide: (state, action: PayloadAction<CompanyOnboardingSteps | null>) => {
      state.onboardingGuideOn = action.payload;
    },
    setOnboardingSwitchModal: (state, action: PayloadAction<boolean>) => {
      state.onboardingSwitchModal = action.payload;
    },
    updateOnboardingStep: (state, action: PayloadAction<CompanyOnboardingStep>) => {
      if (state.onboarding) {
        const stepIndex = state.onboarding?.steps.findIndex((item) => item.type === action.payload.type);
        if (stepIndex !== -1) {
          state.onboarding.steps[stepIndex] = action.payload;
        }
      }
    },
    clearOnboarding: (state) => {
      state.onboarding = null;
      state.onboardingGuideOn = null;
    },
    clearOnboardingGuide: (state) => {
      state.onboardingGuideOn = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialData.fulfilled, (state, action) => {
        state.companies = action.payload;
        let currentCompany;
        if (action.meta.arg) {
          currentCompany = state.companies.find((company) => company.id === action.meta.arg);
        }
        if (currentCompany === undefined) {
          currentCompany = action.payload[0];
        }
        state.currentCompany = currentCompany;
      })
      .addCase(fetchCompanies.fulfilled, (state, action: PayloadAction<Company[]>) => {
        if (state.companies !== null) {
          state.companies.push(...action.payload);
        } else {
          state.companies = action.payload;
        }
      })
      .addCase(profileSettings.fulfilled, (state, action) => {
        state.profileSettings = action.payload;
      })
      .addCase(getInviteLink.fulfilled, (state, action: PayloadAction<InviteLink>) => {
        state.url = action.payload.url;
      })
      .addCase(postRefreshLink.fulfilled, (state, action: PayloadAction<InviteLink>) => {
        state.url = action.payload.url;
      })
      .addCase(editProfileSettings.fulfilled, (state, action) => {
        state.profileSettings = action.payload;
      })
      .addCase(getMember.fulfilled, (state, action: PayloadAction<Members>) => {
        state.members = action.payload;
      })
      .addCase(fetchConnections.fulfilled, (state, action: PayloadAction<Connections>) => {
        const wabaConnections = action.payload.connections.filter((connection) => connection.type === 'waba');
        state.connections = wabaConnections as WabaConnection[];
        state.connectionsAmo = action.payload.connections.filter(
          (connection) => connection.type === 'amocrm'
        ) as AmoCRMConnection[];
        if (wabaConnections.length > 0) {
          state.currentConnectionId = wabaConnections[0].id;
        } else {
          state.currentConnectionId = null;
          state.connections = [];
        }
      })
      .addCase(getAllConnectionForChat.fulfilled, (state, action: PayloadAction<Array<ResGetAllConnForChatType>>) => {
        state.allConnectionsForChat = action.payload;
      })
      .addCase(addNewCompany.pending, (state) => {
        state.isLoadingAddNewCompany = true;
      })
      .addCase(addNewCompany.fulfilled, (state, action: PayloadAction<Company>) => {
        if (state.companies) {
          state.companies.push(action.payload);
          state.isLoadingAddNewCompany = false;
          state.currentCompany = action.payload;
        }
        YandexMetrikaCall('reachGoal', 'createCompany');
      })
      .addCase(updateCompany.fulfilled, (state, action: PayloadAction<Company>) => {
        if (state.companies) {
          state.currentCompany = action.payload;
          state.companies = state.companies.map((company) =>
            company.id === action.payload.id ? action.payload : company
          );
        }
      })
      .addCase(fetchCompanyName.fulfilled, (state, action: PayloadAction<{ companyName: string }>) => {
        state.invitationCompanyName = action.payload.companyName;
      })
      .addCase(fetchConnectionLimits.fulfilled, (state, action: PayloadAction<ConnectionLimits>) => {
        state.connectionLimit = action.payload;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.userInfo = action.payload;
      })
      .addCase(updateFirstLogin.fulfilled, (state) => {
        state.profileSettings.firstLogin = false;
      })
      .addCase(joinMemberToCompany.fulfilled, (state, action: PayloadAction<UsersType>) => {
        state.members.members = [action.payload];
        state.members.companyId = action.payload.companyId;
      })
      .addCase(getRolesCompany.fulfilled, (state, action: PayloadAction<Array<RoleType>>) => {
        state.rolesCompany = action.payload;
      })
      .addCase(addRoleMember.fulfilled, (state, action: PayloadAction<RoleType>) => {
        state.rolesCompany.push(action.payload);
      })
      .addCase(changeRoleMember.fulfilled, (state, action: PayloadAction<UsersType>) => {
        state.members.members = state.members.members.map((i) =>
          i.userId === action.payload.userId
            ? {
                ...i,
                roleId: action.payload.roleId,
              }
            : i
        );
      })
      .addCase(changePermissionRoleMember.fulfilled, (state, action: PayloadAction<RoleType>) => {
        state.rolesCompany = state.rolesCompany.map((i) => (i.id === action.payload.id ? action.payload : i));
      })
      .addCase(fetchOnboarding.fulfilled, (state, action: PayloadAction<CompanyOnboarding>) => {
        state.onboarding = action.payload;
      })
      .addCase(patchOnboarding.fulfilled, (state, action: PayloadAction<CompanyOnboarding>) => {
        state.onboarding = action.payload;
      })
      .addCase(fetchQuestioning.fulfilled, (state, action: PayloadAction<AvailableQuestioning>) => {
        state.availableQuestioning = action.payload.availableFormTypes[0];
      })
      .addCase(createQuestioning.fulfilled, (state) => {
        state.availableQuestioning = null;
      });
  },
});

export const {
  setCurrentConnectionId,
  setCurrentCompany,
  updateErrors,
  updateErrorsBePaid,
  removeDeletedMember,
  setIsDirtyEditConnection,
  setUpdateSettingPhonesLanguage,
  setCurrentCompanyRole,
  setCompanyRoles,
  setDeleteRoleCompany,
  clearConnections,
  updateIsViewNavbarMobile,
  updateIsOpenNavbarMobile,
  setHadConnections,
  setOnboardingGuide,
  setOnboardingSwitchModal,
  updateOnboardingStep,
  clearOnboarding,
  clearOnboardingGuide
} = companiesSlice.actions;

export default companiesSlice.reducer;
