import React, { useEffect, useState, useRef } from 'react';
import './Profile.scss';
import { Prompt } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ReactCountryFlag } from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClick } from '../../../components/utils/hooks';
import { editProfileSettings } from '../../Chat/companiesSlice';
import { profileSettingsInfo } from '../../Chat/companiesSelector';
import { arrayLanguages } from '../../../utils/utils';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import arrowDown from '../../../assets/arrow-down.svg';
import Button from '../../../components/Button';
import Switcher from '../../../components/Switcher';
import ExitButton from "../../../pages/Home/Navbar/components/ExitButton";


const Profile = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const infoProfile = useSelector(profileSettingsInfo);
  const [isNotification, setIsNotification] = useState(infoProfile.notificationsEnabled);
  const [isVisibleLang, setIsVisibleLang] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(infoProfile.language);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);
  const { t, i18n } = useTranslation();

  const translateMessage = {
    yes: t('yes'),
    no: t('no'),
    message: t('settings.profile.leave_page'),
  };

  const onCloseDropdown = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setIsVisibleLang(false);
  };

  useOutsideClick(wrapperRef, onCloseDropdown, triggerButtonRef);

  const checkButton = () => {
    setIsNotification(!isNotification);
  };

  const onSave = () => {
    if (currentLanguage || isNotification) {
      i18n.changeLanguage(currentLanguage);
      dispatch(
        editProfileSettings({
          language: currentLanguage,
          notifications_enabled: isNotification,
        })
      );
    }
  };

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
  }, []);

  useEffect(() => {
    if (isNotification !== infoProfile.notificationsEnabled || infoProfile.language !== currentLanguage) {
      setIsStateChanged(true);
    } else {
      setIsStateChanged(false);
    }
  }, [isNotification, infoProfile.notificationsEnabled, currentLanguage, infoProfile.language]);

  const onChooseLang = (lang: string) => {
    setCurrentLanguage(lang);
    setIsVisibleLang(false);
  };

  return (
    <div className="profile">
      <div className='profile__header'>
        <h1>{t('settings.profile.title')}</h1>
        <ExitButton />
      </div>
      <div className="profile__content">
        <div className="languageUI">
          <h3>{t('settings.profile.lang_UI')}</h3>
          <div className={isVisibleLang ? 'uiSelect open' : 'uiSelect'}
            ref={triggerButtonRef} onClick={() => setIsVisibleLang(!isVisibleLang)}>
            <div className="uiSelect__text">
              <ReactCountryFlag countryCode={currentLanguage === 'en' ? 'GB' : currentLanguage} svg />
              <p>{t(`onChangeLanguage.${currentLanguage}`)}</p>
            </div>
            <div className="uiSelect__arrow">
              <img src={arrowDown} alt="arrow icon" />
            </div>
            <div className={isVisibleLang ? 'uiSelected open' : 'uiSelected'} ref={wrapperRef}>
              {arrayLanguages.map(i =>
                <div key={i} className={currentLanguage === i ? 'uiSelected__text active' : 'uiSelected__text'}
                  id={i} onClick={() => onChooseLang(i)}>
                  <ReactCountryFlag countryCode={i === 'en' ? 'GB' : i} svg />
                  <p>{t(`onChangeLanguage.${i}`)}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <WrapperPermission permission='messagingChatView'>
          <div className="notification">
            <h3>{t('settings.profile.notification')}</h3>
            <Switcher toggled={isNotification} onToggle={checkButton}
              lable={isNotification
                  ? t('settings.profile.notification_on')
                  : t('settings.profile.notification_off')}
            />
          </div>
        </WrapperPermission>
        <div className="changePassword">
          <Button type='button' color='orange' textType='regular' text={t('settings.profile.change_password')}
            onClick={() => { keycloak.login({ action: 'UPDATE_PASSWORD' }); }} />
        </div>
      </div>
      <div className='buttonSave'>
        <Button type='button' color='orange' textType='regular' text={t('save_edit')}
                onClick={onSave} disabled={!isStateChanged} />
      </div>
      <Prompt when={isStateChanged} message={JSON.stringify(translateMessage)} />
    </div>
  );
};

export default Profile;
