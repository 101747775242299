import './InteractiveMessageSettingsButtonActions.scss';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateInteractiveTemplateData } from '../../templatesTypes';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import ButtonAction from './ButtonAction';
import Button from '../../../../components/Button';


const InteractiveMessageSettingsButtonActions = () => {
  const { t } = useTranslation();
  const methods = useFormContext<CreateInteractiveTemplateData>();
  const fieldArrayMethods = useFieldArray<CreateInteractiveTemplateData>({
    name: 'waba_interactive.action.buttons',
    control: methods.control,
  });

  const onAddButtonAction = () => {
    if (fieldArrayMethods.fields.length < 3) {
      fieldArrayMethods.append({ type: 'reply', reply: { title: '' } });
    }
  };

  return (
    <div className="interactiveMessageSettingsButtonActions">
      {fieldArrayMethods.fields.map((button, idx) => (
        <ButtonAction id={idx} {...methods} key={button.id} {...fieldArrayMethods} />
      ))}
      {fieldArrayMethods.fields.length < 3 && (
        <Button type='button' color='white' textType='small' image={<PlusIcon />}
          text={t('templates.add_interactive_templates.template_actions.buttons.add_button')}
          onClick={onAddButtonAction}
        />
      )}
    </div>
  );
};

export default InteractiveMessageSettingsButtonActions;
