import { useContext, useEffect, useState } from 'react';
import './WabaTemplatesModeration.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CreateWabaTemplateRequestExamples } from '../../templatesTypes';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { uploadWabaTemplateFile } from '../../templatesAPI';
import { wabaModeration } from './Context';
import { wabaTemplateContext } from '../WabaTemplateProvider/WabaTemplateProvider';
import { getPartsComponents } from '../../utils/waba';
import { useWabaTemplateRequest } from '../../api';
import { useAppSelector } from '../../../../store/store';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import WabaFileLoad from './WabaFileLoad';
import WabaPreview from '../WabaPreview';
import WabaParams from './WabaParams';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader/Loader';


type ParamsType = {
  id: number;
  value: string;
}

const WabaTemplatesModeration = () => {
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const [onAttachmentFile, setOnAttachmentFile] = useState<File>();
  const [arrayHeaderParams, setArrayHeaderParams] = useState<ParamsType[]>([]);
  const [arrayBodyParams, setArrayBodyParams] = useState<ParamsType[]>([]);
  const [firstURLParams, setFirstURLParams] = useState<ParamsType[]>([]);
  const [secondURLParams, setSecondURLParams] = useState<ParamsType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const { push } = useHistory();
  const loading = useAppSelector((state) => state.templates.wabaListLoading);
  const { connectionId, template } = useContext(wabaTemplateContext);
  const { makeWabaTemplate } = useWabaTemplateRequest();
  const { header, body, footer, buttons } = getPartsComponents(template);

  const wabaValues = {
    onAttachmentFile, setOnAttachmentFile,
    arrayHeaderParams, setArrayHeaderParams,
    arrayBodyParams, setArrayBodyParams,
    firstURLParams, setFirstURLParams,
    secondURLParams, setSecondURLParams,
    isError,
  };

  useEffect(() => {
    if (header && header.text) {
      const findParams = header.text.split(/{{(\d+)}}/gm);

      findParams.forEach((param, index) => {
        if (index % 2 === 1) {
          setArrayHeaderParams([...arrayHeaderParams, { id: Number(param), value: '' }]);
        }
      });
    }
    if (body && body.text) {
      const findParams = body.text.split(/{{(\d+)}}/gm);
      const numbers: number[] = [];

      findParams.forEach((param, index) => {
        if (index % 2 === 1 && !numbers.includes(Number(param))) {
          numbers.push(Number(param));
          setArrayBodyParams((arrayBodyParams) => [...arrayBodyParams, { id: Number(param), value: '' }]);
        }
      });
    }
    if (buttons) {
      let isFirst = false;
      buttons?.buttons.forEach((item) => {
        if (item.type === 'URL') {
          const findParams = item.url.split(/{{(\d+)}}/gm);

          if (!isFirst) {
            findParams[1] &&
            setFirstURLParams((firstURLParams) => [...firstURLParams, { id: Number(findParams[1]), value: '' }]);
            isFirst = true;
          } else
            findParams[1] &&
            setSecondURLParams((secondURLParams) => [...secondURLParams, { id: Number(findParams[1]), value: '' }]);
        }
      });
    }
  }, []);

  const onSubmit = async () => {
    let error: boolean;
    const isEmptyHeaderParams = arrayHeaderParams.find((item) => item.value === '');
    const isEmptyBodyParams = arrayBodyParams.find((item) => item.value === '');

    if (isEmptyHeaderParams || isEmptyBodyParams) {
      error = true;
      setIsError(true);
    } else {
      error = false;
      setIsError(false);
    }

    if (!error) {
      const headerItems = arrayHeaderParams.map((item) => item.value);
      const bodyItems = arrayBodyParams.map((item) => item.value);
      const buttonUrlItems: string[] = [];
      firstURLParams.map((item) => buttonUrlItems.push(item.value));
      secondURLParams.map((item) => buttonUrlItems.push(item.value));
      const headerUrl = onAttachmentFile &&
        (await uploadWabaTemplateFile({ companyId: currentCompany.id, file: onAttachmentFile }).then((res) => {
          return res.url;
        }));

      const examples: CreateWabaTemplateRequestExamples = {
        ...(headerItems.length > 0 ? { header_text: headerItems } : {}),
        ...(bodyItems.length > 0 ? { body_text: bodyItems } : {}),
        ...(buttonUrlItems && buttonUrlItems.length > 0 ? { button_url_text: buttonUrlItems } : {}),
        ...(headerUrl ? { header_url: headerUrl } : {}),
      };

      if (template) {
        template.examples = examples;

        await makeWabaTemplate(template).then((res) => {
          if (res) push({ pathname: location.pathname.replace('moderation', 'list'), state: { connectionId } });
        })
      }
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="wabaTemplatesModeration">
      <GoBackWrapper title={t('templates.waba_moderation.title')}>
        <wabaModeration.Provider value={wabaValues}>
          <div className="wabaTemplatesModeration__content">
            <div className="wabaTemplatesModeration__content_params">
              {header && header.format !== 'TEXT' ? (
                <div className="wabaTemplatesModeration__content_params_title">
                  <p className="regularText middle">{t('templates.waba_moderation.header_param')}</p>
                  <WabaFileLoad header={header} />
                </div>
              ) : null}
              <WabaParams header={header} body={body} buttons={buttons} />
            </div>
            <div className="wabaTemplatesModeration__content_preview">
              <h3>{t('templates.waba_moderation.preview')}</h3>
              <WabaPreview header={header} body={body} footer={footer} buttons={buttons} />
            </div>
            <Button text={t('templates.waba_moderation.submit')} color="orange"
              textType="regular" onClick={onSubmit} />
          </div>
        </wabaModeration.Provider>
      </GoBackWrapper>
    </div>
  );
};

export default WabaTemplatesModeration;
