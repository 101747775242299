import React, { useState } from "react";
import { ReactComponent as ChatsIcon } from '../../../assets/chat.svg';
import { ReactComponent as TemplatesIcon } from '../../../assets/template.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/settings.svg';
import { ReactComponent as ContactsIcon } from '../../../assets/users.svg';
import { ReactComponent as BroadcastsIcon } from '../../../assets/megaphone.svg';
import { ReactComponent as ShopsIcon } from "../../../assets/shops.svg";
import { ReactComponent as ProductsIcon } from "../../../assets/products.svg";
import { ReactComponent as CatalogsIcon } from "../../../assets/catalogs.svg";
import { ReactComponent as OrdersIcon } from "../../../assets/orders.svg";
import { ReactComponent as PartnersIcon } from "../../../assets/hand-shake.svg";


type KeysTabsNavbarType = 'chats' | 'templates' | 'contacts' | 'broadcasts' | 'settings' | 'partners';

export const getIconNavbar: Record<KeysTabsNavbarType, React.FunctionComponent> = {
  chats: ChatsIcon,
  templates: TemplatesIcon,
  contacts: ContactsIcon,
  broadcasts: BroadcastsIcon,
  settings: SettingsIcon,
  partners: PartnersIcon
};

export type KeysTabsSalesType = 'shops' | 'products' | 'catalogs' | 'orders';

export const getIconSales: Record<KeysTabsSalesType, React.FunctionComponent> = {
  shops: ShopsIcon,
  products: ProductsIcon,
  catalogs: CatalogsIcon,
  orders: OrdersIcon,
}

export const getViewPopup = () => {
  const [viewPopup, setViewPopup] = useState<boolean>(false);

  const onMouseEnter = () => {
    setViewPopup(true);
  }

  const onMouseLeave = () => {
    setViewPopup(false);
  }

  return { viewPopup, setViewPopup, onMouseEnter, onMouseLeave }
}
