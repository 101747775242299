import './IntegrationsList.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { integrationsListSelector, integrationsTypesSelector } from '../../integrationSelectors';
import { currentCompanySelector, onboardingGuideSelector } from '../../../../Chat/companiesSelector';
import { getIntegrationsList, getIntegrationsTypes } from '../../integrationSlice';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import IntegrationsAvailable from './IntegrationsAvailable';
import IntegrationsInstalled from './IntegrationsInstalled';
import GuideWrapper from '../../../../../components/Guide/GuideWrapper';


function IntegrationsList() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const integrationsTypes = useSelector(integrationsTypesSelector);
  const integrations = useSelector(integrationsListSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);

  useEffect(() => {
    if (company) {
      dispatch(getIntegrationsList(company.id));
      dispatch(getIntegrationsTypes({
        companyId: company.id,
        language: i18n.language
      }));
    }
  }, [company]);

  const listIntegrations = () => integrations?.map((item) => ({
    id: item.id,
    name: item.name,
    logoUrl: integrationsTypes.find((i) => item.type === i.type)?.logoUrl || '' })
  );

  return (
    <div className={`integrationsList ${onboardingGuideOn && 'guideOn'}`}>
      {integrations && <IntegrationsInstalled integrations={listIntegrations()} />}
      <WrapperPermission permission='integrationsCreate'>
        <>
          <GuideWrapper />
          <IntegrationsAvailable integrations={integrationsTypes} />
        </>
      </WrapperPermission>
    </div>
  );
}

export default IntegrationsList;
