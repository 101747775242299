import { useEffect, useState } from 'react';
import './Notifications.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { notificationContext } from './utils/types';
import { NotificationChannel, NotificationChannelTypes } from './NotificationsTypes';
import {
  deleteNotificationChannel,
  getNotificationsChannels, updateNotificationsChannel,
} from './NotificationsSlice';
import ActionDialog from '../../../components/Modals/ActionDialog';
import NotificationsList from './components/NotificationsList';
import NotificationBanner from './components/NotificationBanner';
import NotificationCreateWhatsapp from './components/NotificationCreateWhatsapp';
import NotificationCreateTelegram from './components/NotifcationCreateTelegram';


const Notifications = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const notifications = useAppSelector(state => state.notifications.notifications);
  const [channelConnection, setChannelConnection] = useState<NotificationChannelTypes | null>(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [removeChannelId, setRemoveChannelId] = useState<number | null>(null);

  useEffect(() => {
    if (currentCompany.id)
      dispatch(getNotificationsChannels({ companyId: currentCompany.id }));
  }, [currentCompany.id]);

  const deleteChannel = () => {
    if (removeChannelId && currentCompany.id) {
      dispatch(deleteNotificationChannel({
        channelId: removeChannelId,
        companyId: currentCompany.id
      }));
    }
    setToggleModal(false);
    setRemoveChannelId(null);
  };

  const onDelete = (channel: NotificationChannel) => {
    setRemoveChannelId(channel.id);
    setToggleModal(true);
  };

  const onEdit = (channel: NotificationChannel) => {
    dispatch(updateNotificationsChannel({
      companyId: currentCompany.id,
      channelId: channel.id,
      name: channel.name,
      type: channel.type,
      ...(channel.type === 'whatsapp' ? { whatsapp: { phone: channel.whatsapp.phone } } : {}),
      params: { events: channel.params.events }
    }));
  };

  return (
    <div className="notifications">
      {toggleModal &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          message={t('settings.notifications.delete.message', { number: removeChannelId })}
          title={t('settings.notifications.delete.title')}
          onAction={deleteChannel} setIsOpen={setToggleModal} />
      }
      <h1>{t('settings.notifications.notifications')}</h1>
      <p className='regularText'>{t('settings.notifications.description_text')}</p>
      <NotificationBanner setChannelConnection={setChannelConnection} />
      <notificationContext.Provider value={{ onDelete, onEdit }}>
        {notifications && <NotificationsList channels={notifications} />}
      </notificationContext.Provider>
      {channelConnection === 'whatsapp' &&
        <NotificationCreateWhatsapp setChannelConnection={setChannelConnection} />}
      {channelConnection === 'telegram' &&
        <NotificationCreateTelegram setChannelConnection={setChannelConnection} />}
    </div>
  );
};

export default Notifications;
