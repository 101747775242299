import React, { useEffect, useState } from 'react';
import './Products.scss';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import Button from '../../../components/Button/Button';
import CreateAndEditProduct from './components/CreateAndEditProduct/CreateAndEditProduct';
import SidePanel from '../../../components/Modals/SidePanel';
import Search from '../../../components/Search/Search';
import PreviewProducts from './components/PreviewProducts/PreviewProducts';
import ProductsList from './components/ProductsList/ProductsList';
import ActionDialog from '../../../components/Modals/ActionDialog';
import Paginator from '../../../components/Paginator';
import { productItemContext } from '../types';
import { Product } from '../SalesAPI';
import { RootState, useAppDispatch, useAppSelector } from "../../../store/store";
import { ProductsSelector, ProductLoadSelector } from "../SalesSelector";
import {
  checkProductsCompany,
  fetchCatalogs,
  fetchProducts,
  removeProduct,
  setProductIdRemove,
} from '../SalesSlice';
import ProductsPreview from '../../../assets/products-preview.png';
import loader from '../../../assets/grid.svg';
import { useHasPermission, WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { getPageLimit } from "../../../components/Paginator/utils";

const productListPageLimitIndex = 'productListPagesLimit'

const Products = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId/');
  const products = useSelector(ProductsSelector);
  const hasProducts = useAppSelector((state: RootState) => state.sales.checkProducts);
  const loadList = useSelector(ProductLoadSelector);
  const [onAddProduct, setOnAddProduct] = useState(false);
  const [onOpenProduct, setOnOpenProduct] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [productSelected, setProductSelected] = useState<Product>();
  const [search, setSearch] = useState('');
  const [query] = useDebounce(search, 400);
  const permissionCreate = useHasPermission('salesProductCreate');

  useEffect(() => {
    setSearch('');
    if (url?.params.companyId) {
      dispatch(checkProductsCompany({ companyId: Number(url?.params.companyId) }));
      dispatch(fetchProducts({ companyId: Number(url?.params.companyId) }));
      dispatch(fetchCatalogs({ companyId: Number(url?.params.companyId) }));
      onUpdate(1, getPageLimit(productListPageLimitIndex));
    }
  }, [url?.params.companyId]);

  useEffect(() => {
    if (hasProducts) {
      onUpdate(1, getPageLimit(productListPageLimitIndex));
    }
  }, [hasProducts]);

  useEffect(() => {
    onUpdate(1, getPageLimit(productListPageLimitIndex));
  }, [query]);

  const onUpdate = (page: number, limit: number) => {
    dispatch(fetchProducts({
      companyId: Number(url?.params.companyId), limit, offset: (limit * (page - 1)),
      ...(query ? { query } : {})
    }));
  };

  const onViewDetail = (product: Product) => {
    setProductSelected(product);
    setOnOpenProduct(true);
  };

  const onDelete = (product: Product) => {
    setProductSelected(product);
    setDeleteOpen(true);
  };

  const deleteProduct = () => {
    if (productSelected) {
      dispatch(setProductIdRemove(productSelected.id));
      dispatch(removeProduct({
        companyId: Number(url?.params.companyId),
        productId: productSelected.id
      }))
      setDeleteOpen(false);
      setProductSelected(undefined)
    }
  };

  const onClose = () => {
    setDeleteOpen(false);
    setProductSelected(undefined);
  }

  const onEdit = (product: Product) => {
    setProductSelected(product)
    setOnAddProduct(true);
  };

  if (!products) {
    return (
      <div className='products'>
        <div className='loaderContainer'>
          <img src={loader} alt='' />
        </div>
      </div>
    );
  }

  if (!hasProducts) {
    return (
        <div className='noneProducts'>
          {onAddProduct && <SidePanel setIsOpen={setOnAddProduct} background='light'>
            <CreateAndEditProduct setIsOpen={setOnAddProduct} />
          </SidePanel>}
          <img alt='preview' src={ProductsPreview} />
          {permissionCreate ? (
            <>
              <div className='noneProducts_text'>
                <h1>{t('sales.products.title')}</h1>
                <p>{t('sales.products.none_product1')}</p>
                <p>{t('sales.products.none_product2')}</p>
              </div>
              <Button text={t('sales.products.add_product')} color='orange' textType='regular'
                      onClick={() => setOnAddProduct(true)} />
            </>) : (
            <p>{t('sales.products.noProductCreate')}</p>
          )}
        </div>
    );
  }

  return (
    <div className='products'>
      {onAddProduct && <SidePanel setIsOpen={setOnAddProduct} background='light'>
        {productSelected 
          ? <CreateAndEditProduct product={productSelected} setProductSelected={setProductSelected}
              setIsOpen={setOnAddProduct} />
          : <CreateAndEditProduct setIsOpen={setOnAddProduct} />}
      </SidePanel>}
      {onOpenProduct && <SidePanel setIsOpen={setOnOpenProduct} background='light'>
        <PreviewProducts product={productSelected} setProductSelected={setProductSelected} />
      </SidePanel>}
      {deleteOpen &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          onAction={deleteProduct} setIsOpen={setDeleteOpen}
          title={t('sales.products.delete.title')} onClose={onClose}
          message={t('sales.products.delete.text', { product: productSelected?.name })} />}
      <div className='products__header'>
        <h1>{t('sales.products.title')}</h1>
        <div className='products__header_right'>
          <Search onChangeSearchInput={setSearch} value={search} />
           <WrapperPermission  permission='salesProductCreate'>
             <Button type='button' color='orange' textType='regular' text={t('sales.products.add_product')}
               onClick={() => setOnAddProduct(true)} />
           </WrapperPermission>
        </div>
      </div>
      {loadList ? <div className='products_list'>
          <div className="load"><img src={loader} alt='loading...' /></div>
        </div> :
        <>
          {products.items.length === 0 ? <div>{t('sales.products.none_product1')}</div> :
            <>
              <productItemContext.Provider value={{ onEdit, onDelete, onPreview: onViewDetail }}>
                <ProductsList products={products.items} />
              </productItemContext.Provider>
            </>
          }
        </>
      }
      <div className='products__bottom'>
        <Paginator totalCount={products.totalCount} pageLimitStoreIndex={productListPageLimitIndex}
          pageSelect={onUpdate} />
      </div>
    </div>
  );
};

export default Products;
