import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../Chat/companiesSelector';
import { useAppDispatch } from '../../store/store';
import { usePhoneSchema } from '../../components/PhoneInput/utils';
import {
  WabaTemplateCategory, WabaTemplateComponentHeaderFormat,
  WabaTemplateComponentButtonsButtonUrl, WabaTemplateComponentButtonsButtonPhoneNumber,
  WabaTemplateComponentButtonsButtonQuickReply, CreateWabaTemplateRequest,
  WabaTemplateInfo, WabaTemplateComponentButtonsButtonOTP, WabaTemplateComponentButtonsInput,
  WabaTemplateComponentHeader, WabaTemplateComponentBody, WabaTemplateComponentFooter,
  WabaTemplateComponentAuthBody, WabaTemplateComponentAuthFooter
} from "./templatesTypes";
import { countVariables, WabaTemplateActions } from './utils/waba';
import { useContext } from 'react';
import { wabaTemplateContext } from './WabaTemplates/WabaTemplateProvider/WabaTemplateProvider';
import { createWabaTemplate, deleteWabaTemplate, updateWabaTemplate } from './templatesSlice';
import { addAlertWithCustomText } from '../../components/Alert/alertSlice';


type WabaTemplateOtpButtonCopy = {
  type: 'COPY_CODE';
  text: string;
};

type WabaTemplateOtpButtonTap = {
  type: 'ONE_TAP';
  text: string;
  autofill_text?: string | null;
  package_name?: string | null;
  signature_hash?: string | null;
};

export type WabaTemplateSchema = {
  custom_name: string;
  template_locale: string;
  category: WabaTemplateCategory;
  allow_category_change?: boolean;
  add_security_recommendation?: boolean;
  code_expiration_minutes?: number;
  header?: {
    format: WabaTemplateComponentHeaderFormat;
    text?: string | null;
  };
  body?: string;
  footer?: string;
  buttons?: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumber
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateOtpButtonCopy
    | WabaTemplateOtpButtonTap
  >;
};

function isValidUrl(link: string) {
  try {
    const linkUrl = new URL(link);
    return linkUrl.protocol
      && linkUrl.host.split('.').length >= 2 && linkUrl.host.split('.').every((item) => item.length > 0);
  } catch (err) {
    return false;
  }
}

export function checkComponentText(
  item: WabaTemplateComponentHeader
  | WabaTemplateComponentBody
  | WabaTemplateComponentFooter
  | WabaTemplateComponentAuthBody
  | WabaTemplateComponentAuthFooter): item is WabaTemplateComponentHeader
  | WabaTemplateComponentBody
  | WabaTemplateComponentFooter {
  if (item && ('text') in item) {
    return true;
  }
  return false;
}

export function useWabaTemplateSchema() {
  const { t } = useTranslation();
  const phoneSchema = usePhoneSchema({ required: true });

  return yup.object().shape({
    custom_name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    category: yup.string().required(),
    template_locale: yup.string().required(t('error.form.empty_field')),
    allow_category_change: yup.boolean(),
    code_expiration_minutes: yup.number().when('category', {
      is: 'AUTHENTICATION',
      then: yup.number().nullable(true).transform((_, val) => (val !== "" ? Number(val) : null))
        .min(1, t('error.form.min_value', { limit: 1 })).max(90, t('error.form.max_value', { limit: 90 }))
    }),
    header: yup.object().when('category', {
      is: 'AUTHENTICATION',
      then: yup.object().shape({
        format: yup.string().nullable(true),
        text: yup.string().nullable(true)
      }),
      otherwise: yup.object().shape({
        format: yup.string().required(),
        text: yup.string().when('format', {
          is: 'TEXT',
          then: yup.string().max(60, t('error.form.max_length', { limit: 60 })),
          otherwise: yup.string().nullable()
        }).test({
          name: 'header_text',
          test: (val, { createError }) => {
            if (val) {
              return countVariables(val) > 1
                ? createError({ message: t('templates.waba_list.form.variable_limit') }) : true;
            }
            return true;
          }
        })
      })
    }),
    body: yup.string().required(t('error.form.empty_field')).max(1024, t('error.form.max_length', { limit: 1024 }))
      .test({
        name: 'body_text',
        test: (val, { createError }) => {
          if (val) {
            return countVariables(val) > 99
              ? createError({ message: t('templates.waba_list.form.variable_limit') }) : true;
          }
          return true;
        }
      }),
    footer: yup.string().max(60, t('error.form.max_length', { limit: 60 })),
    buttons: yup.array().of(yup.object().shape({
      type: yup.string().required(),
      text: yup.string().required(t('error.form.empty_field')).when('type', {
        is: 'COPY_CODE' || 'ONE_TAP',
        then: yup.string().max(10, t('error.form.max_length', { limit: 10 })),
        otherwise: yup.string().max(20, t('error.form.max_length', { limit: 20 }))
      }),
      url: yup.string().when('type', {
        is: 'URL',
        then: yup.string().required(t('error.form.empty_field')),
        otherwise: yup.string().nullable() }).test({ name: 'url',
          test: (val, { createError }) => {
            let message = '';
            if (val) {
              if (countVariables(val) > 1) {
                message = t('templates.waba_list.form.variable_limit');
              } else {
                let link = val;
                if (countVariables(val) === 1) {
                  link = val.replace(/(\{\{\d+\}\})?$/g, '');
                }
                if ((countVariables(link) > 0) || !isValidUrl(link)) {
                  message = t('error.form.url');
                }
              }
            }
            return message ? createError({ message }) : true;
          }
      }),
      phone_number: yup.string().when('type', {
        is: 'PHONE_NUMBER',
        then: phoneSchema.phone,
        otherwise: yup.string().nullable(),
      })
    })).when('category', {
      is: 'AUTHENTICATION',
      then: yup.array().min(1, t('error.form.empty_field')),
      otherwise: yup.array().nullable()
    }).test({
      name: 'buttons_number',
      test: (val, { createError }) => {
        if (val) {
          const urlButtons = val.reduce((acc, curr) => acc + (curr.type === 'URL' ? 1 : 0), 0);
          const phoneButtons = val.reduce((acc, curr) => acc + (curr.type === 'PHONE_NUMBER' ? 1 : 0), 0);
          return (urlButtons > 2 || phoneButtons > 1)
            ? createError({ path: 'buttons', message: t('templates.waba_list.form.buttons.buttons_limit') }) : true;
        }
        return true;
      } })
  });
}

export function useWabaTemplateRequest() {
  const dispatch = useAppDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const { connectionId } = useContext(wabaTemplateContext);

  const makeWabaTemplateRequest = (data: WabaTemplateSchema) => {
    const request: CreateWabaTemplateRequest = {
      company_id: companyId,
      connection_id: connectionId,
      custom_name: data.custom_name,
      category: data.category,
      allow_category_change: data.allow_category_change,
      components: [],
      template_locale: data.template_locale,
      examples: {}
    };

    if (data.category === 'AUTHENTICATION') {
      if (data.body) request.components.push(
        { type: 'BODY', add_security_recommendation: data.add_security_recommendation });

      if (data.footer && data.code_expiration_minutes) request.components.push(
        { type: 'FOOTER', code_expiration_minutes: data.code_expiration_minutes });

      if (data.buttons && data.buttons?.length > 0) {
        const buttons: WabaTemplateComponentButtonsInput['buttons'] = [];
        data.buttons.forEach(button => {
          if (button.type === 'COPY_CODE') buttons.push({ type: 'OTP', otp_type: button.type, text: button.text });
          if (button.type === 'ONE_TAP') buttons.push(
            {
              type: 'OTP', otp_type: button.type, text: button.text,
              autofill_text: button.autofill_text, package_name: button.package_name,
              signature_hash: button.signature_hash
            });
        });
        request.components.push({ type: 'BUTTONS', buttons });
      }
    }

    if (data.category === 'MARKETING' || data.category === 'UTILITY') {
      if ((data.header) && ((data.header.text && data.header.format === 'TEXT') || data.header.format !== 'TEXT')) {
        request.components.push({ type: 'HEADER', text: data.header.text, format: data.header.format });
      }
      if (data.body) request.components.push({ type: 'BODY', text: data.body });
      if (data.footer) request.components.push({ type: 'FOOTER', text: data.footer });
      if (data.buttons && data.buttons?.length > 0) {
        const buttons: WabaTemplateComponentButtonsInput['buttons'] = [];
        data.buttons.forEach(button => {
          if (button.type === 'QUICK_REPLY') buttons.push({ type: 'QUICK_REPLY', text: button.text });
          if (button.type === 'PHONE_NUMBER') buttons.push(
            { type: 'PHONE_NUMBER', text: button.text, phone_number: button.phone_number });
          if (button.type === 'URL') buttons.push({ type: 'URL', text: button.text, url: button.url });
        });
        request.components.push({ type: 'BUTTONS', buttons });
      }
    }

    return request;
  };

  const makeWabaTemplateData = (template: CreateWabaTemplateRequest) => {
    const data: WabaTemplateSchema = {
      category: template.category,
      custom_name: template.custom_name ?? '',
      template_locale: template.template_locale ?? '',
      allow_category_change: template.allow_category_change
    };

    if (template.category === 'AUTHENTICATION') {
      template.components.forEach(component => {
        if (component.type === 'BODY' && ('add_security_recommendation' in component)) {
          data.add_security_recommendation = component.add_security_recommendation ?? undefined;
        }
        if (component.type === 'FOOTER'&& ('code_expiration_minutes' in component)) {
          data.code_expiration_minutes = component.code_expiration_minutes ?? undefined;
        }
        if (component.type === 'BUTTONS') {
          const buttons: Array<WabaTemplateOtpButtonCopy| WabaTemplateOtpButtonTap> = [];
          component.buttons.forEach(button => {
            if (button.type === 'OTP' && button.otp_type === 'COPY_CODE') {
              buttons.push({ type: button.otp_type, text: button.text });
            }
            if (button.type === 'OTP' && button.otp_type === 'ONE_TAP') {
              buttons.push({
                type: button.otp_type, text: button.text, autofill_text: button.autofill_text,
              });
            }
          });
          data.buttons = buttons;
        }
      });
    }

    if (template.category === 'MARKETING' || template.category === 'UTILITY') {
      data.header = { format: 'TEXT', text: '' };
      template.components.forEach(component => {
        if (component.type === 'HEADER' && ((component.format === 'TEXT' && component.text) || component.format !== 'TEXT')) {
          data.header = { text: component.text ?? '', format: component.format };
        }
        if (component.type === 'BODY' && checkComponentText(component)) {
          data.body = component.text;
        }
        if (component.type === 'FOOTER' && checkComponentText(component)) {
          data.footer = component?.text;
        }
        if (component.type === 'BUTTONS') {
          const buttons: Array<
          | WabaTemplateComponentButtonsButtonUrl
          | WabaTemplateComponentButtonsButtonPhoneNumber
          | WabaTemplateComponentButtonsButtonQuickReply> = [];
          component.buttons.forEach(button => {
            if (button.type === 'QUICK_REPLY') buttons.push({ type: button.type, text: button.text });
            if (button.type === 'PHONE_NUMBER') buttons.push(
              { type: button.type, text: button.text, phone_number: button.phone_number });
            if (button.type === 'URL') buttons.push({ type: button.type, text: button.text, url: button.url });
          });
          data.buttons = buttons;
        }
      });
    }

    return data;
  };

  const  wabaTemplateConvertAction = (template: WabaTemplateInfo, action: WabaTemplateActions) => {
    const data: CreateWabaTemplateRequest = {
      company_id: companyId,
      connection_id: connectionId,
      custom_name: template.customName ?? template.name,
      category: template.category,
      allow_category_change: true,
      components: [],
      template_locale: action === 'copy' ? template.locale : '',
      template_name: template.name,
      examples: {}
    };

    if (template.category === 'AUTHENTICATION') {
      template.components.forEach(component => {
        if (component.type === 'BODY') {
          data.components.push({
            type: 'BODY', text: component.text, add_security_recommendation: component.addSecurityRecommendation });
        }
        if (component.type === 'FOOTER') {
          data.components.push({
             type: 'FOOTER', text: component.text, code_expiration_minutes: component.codeExpirationMinutes });
        }
        if (component.type === 'BUTTONS') {
          const buttons: WabaTemplateComponentButtonsButtonOTP[] = [];
          component.buttons.forEach(button => {
            if (button.type === 'OTP') {
              buttons.push({
                type : button.type, otp_type: button.otpType, text: button.text, autofill_text: button.autofillText,
                package_name: button.packageName, signature_hash: button.signatureHash
              });
            }
          });
          data.components.push({ type: 'BUTTONS', buttons });
        }
      });
    }

    if (template.category === 'MARKETING' || template.category === 'UTILITY') {
      template.components.forEach(component => {
        if (component.type === 'HEADER') {
          data.components.push({ type: 'HEADER', text: component.text, format: component.format });
        }
        if (component.type === 'BODY') {
          data.components.push({ type: 'BODY', text: component.text });
        }
        if (component.type === 'FOOTER') {
          data.components.push({ type: 'FOOTER', text: component.text });
        }
        if (component.type === 'BUTTONS') {
          const buttons: Array<
          | WabaTemplateComponentButtonsButtonUrl
          | WabaTemplateComponentButtonsButtonPhoneNumber
          | WabaTemplateComponentButtonsButtonQuickReply> = [];
          component.buttons.forEach(button => {
            if (button.type === 'QUICK_REPLY') buttons.push({ type: button.type, text: button.text });
            if (button.type === 'PHONE_NUMBER') buttons.push(
              { type: button.type, text: button.text, phone_number: button.phoneNumber });
            if (button.type === 'URL') buttons.push({ type: button.type, text: button.text, url: button.url });
          });
          data.components.push({ type: 'BUTTONS', buttons });
        }
      });
    }

    return data;
  };

  const makeWabaTemplate = async (template: CreateWabaTemplateRequest) => {
    let result = false;

    await dispatch(createWabaTemplate(template))
      .then((response) => {
        if (response.payload) result = response.meta.requestStatus === 'fulfilled';
      });

    return result;
  };

  const removeWabaTemplate = (templateName: string, connectionId: number) => {
    dispatch(addAlertWithCustomText({ type: 'info', message: 'templates.waba_list.remove_template' }));
    dispatch(deleteWabaTemplate({
      company_id: companyId,
      connection_id: connectionId,
      template_name: templateName
    }));
  };

  const renameWabaTemplate = (templateName: string, locale: string, newName: string) => {
    dispatch(updateWabaTemplate({
      company_id: companyId,
      connection_id: connectionId,
      template_name: templateName,
      template_locale: locale,
      custom_name: newName
    }));
  };

  return {
    makeWabaTemplateRequest, makeWabaTemplateData, makeWabaTemplate,
    wabaTemplateConvertAction, removeWabaTemplate, renameWabaTemplate };
}
