import React, {useContext, useEffect} from "react";
import './ClientSubscriptionList.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PartnerSubscriptions } from '../../../PartnerCabinetSelector';
import { getCompanySubscriptionStatus } from '../../ulils/utils';
import { clientViewContext } from "../../ulils/Context";
import { subscriptionServiceMap } from "../../../../Settings/Subscriptions/SubscriptionsTypes";
import { SelectedSubscriptionsType } from "../ClientView/ClientView";
import ClientSubscriptionCard from '../ClientSubscriptionCard';



const ClientSubscriptionList = () => {
  const { t } = useTranslation();
  const { setSelectedSubscriptions } = useContext(clientViewContext);
  const partnerSubscriptions = useSelector(PartnerSubscriptions);

  useEffect(() => {
    const subscriptionsWithType: SelectedSubscriptionsType[] = [];
    const filterArr = partnerSubscriptions.filter((item) => getCompanySubscriptionStatus(item) !== 'paused');
    filterArr.forEach((item) => subscriptionsWithType.push({
      id: item.id,
      type: subscriptionServiceMap[item.subscriptionType]
    }));
    setSelectedSubscriptions(subscriptionsWithType);
  }, [partnerSubscriptions]);

  if (partnerSubscriptions.length === 0) return <></>;

  return (
    <div className="clientSubscriptionList">
      <h3>{t('partner_cabinet.clients.subscriptions.title')}</h3>
      {partnerSubscriptions.map((subscription) => (
        <ClientSubscriptionCard key={subscription.id} subscription={subscription} />
      ))}
    </div>
  );
};

export default ClientSubscriptionList;
