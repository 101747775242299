import React, { useContext } from 'react';
import './WabaParams.scss';
import {
  WabaTemplateComponentBody, WabaTemplateComponentHeader,
  WabaTemplateComponentButtonsIn, WabaTemplateComponentButtonsOutput,
  WabaTemplateComponentHeaderCreateRequest
} from '../../../templatesTypes';
import { useTranslation } from 'react-i18next';
import { wabaModeration } from '../Context';


type Props = {
  header?: WabaTemplateComponentHeader | WabaTemplateComponentHeaderCreateRequest;
  body?: WabaTemplateComponentBody;
  buttons?: WabaTemplateComponentButtonsOutput | WabaTemplateComponentButtonsIn;
};

type ParamsType = {
  id: number;
  value: string;
};

const WabaParams = ({ header }: Props) => {
  const { t } = useTranslation();
  const {
    arrayHeaderParams, setArrayHeaderParams,
    arrayBodyParams, setArrayBodyParams,
    firstURLParams, setFirstURLParams,
    secondURLParams, setSecondURLParams, isError
  } = useContext(wabaModeration);

  const onChangeInputs = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    arrayParams: ParamsType[],
    setArrayParams: React.Dispatch<React.SetStateAction<ParamsType[]>>
  ) => {
    const find = arrayParams.find((param) => param.id === id);

    if (find) {
      const editInput = arrayParams.map((input) => {
        if (input.id === find.id) {
          return { ...input, value: e.target.value };
        } else return input;
      });
      setArrayParams(editInput);
    } else {
      setArrayParams([...arrayParams, { id, value: e.target.value }]);
    }
  };

  const getError = (array: ParamsType[], id: number) => {
    const findParam = array.find((param) => param.id === id);
    if (findParam && findParam.value.length === 0) {
      return 'error';
    } else return '';
  };

  return (
    <div className="wabaParams">
      {header && header.format === 'TEXT' && arrayHeaderParams.map((input) => (
        <div key={input.id} className='inputContainer'>
          <p className="regularText middle">{`${t('templates.waba_moderation.header_param')} {{${input.id}}}`}</p>
          <input className={`inputContainer__input ${isError ? getError(arrayHeaderParams, input.id) : ''}`}
            onChange={(e) => onChangeInputs(e, input.id, arrayHeaderParams, setArrayHeaderParams)}
          />
          {isError && getError(arrayHeaderParams, input.id) ?
            <p className='smallText'>{t('error.form.empty_field')}</p> : ''}
        </div>
      ))}
      {arrayBodyParams.map((input) => (
        <div key={input.id} className='inputContainer'>
          <p className="regularText middle">{`${t('templates.waba_moderation.body_param')} {{${input.id}}}`}</p>
          <input className={`inputContainer__input ${isError ? getError(arrayBodyParams, input.id) : ''}`}
            onChange={(e) => onChangeInputs(e, input.id, arrayBodyParams, setArrayBodyParams)}
          />
          {isError && getError(arrayBodyParams, input.id) ?
            <p className='smallText'>{t('error.form.empty_field')}</p> : ''}
        </div>
      ))}
      {firstURLParams.map((input, index) => (
        <div key={index} className='inputContainer'>
          <p className="regularText middle">{`${t('templates.waba_moderation.url_param')} {{${input.id}}}`}</p>
          <input className={`inputContainer__input ${isError ? getError(firstURLParams, input.id) : ''}`}
                 onChange={(e) => onChangeInputs(e, input.id, firstURLParams, setFirstURLParams)}
          />
          {isError && getError(firstURLParams, input.id) ?
            <p className='smallText'>{t('error.form.empty_field')}</p> : ''}
        </div>
      ))}
      {secondURLParams.map((input, index) => (
        <div key={index} className='inputContainer'>
          <p className="regularText middle">{`${t('templates.waba_moderation.url_param')} {{${input.id}}}`}</p>
          <input className={`inputContainer__input ${isError ? getError(secondURLParams, input.id) : ''}`}
                 onChange={(e) => onChangeInputs(e, input.id, secondURLParams, setSecondURLParams)}
          />
          {isError && getError(secondURLParams, input.id) ?
            <p className='smallText'>{t('error.form.empty_field')}</p> : ''}
        </div>
      ))}
    </div>
  );
};

export default WabaParams;
