import { useEffect, useState } from 'react';
import './ClientView.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from "../../../../../store/store";
import {
  clearPartnerCompany, fetchPartnerCompany,
  uploadPartnerCompanyPayments, fetchPartnerCompanySubscriptions, updatePartnerCompanyPayment
} from '../../../PartnerCabinetSlice';
import { PartnerCompanySelector } from '../../../PartnerCabinetSelector';
import { ClientPayerType, getArrayPayer, getTitlePaymentsLocked } from '../../ulils/utils';
import { clientViewContext, ClientViewContext } from '../../ulils/Context';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import { useHasPermission, WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";
import GoBackWrapper from '../../../../../components/GoBackWrapper';
import Button from '../../../../../components/Button';
import ItemPercent from '../ClientItemComponents/ClientPercent';
import Select from '../../../../../components/Select';
import ClientSubscriptionList from '../ClientSubscriptionList';
import ClientInvoicesView from '../ClientInvoicesView';
import Loader from '../../../../../components/Loader/Loader';
import ClientNotifications from "../ClientNotifications";
import SidePanel from "../../../../../components/Modals/SidePanel";
import ClientInvoicesCreate from "../ClientInvoicesCreate";
import ClientBalanceCreate from '../ClientBalanceCreate';


export type SelectedSubscriptionsType = {
  id: number;
  type: string;
};

const ClientView = () => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; partnerCompanyId: string; }>(
    '/companies/:companyId/partners/clients/:partnerCompanyId'
  );
  const dispatch = useAppDispatch();
  const partnerCompany = useSelector(PartnerCompanySelector);
  const [onPay, setOnPay] = useState<boolean>(false);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<SelectedSubscriptionsType[]>([]);
  const [onPayBalance, setOnPayBalance] = useState<boolean>(false);
  const peyerArr = getArrayPayer();
  const permissionEdit = useHasPermission('partnersEdit');

  useEffect(() => () => {
    dispatch(clearPartnerCompany());
  }, []);

  useEffect(() => {
    const companyId = Number(url?.params.companyId);
    const partnerCompanyId = Number(url?.params.partnerCompanyId);

    dispatch(fetchPartnerCompany({ company_id: companyId, partner_company_id: partnerCompanyId }));
    dispatch(fetchPartnerCompanySubscriptions({ company_id: companyId, partner_company_id: partnerCompanyId }));
    dispatch(uploadPartnerCompanyPayments({ company_id: companyId, partner_company_id: partnerCompanyId, limit: 3 }));
  }, [url?.params.partnerCompanyId]);

  const onCreateInvoices = () => setOnPay(true);

  const onSelect = (item: ClientPayerType) => {
    dispatch(updatePartnerCompanyPayment({
      company_id: Number(url?.params.companyId),
      partner_company_id: Number(url?.params.partnerCompanyId),
      is_locked: item.value,
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(addAlertWithCustomText({ message: t('errors.100000'), type: 'info' }));
      }
      if (res.meta.requestStatus === 'rejected') {
        dispatch(addAlertWithCustomText({ message: t('errors.99999'), type: 'alarm' }));
      }
    });
  };

  const contextValue: ClientViewContext = {
    selectedSubscriptions,
    setSelectedSubscriptions,
    setOnPayBalance
  };

  if (!partnerCompany) {
    return (
      <div className="clientView">
        <Loader />
      </div>
    );
  }

  return (
    <div className="clientView">
      <GoBackWrapper title={partnerCompany.name}>
        <clientViewContext.Provider value={contextValue}>
          {onPay && (
            <SidePanel setIsOpen={setOnPay} background="light">
              <ClientInvoicesCreate setOnPay={setOnPay} partnerCompany={partnerCompany} />
            </SidePanel>
          )}
          {onPayBalance && (
            <SidePanel setIsOpen={setOnPayBalance} background="light">
              <ClientBalanceCreate setOnPay={setOnPayBalance} partnerCompany={partnerCompany} />
            </SidePanel>
          )}
          <div className="clientView__header">
            <WrapperPermission permission="partnersEdit">
              <Button color="orange" textType="regular" text={t('partner_cabinet.clients.create.title')}
                image={<PlusIcon />} onClick={onCreateInvoices} disabled={selectedSubscriptions.length === 0} />
            </WrapperPermission>
          </div>
          <p className="regularText">ID {partnerCompany.id}</p>
          <div className="clientView__info">
            <div className="clientView__info_left">
              <div className="partnerPercent">
                <h4>{t('partner_cabinet.clients.percent')}</h4>
                <ItemPercent percent={partnerCompany.partnerPercent} />
              </div>
              <div className="partnerPayer">
                <h4>{t('partner_cabinet.clients.pay_subscriptions')}</h4>
                {permissionEdit ? (
                  <Select items={peyerArr} labelKey="name" onChoose={onSelect} value={getTitlePaymentsLocked(t, partnerCompany.paymentsLocked)}
                    initValue={getTitlePaymentsLocked(t, partnerCompany.paymentsLocked)} />
                ) : (
                  <p className='regularText'>{getTitlePaymentsLocked(t, partnerCompany.paymentsLocked)}</p>
                )}
              </div>
            </div>
            <ClientNotifications />
          </div>
          <ClientSubscriptionList />
          <ClientInvoicesView partnerId={partnerCompany.id} />
        </clientViewContext.Provider>
      </GoBackWrapper>
    </div>
  );
};

export default ClientView;
