

export type CustomTemplate = {
  id: number;
  type: string;
  name: string;
  text: string;
  companyId: number;
  fileUrl: null | string;
  params: CreateInteractiveTemplateParams;
};

type WabaTemplateButton = {
  type: string;
  text: string;
  phoneNumber: string;
  url: string;
};

export type WabaTemplate = {
  name: string;
  locale: string;
  components: {
    body: {
      text: string;
      paramsCount: number;
    };
    buttons?: {
      text: string;
      paramsCount: number;
      buttons: WabaTemplateButton[];
    };
    header?: {
      text: string;
      params_count: number;
      format: string;
    };
    footer?: {
      text: string;
      paramsCount: number;
    };
  };
  status: string;
  rejectedReason: string;
};

export type PatchCustomTemplateRequestOptions = {
  companyId: number;
  name?: string;
  text?: string;
  fileUrl?: string | null;
  templateId: number;
};

export type CreateCustomTemplateRequestOptions = {
  type: string;
  name: string;
  text: string;
  companyId: number;
  fileUrl?: string;
};

type ButtonAction = {
  type: string;
  reply: {
    title: string;
  };
};

type ListActionPayload = {
  title: string;
  description: string;
};

type ListActions = {
  button: string;
  sections: Array<{
    rows: ListActionPayload[];
  }>;
};

export type CreateInteractiveTemplateData = {
  type: string;
  name: string;
  waba_interactive: {
    type: 'list' | 'button';
    header: {
      type: 'text' | 'image' | 'video' | 'document';
      text?: string;
      url?: string;
      filename?: string;
    };
    body: string;
    footer?: string;
    action: {
      buttons: ButtonAction[];
      sections: ListActions;
    };
  };
};

export type CreateInteractiveTemplateRequestData = {
  type: string;
  company_id: number;
  name: string;
  waba_interactive: {
    type: 'list' | 'button';
    header?: {
      type: 'text' | 'image' | 'video' | 'document';
      text?: string;
      url?: string;
      filename?: string;
    };
    footer?: string;
    action: {
      buttons?: ButtonAction[];
      sections?: ListActions;
    };
  };
};

type CreateInteractiveTemplateParamsButtons = {
  type: string;
  reply: {
    id: string;
    title: string;
  };
};

type CreateInteractiveTemplateParamsList = {
  button: string;
  sections: Array<{ rows: Array<{ id: string; title: string; description: string; }>; }>;
};

type CreateInteractiveTemplateParams = {
  action: {
    buttons: CreateInteractiveTemplateParamsButtons[] | null;
    sections: CreateInteractiveTemplateParamsList | null;
  };
  sections: null;
  body: string;
  footer: string;
  header: {
    filename: string | null;
    text: string | null;
    type: 'text' | 'image' | 'video' | 'document';
    url: string;
  };
  type: 'button' | 'list';
};

export type RequestInteractiveTemplate = {
  companyId: number;
  type: string;
  header?: {
    type: string;
    text?: string;
    url?: string;
    filename?: string;
  };
  body: string;
  footer?: string;
  action: {
    buttons?: CreateInteractiveTemplateParamsButtons[];
    sections?: CreateInteractiveTemplateParamsList;
  };
};

/** CreateWabaTemplateRequest */
export const wabaTemplateCategories = ['AUTHENTICATION', 'MARKETING', 'UTILITY'];
export type WabaTemplateCategory = 'MARKETING' | 'AUTHENTICATION' | 'UTILITY';

export type CreateWabaTemplateRequest = {
  company_id: number;
  connection_id: number;
  custom_name: string;
  template_name?: string | null;
  template_locale: string;
  category: WabaTemplateCategory;
  allow_category_change?: boolean;
  components: Array<
    | WabaTemplateComponentHeaderCreateRequest
    | WabaTemplateComponentBody
    | WabaTemplateComponentAuthBody
    | WabaTemplateComponentFooter
    | WabaTemplateComponentAuthFooter
    | WabaTemplateComponentButtonsInput>;
  examples: CreateWabaTemplateRequestExamples;
};

export type CreateWabaTemplateRequestExamples = {
  header_text?: string[];
  body_text?: string[];
  button_url_text?: string[] | null;
  header_url?: string | null;
};

/** WabaTemplateComponentHeaderFormat */
export const wabaTemplateComponentHeaderFormats = ['TEXT', 'IMAGE', 'DOCUMENT', 'VIDEO'];
export type WabaTemplateComponentHeaderFormat = 'TEXT' | 'IMAGE' | 'DOCUMENT' | 'VIDEO';

export type WabaTemplateListButtons =
  | WabaTemplateComponentButtonsButtonUrl
  | WabaTemplateComponentButtonsButtonPhoneNumber
  | WabaTemplateComponentButtonsButtonQuickReply
  | WabaTemplateComponentButtonsButtonOTP
  | WabaTemplateComponentButtonsButtonOTPIn
  | WabaTemplateComponentButtonsButtonPhoneNumberIn;

export type WabaTemplateComponentHeaderCreateRequest = {
  type: 'HEADER';
  format: WabaTemplateComponentHeaderFormat;
  text?: string | null;
};

export type WabaTemplateComponentBody = {
  type: 'BODY';
  text: string;
};

export type WabaTemplateComponentAuthBody = {
  type: 'BODY';
  add_security_recommendation?: boolean | null;
};

export type WabaTemplateComponentFooter = {
  type: 'FOOTER';
  text: string;
};

export type WabaTemplateComponentAuthFooter = {
  type: 'FOOTER';
  code_expiration_minutes?: number | null;
};

export type WabaTemplateComponentButtonsInput = {
  type: 'BUTTONS';
  buttons: Array<
  | WabaTemplateComponentButtonsButtonUrl
  | WabaTemplateComponentButtonsButtonPhoneNumber
  | WabaTemplateComponentButtonsButtonQuickReply
  | WabaTemplateComponentButtonsButtonOTP>;
};

export type WabaTemplateComponentButtonsButtonUrl = {
  type: 'URL';
  text: string;
  url: string;
};

export type WabaTemplateComponentButtonsButtonPhoneNumber = {
  type: 'PHONE_NUMBER';
  text: string;
  phone_number: string;
};

export type WabaTemplateComponentButtonsButtonQuickReply = {
  type: 'QUICK_REPLY';
  text: string;
};

export type WabaTemplateOtpButtonOtpType = 'COPY_CODE' | 'ONE_TAP';

export type WabaTemplateComponentButtonsButtonOTP = {
  type: 'OTP';
  otp_type: WabaTemplateOtpButtonOtpType;
  text: string;
  autofill_text?: string | null;
  package_name?: string | null;
  signature_hash?: string | null;
};

/** DeleteWabaTemplateRequest */
export type DeleteWabaTemplateRequest = {
  company_id: number;
  connection_id: number;
  template_name: string;
};

/** CreateWabaTemplateResponse */
export type WabaTemplateStatus = 'APPROVED' | 'SUBMITTED' | 'REJECTED' | 'UNKNOWN';

export type WabaTemplateComponentBodyIn = {
  type: 'BODY';
  text: string;
  addSecurityRecommendation?: boolean | null;
};

export type WabaTemplateComponentFooterIn = {
  type: 'FOOTER';
  text: string;
  codeExpirationMinutes?: number | null;
};

export type WabaTemplateComponentButtonsButtonPhoneNumberIn = {
  type: 'PHONE_NUMBER';
  text: string;
  phoneNumber: string;
};

export type WabaTemplateComponentButtonsButtonOTPIn = {
  type: 'OTP';
  otpType: WabaTemplateOtpButtonOtpType;
  text: string;
  autofillText?: string | null;
  packageName?: string | null;
  signatureHash?: string | null;
};

export type WabaTemplateComponentButtonsIn = {
  type: 'BUTTONS';
  buttons: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumberIn
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateComponentButtonsButtonOTPIn
  >;
};

export type WabaTemplateInfo = {
  name: string;
  locale: string;
  status: WabaTemplateStatus;
  category: WabaTemplateCategory;
  createdAt: string;
  updatedAt: string;
  rejectedReason: string;
  customName?: string | null;
  components: Array<
    | WabaTemplateComponentHeader
    | WabaTemplateComponentBodyIn
    | WabaTemplateComponentFooterIn
    | WabaTemplateComponentButtonsIn>;
};

export type CreateWabaTemplateResponse = {
  wabaTemplates: WabaTemplateInfo[];
};

export type WabaTemplateComponentHeader = {
  type: 'HEADER',
  format: WabaTemplateComponentHeaderFormat;
  text: string;
};

export type WabaTemplateComponentButtonsOutput = {
  type: 'BUTTONS';
  buttons: Array<
    | WabaTemplateComponentButtonsButtonUrl
    | WabaTemplateComponentButtonsButtonPhoneNumber
    | WabaTemplateComponentButtonsButtonQuickReply
    | WabaTemplateComponentButtonsButtonOTP
  >;
};

export type UpdateWabaTemplateRequest = {
  company_id: number;
  connection_id: number;
  template_name: string;
  template_locale: string;
  custom_name: string;
};
