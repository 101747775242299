import { ReactElement } from "react";
import { WabaTemplateComponentHeaderFormat, WabaTemplateListButtons } from "../../templatesTypes";
import { ReactComponent as PDFIcon } from "../../../../assets/pdf.svg";
import { ReactComponent as ImageIcon } from '../../../../assets/image.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/video.svg';
import { ReactComponent as FilesIcon } from '../../../../assets/files.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/phone-outgoing.svg';
import { ReactComponent as NewWindowIcon } from '../../../../assets/new-window.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';
import parse from "html-react-parser";


type WabaTemplateFileConfig = {
  title: string;
  description: string;
  accept: string;
  maxSize: number;
  icon: ReactElement;
};

type WabaTemplateButtonConfig = {
  text: (buttons: WabaTemplateListButtons) => string;
  icon?: ReactElement;
};

type WabaTemplateComponentButtonsType = 'URL' | 'PHONE_NUMBER' | 'QUICK_REPLY' | 'OTP';

export const configWabaTemplateFile: Record<WabaTemplateComponentHeaderFormat, WabaTemplateFileConfig> = {
  IMAGE: {
    title: 'templates.waba_moderation.fileload.load_image',
    description: 'templates.waba_moderation.fileload.accept_image',
    accept: '.png, .jpeg, .jpg',
    maxSize: 5242880,
    icon: <ImageIcon className="previewIcon" />,
  },
  VIDEO: {
    title: 'templates.waba_moderation.fileload.load_video',
    description: 'templates.waba_moderation.fileload.accept_video',
    accept: '.mp4, .3gp',
    maxSize: 16777216,
    icon: <VideoIcon className="previewIcon" />,
  },
  DOCUMENT: {
    title: 'templates.waba_moderation.fileload.load_document',
    description: 'templates.waba_moderation.fileload.accept_document',
    accept: '.pdf',
    maxSize: 3145728,
    icon: <FilesIcon className="previewIcon" />,
  },
  TEXT: { title: '', description: '', accept: '', maxSize: 0, icon: <></> },
};

export const useWabaTemplateFileType = (headerType: WabaTemplateComponentHeaderFormat, attachmentFile: File) => {
  let component: JSX.Element = <></>;

  switch (headerType) {
    case 'IMAGE':
      component = <img alt="preview" className="wabaFileLoad__preview" src={URL.createObjectURL(attachmentFile)} />;
      break;
    case 'VIDEO':
      component = <video controls className="wabaFileLoad__preview" src={URL.createObjectURL(attachmentFile)} />;
      break;
    case 'DOCUMENT':
      component = (
        <div className="wabaFileLoad__document">
          <PDFIcon width={60} height={60} />
          <span>{attachmentFile.name}</span>
        </div>
      );
      break;
  }

  return component;
};

const getText = (button: WabaTemplateListButtons) => {
  switch (button.type) {
    case 'QUICK_REPLY':
      return button.text;
    case 'PHONE_NUMBER':
      return button.text;
    case 'URL':
      return button.text;
    case 'OTP':
      return button.text;
    default:
      return '';
  }
};

export const configWabaTemplateButton: Record<WabaTemplateComponentButtonsType, WabaTemplateButtonConfig> = {
  QUICK_REPLY: { text: getText },
  PHONE_NUMBER: { text: getText, icon: <PhoneIcon /> },
  URL: { text: getText, icon: <NewWindowIcon /> },
  OTP: { text: getText, icon: <CopyIcon /> },
};


export const parseWabaBodyText = (text?: string | null | string[]) => {
  if (text && typeof text === 'string') {
    const htmlFormat = text
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // для жирного
      .replace(/_(.*?)_/g, "<em>$1</em>") // для курсива
      .replace(/~(.*?)~/g, "<span style='text-decoration: line-through'>$1</span>") // для зачеркнутого
      .replace(/```(.*?)```/g, "<span style='font-family: monospace'>$1</span>"); // для моношрифта

    return parse(htmlFormat);
  }

  return text;
}
