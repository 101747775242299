import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import { WabaTemplateInfo } from '../../../../templatesTypes';
import './WabaTemplateRename.scss';

type WabaTemplateRenameProps = {
  template: WabaTemplateInfo;
  onSave: (name: string) => void;
  onCancel: () => void;
};

type FormData = {
  custom_name: string | null;
};

function WabaTemplateRename({ template, onCancel, onSave }: WabaTemplateRenameProps) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    custom_name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 }))
  });

  const methods = useForm<FormData>({ mode: 'onSubmit', resolver: yupResolver(schema),
    defaultValues: {
      custom_name: template.customName
    }
  });

  const onSubmit = (data: FormData) => {
    if (data.custom_name) onSave(data.custom_name);
  };
  
  return (
    <div className='wabaTemplateRename'>
      <h2>{t('templates.waba_list.form.rename')}</h2>
      <FormProvider {...methods}>
        <Input label={t('templates.waba_list.form.name')} name='custom_name' />
        <div className='wabaTemplateRename__buttons'>
          <Button type='submit' color='orange' textType='regular' text={t('save')}
            disabled={methods.formState.isSubmitting || !methods.formState.isDirty}
            onClick={methods.handleSubmit(onSubmit)}
          />
          <Button type='button' color='white' textType='regular' text={t('cancel')} onClick={onCancel} />
        </div>
      </FormProvider>
    </div>
  );
}

export default WabaTemplateRename;
