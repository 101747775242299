
export type ConnectionState = {
  state: string;
};

export type ConnectionStatusType = {
  status: 'qr' | 'loading' | 'not_paid' | 'ok' | 'conflict' | 'not_created';
  qrCode?: string;
};

export type RuTaxation = 'osn' | 'usn_income' | 'usn_income_outcome' | 'envd' | 'esn' | 'patent';
export type ModulbankPaymentMethods = 'card' | 'spb';

export const connectionTypes = ['amocrm', 'bitrix',
  'whatsapp', 'telegram', 'telegram_bot', 'odnoklassniki', 'waba',
  'tinkoff', 'modulbank', 'sberbank', 'paykeeper', 'paypal', 'bepaid',
  'lifepay', 'chatapi_instagram', 'yclients', 'yookassa'] as const;

export type ConnectionsType = typeof connectionTypes[number];

export type CompanyConnectionType = {
  companyId: number;
  connectionId: number;
};

export type ConnectionsComposeType = `connections_${ConnectionsType}`;

export type ConnectionsComposeMapType = {
  [service in ConnectionsType]: `connections_${service}`;
};

export type ConnectionsListRequest = {
  companyId: number;
  limit?: number;
  offset?: number;
  types?: ConnectionsType | string[];
};

type OdnoklassnikiUpdateParams = {
  ok_api_key: string;
};

type WabaUpdateParams = {
  api_key?: string;
  waba_auto_templates_enabled?: boolean;
};

type WhatsAppUpdateParams = {
  group_chats_enabled: boolean;
};

type TelegramUpdateParams = {
  group_chats_enabled: boolean;
};

type PaypalUpdateParams = {
  secret?: string;
  is_testing?: boolean;
};

type TinkoffUpdateParams = {
  terminal_password: string;
  taxation: RuTaxation;
};

export type ModulbankUpdateParams = {
  merchant_password: string;
  is_testing: boolean;
  taxation: RuTaxation;
  payment_methods: ModulbankPaymentMethods[];
};

type BepaidUpdateParams = {
  secret_key?: string;
  is_testing?: boolean;
};

type PaykeeperUpdateParams = {
  user_name?: string;
  password: string;
};

type SberbankUpdateParams = {
  login?: string;
  password?: string;
  is_testing?: boolean;
  success_url: string;
  fail_url: string;
  taxation: RuTaxation;
};

export type YookassaUpdateParams = {
  return_url?: string;
  taxation: RuTaxation;
};

type TelegramBotUpdateParams = {
  bot_token: string;
};

type GenericConnectionUpdate<T, B extends ConnectionsType> = {
  companyId: number;
  itemId: number;
  name: string;
  type: B;
  params?: T;
};

export type OdnoklassnikiConnectionPatchRequest = GenericConnectionUpdate<OdnoklassnikiUpdateParams, 'odnoklassniki'>;
export type WabaConnectionPatchRequest = GenericConnectionUpdate<WabaUpdateParams, 'waba'>;
export type PaypalConnectionPatchRequest = GenericConnectionUpdate<PaypalUpdateParams, 'paypal'>;
export type TinkoffConnectionPatchRequest = GenericConnectionUpdate<TinkoffUpdateParams, 'tinkoff'>;
export type ModulbankConnectionPatchRequest = GenericConnectionUpdate<ModulbankUpdateParams, 'modulbank'>;
export type BepaidConnectionPatchRequest = GenericConnectionUpdate<BepaidUpdateParams, 'bepaid'>;
export type PaykeeperConnectionPatchRequest = GenericConnectionUpdate<PaykeeperUpdateParams, 'paykeeper'>;
export type SberbankConnectionPatchRequest = GenericConnectionUpdate<SberbankUpdateParams, 'sberbank'>;
export type TelegramBotConnectionPatchRequest = GenericConnectionUpdate<TelegramBotUpdateParams, 'telegram_bot'>;
export type TelegramConnectionPatchRequest = GenericConnectionUpdate<TelegramUpdateParams, 'telegram'>;
export type WhatsAppConnectionPatchRequest = GenericConnectionUpdate<WhatsAppUpdateParams, 'whatsapp'>;
export type AmoCRMConnectionPatchRequest = GenericConnectionUpdate<undefined, 'amocrm'>;
export type BitrixConnectionPatchRequest = GenericConnectionUpdate<undefined, 'bitrix'>;
export type LifepayConnectionPatchRequest = GenericConnectionUpdate<undefined, 'lifepay'>;
export type ChatAPIInstagramConnectionPatchrequest = GenericConnectionUpdate<undefined, 'chatapi_instagram'>;
export type YClientsConnectionPatchRequest = GenericConnectionUpdate<undefined, 'yclients'>;
export type YookassaConnectionPatchRequest = GenericConnectionUpdate<YookassaUpdateParams, 'yookassa'>;
export type ConnectionsUpdateRequest =
  | AmoCRMConnectionPatchRequest
  | BitrixConnectionPatchRequest
  | OdnoklassnikiConnectionPatchRequest
  | WabaConnectionPatchRequest
  | PaypalConnectionPatchRequest
  | TinkoffConnectionPatchRequest
  | ModulbankConnectionPatchRequest
  | BepaidConnectionPatchRequest
  | PaykeeperConnectionPatchRequest
  | SberbankConnectionPatchRequest
  | TelegramBotConnectionPatchRequest
  | TelegramConnectionPatchRequest
  | WhatsAppConnectionPatchRequest
  | LifepayConnectionPatchRequest
  | ChatAPIInstagramConnectionPatchrequest
  | YClientsConnectionPatchRequest
  | YookassaConnectionPatchRequest;

type OdnoklassnikiCreateParams = {
  ok_api_key: string;
};

type WabaCreateParams = {
  api_key: string;
};

type PaypalCreateParams = {
  client_id: string;
  secret: string;
  is_testing: boolean;
  invoice_lifetime_minutes: number;
};

export type TinkoffCreateParams = {
  terminal_id: string;
  terminal_password: string;
  taxation: RuTaxation;
  invoice_lifetime_minutes: number;
};

export type ModulbankCreateParams = {
  merchant_id: string;
  merchant_password: string;
  is_testing: boolean;
  taxation: RuTaxation;
  payment_methods: ModulbankPaymentMethods[];
  invoice_lifetime_minutes: number;
};

export type BepaidCreateParams = {
  shop_id: number;
  secret_key: string;
  is_testing: boolean;
  invoice_lifetime_minutes: number;
};

export type PaykeeperCreateParams = {
  server_url: string;
  user_name: string;
  password: string;
  invoice_lifetime_minutes: number;
};

export type SberbankCreateParams = {
  login: string;
  password: string;
  is_testing: boolean;
  success_url: string;
  fail_url: string;
  taxation: RuTaxation;
  invoice_lifetime_minutes: number;
};

type TelegramCreateParams = {
  phone?: string;
  code?: number;
  phone_code_hash?: string;
  password?: string;
  session_id: string;
};

type TelegramBotCreateParams = {
  bot_token: string;
};

type GenericConnectionCreate<T, B extends ConnectionsType> = {
  companyId: number;
  name: string;
  type: B;
  params?: T;
};

export type OdnoklassnikiConnectionPostRequest = GenericConnectionCreate<OdnoklassnikiCreateParams, 'odnoklassniki'>;
export type WabaConnectionPostRequest = GenericConnectionCreate<WabaCreateParams, 'waba'>;
export type PaypalConnectionPostRequest = GenericConnectionCreate<PaypalCreateParams, 'paypal'>;
export type TinkoffConnectionPostRequest = GenericConnectionCreate<TinkoffCreateParams, 'tinkoff'>;
export type ModulbankConnectionPostRequest = GenericConnectionCreate<ModulbankCreateParams, 'modulbank'>;
export type BepaidConnectionPostRequest = GenericConnectionCreate<BepaidCreateParams, 'bepaid'>;
export type PaykeeperConnectionPostRequest = GenericConnectionCreate<PaykeeperCreateParams, 'paykeeper'>;
export type SberbankConnectionPostRequest = GenericConnectionCreate<SberbankCreateParams, 'sberbank'>;
export type TelegramBotConnectionPostRequest = GenericConnectionCreate<TelegramBotCreateParams, 'telegram_bot'>;
export type TelegramConnectionPostRequest = GenericConnectionCreate<TelegramCreateParams, 'telegram'>;
export type WhatsAppConnectionPostRequest = GenericConnectionCreate<undefined, 'whatsapp'>;
export type AmoCRMConnectionPostRequest = GenericConnectionCreate<undefined, 'amocrm'>;
export type BitrixConnectionPostRequest = GenericConnectionCreate<undefined, 'bitrix'>;
export type LifepayConnectionPostRequest = GenericConnectionCreate<undefined, 'lifepay'>;
export type ChatAPIInstagramConnectionPostrequest = GenericConnectionCreate<undefined, 'chatapi_instagram'>;
export type YClientsConnectionPostRequest = GenericConnectionCreate<undefined, 'yclients'>;
export type ConnectionsCreateRequest =
  | AmoCRMConnectionPostRequest
  | BitrixConnectionPostRequest
  | OdnoklassnikiConnectionPostRequest
  | WabaConnectionPostRequest
  | PaypalConnectionPostRequest
  | TinkoffConnectionPostRequest
  | ModulbankConnectionPostRequest
  | BepaidConnectionPostRequest
  | PaykeeperConnectionPostRequest
  | SberbankConnectionPostRequest
  | TelegramBotConnectionPostRequest
  | TelegramConnectionPostRequest
  | WhatsAppConnectionPostRequest
  | LifepayConnectionPostRequest
  | ChatAPIInstagramConnectionPostrequest
  | YClientsConnectionPostRequest;

export type BlockedChatType = {
  id: number;
  phone: string;
  createdAt: string;
};

export type BlackListChatsRequest = CompanyConnectionType & {
  limit?: number;
  offset?: number;
};

export type BlackListChatAddRequest = CompanyConnectionType & {
  phone: string;
};

export type BlackListChatDeleteRequest = CompanyConnectionType & {
  itemId: number;
};

export type WabaConnectionRequest = {
  company_id: number;
  waba_connection_id: number;
};

export type linkFacebookRequest = WabaConnectionRequest & {
  access_token: string;
  app_id: string;
  app_secret: string;
};

export type FacebookCatalog = {
	id: number;
	name: string;
	wabaConnectionId: number;
};

export type FacebookCatalogStatus = 'connected' | 'disconnected' | 'expired';

export type WabaCatalogStatus = {
  status: FacebookCatalogStatus;
  payload: string;
};
