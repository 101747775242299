import React, { createContext } from 'react';
import { SelectedSubscriptionsType } from '../components/ClientView/ClientView';


export type ClientViewContext = {
  selectedSubscriptions: Array<SelectedSubscriptionsType>;
  setSelectedSubscriptions:  React.Dispatch<React.SetStateAction<SelectedSubscriptionsType[]>>;
  setOnPayBalance: React.Dispatch<React.SetStateAction<boolean>>;
};

const clientViewContext = createContext<ClientViewContext>({
  selectedSubscriptions: [],
  setSelectedSubscriptions: () => [],
  setOnPayBalance: () => false,
});

export { clientViewContext };
