import './GuidePanels.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { setOnboardingGuide, setOnboardingSwitchModal } from '../../../views/Chat/companiesSlice';
import { companyOnboardingSteps, CompanyOnboardingSteps } from '../../../api/types';
import { getGuidesStatus, guideDocumentations, guidedOnboardingSteps } from '../utils/guides';
import GuidePanel from '../GuidePanel';
import { YandexMetrikaCall } from "../../../utils/utils";


function GuidePanels() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useRouteMatch('/companies/:companyId');
  const { guides, conectionType } = getGuidesStatus();

  const guideOn = (step: CompanyOnboardingSteps) => {
    return (e: React.MouseEvent<HTMLElement>) => {
      const guideConfig = guidedOnboardingSteps[step];
      if (guideConfig) {
        dispatch(setOnboardingGuide(step));
        dispatch(setOnboardingSwitchModal(false));
        history.push(`${url?.url}${guideConfig.path}`);
        YandexMetrikaCall('reachGoal', guideConfig.yametrika);
      }
    }
  };

  const chooseDocumentations = (step: CompanyOnboardingSteps) => {
    const docLinks = guideDocumentations[step];
    if (docLinks) return docLinks[i18n.language];
  };

  const chooseTitle = (step: CompanyOnboardingSteps) => {
    let title = `company_onboarding.guides.${step}`;
    if ((step === 'add_connection' || step === 'add_integration') && conectionType) title = `${title}.${conectionType}`;
    return title;
  };

  return (
    <div className='guidePanels'>
      {guides?.map((step, index) => (
        <GuidePanel key={step.type} step={index + 1} title={t(chooseTitle(step.type))} complete={step.complete}
          guideOn={guideOn(step.type)} documentation={chooseDocumentations(step.type)}
          subtitle={guidedOnboardingSteps[step.type]?.subtitle}  disable={step.disable} />
      ))}
    </div>
  );
}

export default GuidePanels;
