import React from 'react';
import './InteractiveMessageSettingsAttachmentPayloadTypes.scss';
import { payloadTypes } from './consts';
import { CustomTemplate } from '../../templatesTypes';

type Props = {
  payloadType: string;
  onChangeAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  editTemplateData?: CustomTemplate;
  setEditTemplateFileUrl: React.Dispatch<React.SetStateAction<{ fileUrl: string; documentName: string | null } | null>>;
  editTemplateDataFileUrl: {
    fileUrl: string;
    documentName: string | null;
  } | null;
  fileAttachmentError: boolean;
};

const InteractiveMessageSettingsAttachmentPayloadTypes: React.FC<Props> = ({
  payloadType,
  onChangeAttachedFile,
  editTemplateData,
  setEditTemplateFileUrl,
  editTemplateDataFileUrl,
  fileAttachmentError,
}) => {
  const PayloadComponent = payloadTypes[payloadType];

  return (
    <div className="interactiveMessageSettingsAttachmentPayloadTypes">
      <PayloadComponent
        payloadType={payloadType}
        onChangeAttachedFile={onChangeAttachedFile}
        editTemplateData={editTemplateData}
        setEditTemplateFileUrl={setEditTemplateFileUrl}
        editTemplateDataFileUrl={editTemplateDataFileUrl}
        fileAttachmentError={fileAttachmentError}
      />
    </div>
  );
};

export default InteractiveMessageSettingsAttachmentPayloadTypes;
