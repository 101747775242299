import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../store/store';
import { RequisitesSelector } from '../../SubscriptionsSelector';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { subRequisiteItemContext, useSubscriptionsRequisiteRequest } from './utils';
import { PaymentRequisite } from '../../SubscriptionsTypes';
import { removePaymentRequisite } from '../../SubscriptionsSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import RequisitesPlug from '../RequisitesList/RequisitesPlug';
import RequisitesList from '../RequisitesList/RequisitesList';
import Button from '../../../../../components/Button';
import RequisitesForm from '../../../componentsBilling/RequisitesForm';
import ActionDialog from "../../../../../components/Modals/ActionDialog";


function RequisitesView() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const requisitesList = useSelector(RequisitesSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const [isVisibleForm, setIsVisibleForm] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [editData, setEditData] = useState<PaymentRequisite>();
  const [deleteData, setDeleteData] = useState<PaymentRequisite>();
  const { makeRequisite } = useSubscriptionsRequisiteRequest();

  const onAdd = () => {
    setEditData(undefined);
    setIsVisibleForm(true);
  };

  const onEdit = (requisite: PaymentRequisite) => {
    setEditData(requisite);
    setIsVisibleForm(true);
  };

  const onDelete = (requisite: PaymentRequisite) => {
    setIsDelete(true);
    setDeleteData(requisite);
  };

  const onDeleteHandler = () => {
    if (deleteData && isDelete) {
      dispatch(removePaymentRequisite({ company_id: currentCompany.id, requisite_id: deleteData.id })).then((res) => {
        if (res) setIsDelete(false);
      });
    }
  };

  return (
    <>
      {isDelete && (
        <ActionDialog
          title={t('requisites.actions.delete_title')}
          message={t('requisites.actions.delete_text')}
          action={t('delete')} cancel={t('cancel')}
          onAction={onDeleteHandler} setIsOpen={setIsDelete} />
      )}
      {isVisibleForm && <RequisitesForm  makeRequisite={makeRequisite} setIsOpen={setIsVisibleForm} editData={editData} />}
      {!requisitesList || requisitesList.length <= 0 ? (
        <RequisitesPlug setIsVisibleForm={setIsVisibleForm} />
      ) : (
        <>
          <WrapperPermission permission="subscriptionsEdit">
            <Button text={t('subscriptions.requisites.add_requisites')}
              textType="regular" color="orange" onClick={onAdd} />
          </WrapperPermission>
          <subRequisiteItemContext.Provider value={{ onEdit, onDelete }}>
            <RequisitesList requisitesList={requisitesList} />
          </subRequisiteItemContext.Provider>
        </>
      )}
    </>
  );
}

export default RequisitesView;
