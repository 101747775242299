import { Redirect, Route, useRouteMatch } from "react-router-dom";
import './PartnerCabinet.scss';
import PartnerCabinetList from "./PartnerCabinetList";
import { partnersTabs } from "./tabs";
import ClientView from "./Clients/components/ClientView";


const PartnerCabinetRoute = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <PartnerCabinetList />
      <div className='partnerCabinetContent'>
        {partnersTabs.map(({ id, Component }) => (
          <Route key={id} path={`${url}/${id}`} exact>
            <Component />
          </Route>
        ))}
        <Route path={`${url}/clients/:clientId`} exact>
          <ClientView />
        </Route>
        <Route path={`${url}/`} exact>
          <Redirect to={`${url}/${partnersTabs[0].id}`} />
        </Route>
      </div>
    </>
  );
};

export default PartnerCabinetRoute;
