import React, { useState } from 'react';
import './InteractiveMessages.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { wabaInteractiveSelector } from '../../templatesSelector';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as TextTypeIcon } from '../../../../assets/article.svg';
import { ReactComponent as ImageTypeIcon } from '../../../../assets/image.svg';
import { ReactComponent as VideoTypeIcon } from '../../../../assets/video.svg';
import { ReactComponent as DocumentTypeIcon } from '../../../../assets/template.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import Button from '../../../../components/Button';
import SwitchTabs from '../../../../components/SwitchTabs';
import Search from '../../../../components/Search/Search';
import InteractiveMessageTemplateCard from '../InteractiveMessageTemplateCard/InteractiveMessageTemplateCard';


type Props = {
  /* eslint-disable-next-line */
  setTemplateEdit: (value: any) => void;
};

const InteractiveMessages: React.FC<Props> = ({ setTemplateEdit }) => {
  const { t } = useTranslation();
  const [selectedMessageType, setSelectedMessageType] = useState<string>('all');
  const [searchValue, setSearchValue] = useState<string>('');
  const wabaInteractiveTemplates = useSelector(wabaInteractiveSelector);
  const dispatch = useDispatch()
  const { url } = useRouteMatch();
  const tabs = [
    {
      tabName: t('templates.interactive_attachments_types.all'),
      tabId: 'all',
    },
    {
      tabName: t('templates.interactive_attachments_types.text'),
      tabId: 'text',
      tabIcon: TextTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.image'),
      tabId: 'image',
      tabIcon: ImageTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.video'),
      tabId: 'video',
      tabIcon: VideoTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.document'),
      tabId: 'document',
      tabIcon: DocumentTypeIcon,
    },
  ];

  const onSelectMessageType = (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonMessageType = e.currentTarget.id;

    setSelectedMessageType(buttonMessageType);
  };

  const filteredTemplatesByTabType = () => {
    if (selectedMessageType !== 'all') {
      let filteredTemplateByTab = wabaInteractiveTemplates.filter(
        (template) => template.params.header && template.params.header.type === selectedMessageType
      );
      if (searchValue) {
        filteredTemplateByTab = filteredTemplateByTab.filter((template) =>
          template.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      return filteredTemplateByTab.map((template) => (
        <InteractiveMessageTemplateCard key={template.id} template={template} setTemplateEdit={setTemplateEdit} />
      ));
    }
    if (searchValue) {
      return wabaInteractiveTemplates
        .filter((template) => template.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map((template) => (
          <InteractiveMessageTemplateCard key={template.id} template={template} setTemplateEdit={setTemplateEdit} />
        ));
    }
    return wabaInteractiveTemplates.map((template) => (
      <InteractiveMessageTemplateCard key={template.id} template={template} setTemplateEdit={setTemplateEdit} />
    ));
  };

  return (
    <div className="interactiveMessages">
      <div className="interactiveMessages__header">
        <div className="interactiveMessages__header_title">
          <h1>{t('templates.interactive_messages')}</h1>
          <WrapperPermission permission='templatesCreate'>
            <Button type='button' color='orange' textType='regular' text={t('templates.create_templates')}
              onClick={() => dispatch(push(`${url}/add`))} image={<PlusIcon />} />
          </WrapperPermission>
        </div>
        <div className="interactiveMessages__header_bottom">
          <SwitchTabs tabs={tabs} onSelectHandler={onSelectMessageType} selectedTab={selectedMessageType} />
          <Search onChangeSearchInput={setSearchValue} value={searchValue} />
        </div>
      </div>
      <div className="interactiveMessages__templates">{filteredTemplatesByTabType()}</div>
    </div>
  );
};

export default InteractiveMessages;
