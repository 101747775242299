import axiosInstance from "../../api/AxiosInstance";
import * as typ from "./PartnerCabinetTypes";


export const getPartnerInfo = async (companyId: number): Promise<typ.PartnerInfo> =>
  await axiosInstance.get(`/companies/${companyId}/partners/info`);

export const getPartnerCompanies =
  async (requestOption: typ.PartnerCompaniesRequest): Promise<typ.PartnerCompany[]> => {
  const { company_id: companyId, ...rest } = requestOption;
  return await axiosInstance.get(`/companies/${companyId}/partners/partner_companies/`, {
    params: { ...rest }
  });
};

export const getPartnerCompany = async (requestOption: typ.PartnerCompanyRequest): Promise<typ.PartnerCompany> =>
  await axiosInstance.get(
    `/companies/${requestOption.company_id}/partners/partner_companies/${requestOption.partner_company_id}`
  );

export const patchPartnerCompanyPayment = async (requestOption: typ.PaymentLockRequest): Promise<typ.PartnerCompany> =>
  await axiosInstance.patch(
    `/companies/${requestOption.company_id}/partners/partner_companies/${requestOption.partner_company_id}`,
    { payments_locked: requestOption.is_locked }
  );

export const getPartnerCompanySubscriptions =
  async (requestOption: typ.PartnerCompanyRequest): Promise<typ.CompanySubscription[]> => {
    const { company_id: companyId, partner_company_id: partnerCompanyId } = requestOption;
    return await axiosInstance.get(`/companies/${companyId}/partners/partner_companies/${partnerCompanyId}/subscriptions/`);
};

export const getPartnerCompaniesPayments = async (
  requestOption: typ.CompaniesPaymentsRequest): Promise<typ.CompanyPayment[]> => {
  const { company_id: companyId, ...rest } = requestOption;
  return await axiosInstance.get(`/companies/${companyId}/partners/partner_companies/payments/`, {
    params: { ...rest } });
};

export const getPartnerCompanyRequisites = async (
  requestOption: typ.CompanyRequisitesRequest): Promise<typ.CompanyRequisite[]> => {
  const { company_id: companyId, partner_company_id: partnerCompanyId, ...rest } = requestOption;
  return await axiosInstance.get(
    `/companies/${companyId}/partners/partner_companies/${partnerCompanyId}/payments/requisites/`,
    { params: { ...rest } });
};

export const postPartnerCompanyInvoice = async (
  requestOption: typ.PartnerCompanyInvoiceRequest): Promise<typ.CompanyPayment> => {
    const { company_id: companyId, partner_company_id: partnerCompanyId, ...rest } = requestOption;
  return await axiosInstance.post(`/companies/${companyId}/partners/partner_companies/${partnerCompanyId}/payments/invoices/`,
    { ...rest });
};

export const getPartnerRequisites = async (
  requestOption: typ.PartnerRequisitesRequest): Promise<typ.CompanyRequisite[]> => {
  const { company_id: companyId, ...rest } = requestOption;
  return await axiosInstance.get(`/companies/${companyId}/partners/payments/requisites/`, {
    params: { ...rest } });
};

export const postPartnerRequisite = async (
  requestOption: typ.PartnerRequisitePostRequest): Promise<typ.CompanyRequisite> => {
  const { company_id: companyId, ...rest } = requestOption;
  return await axiosInstance.post(`/companies/${companyId}/partners/payments/requisites/`, { ...rest });
};

export const deletePartnerRequisite = async (
  requestOption: typ.PartnerRequisiteRequest): Promise<typ.CompanyRequisite> => {
  const { company_id: companyId, requisite_id : requisiteId } = requestOption;
  return await axiosInstance.delete(`/companies/${companyId}/partners/payments/requisites/${requisiteId}`);
};

export const putPartnerRequisite = async (
  requestOption: typ.UpdatePartnerRequisite): Promise<typ.CompanyRequisite> => {
  const { company_id: companyId, requisite_id : requisiteId, ...rest } = requestOption;
  return await axiosInstance.put(`/companies/${companyId}/partners/payments/requisites/${requisiteId}`, { ...rest });
};

export const getPartnerNotificationChannels = async (companyId: number): Promise<typ.PartnerNotificationChannel[]> =>
  await axiosInstance.get(`/companies/${companyId}/partners/notifications/channels/`);

export const postPartnerNotificationChannel = async (
  requestOption: typ.PartnerNotificationChannelCreate): 
  Promise<typ.PartnerNotificationChannel | typ.PartnerNotificationTelegramResponse> => {
  const { companyId, ...rest } = requestOption;
  return await axiosInstance.post(`/companies/${companyId}/partners/notifications/channels/`, { ...rest });
};

export const deletePartnerNotificationChannel = async (
  requestOption: typ.PartnerNotificationChannelRequest): Promise<void> => {
    const { companyId, channelId } = requestOption;
    await axiosInstance.delete(`/companies/${companyId}/partners/notifications/channels/${channelId}`);
};

export const patchPartnerNotificationChannel = async (
  requestOption: typ.PartnerNotificationChannelUpdate): Promise<typ.PartnerNotificationChannel> => {
  const { companyId, channelId, ...rest } = requestOption;
  return await axiosInstance.patch(`/companies/${companyId}/partners/notifications/channels/${channelId}`, { ...rest });
};
