import './WabaTemplateName.scss';
import { useRef } from "react";
import { WabaTemplateInfo } from '../../../../templatesTypes';
import { useTooltip } from "../../../../../../components/Modals/ToolTip/utils";
import { getPartsComponents } from '../../../../utils/waba';
import ToolTip from "../../../../../../components/Modals/ToolTip";
import WabaPreview from '../../../WabaPreview';


type WabaTemplateNameProps = {
  name: string;
  text?: string;
  template: WabaTemplateInfo;
};

function WabaTemplateName({ name, text, template }: WabaTemplateNameProps) {
  const { viewPopupOn: onPreviewEnter, viewPopupOff: onPreviewLeave, viewPopup: viewPopupPreview } = useTooltip();
  const previewRef = useRef<HTMLDivElement>(null);

  const { header, body, footer, buttons } = getPartsComponents(template);

  return (
    <>
      {viewPopupPreview && (
        <ToolTip refElement={previewRef}>
          <div className="wabaTemplateName__preview">
            <WabaPreview header={header} body={body} footer={footer} buttons={buttons} />
          </div>
        </ToolTip>
      )}
      <div ref={previewRef} className="wabaTemplateName" onMouseEnter={onPreviewEnter} onMouseLeave={onPreviewLeave}>
        <p className="regularText semibold">{name}</p>
        {text && <p className="smallText">{text}</p>}
      </div>
    </>
  );
}

export default WabaTemplateName;
