import React, { useContext, useEffect, useState } from "react";
import './ClientInvoicesCreate.scss';
import * as yup from 'yup';
import { useSelector } from "react-redux";
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { useDebounce } from 'use-debounce';
import { CompanyInvoiceRequestItem, CompanyPayment, PartnerCompany } from "../../../PartnerCabinetTypes";
import { addPartnerCompanyInvoice } from '../../../PartnerCabinetSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { useAppDispatch } from "../../../../../store/store";
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";
import {getPartnerCompanyRequisites, getPartnerRequisites} from "../../../PartnerCabinetAPI";
import { clientViewContext } from "../../ulils/Context";
import { PartnerInvoiceSchema, useInvoice } from "../../../api/invoices";
import { TariffType, tarifName } from "../../../../../utils/dialogs";
import PeriodSelector from '../../../components/PeriodSelector';
import PaymentMethod from '../../../components/PaymentMethod';
import Input from '../../../../../components/Input';
import PromoCode from '../../../components/PromoCode/PromoCode';
import SelectedSubscriptions from './InvoicesSubscriptions';
import Button from '../../../../../components/Button';
import Dropdown from "../../../../Settings/Dropdown/Dropdown";
import { PartnerSubscriptions } from "../../../PartnerCabinetSelector";


type Props = {
  partnerCompany: PartnerCompany;
  setOnPay: React.Dispatch<React.SetStateAction<boolean>>;
};

type Named = {
  id: number;
  name: string;
};

const DIALOGS_LIMITS = { min: 1, max: 25000 };

const ClientInvoicesCreate = ({ partnerCompany, setOnPay }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const partnerSubscriptions = useSelector(PartnerSubscriptions);
  const [iHavePromoCode, setIHavePromoCode] = useState<boolean>(false);
  const [promoCodeTest, setPromoCodeTest] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<CompanyPayment>();
  const [requisites, setRequisites] = useState<Named[]>([]);
  const { selectedSubscriptions } = useContext(clientViewContext);
  const [hasDialogs, setHasDialogs] = useState<boolean>(false);
  const [itemsSubscriptions, setItemsSubscriptions] = useState<CompanyInvoiceRequestItem[]>([]);
  const getInvoice = useInvoice();

  useEffect(() => {
    const items: CompanyInvoiceRequestItem[] = [];
    selectedSubscriptions.map((item) => items.push({ type: "SUBSCRIPTION", id: item.id }));
    setItemsSubscriptions(items);
    setHasDialogs(
      tarifName(partnerCompany.waba360DialogPartnerId as TariffType) === 'russia' &&
      partnerSubscriptions.some((item) => item.subscriptionType === 'CONNECTION_WABA')
    );

    dispatch(addPartnerCompanyInvoice({
        company_id: currentCompany.id,
        partner_company_id: partnerCompany.id,
        preview: true,
        payment_method: 'CARD_RU',
        period_months: 1,
        items
      })
    ).then((res) => {
      if (typeof res.payload === 'object') {
        setInvoices(res.payload);
      }
    });
  }, []);

  useEffect( () => {
    let isFetchData = true;

    const fetchData = async () => {
      if (partnerCompany.id) {
        let requisitesList: Named[];

        if (partnerCompany.paymentsLocked) {
          requisitesList = await getPartnerRequisites({ company_id: currentCompany.id, only_approved: true });
        } else {
          requisitesList = await getPartnerCompanyRequisites({
            company_id: currentCompany.id, partner_company_id: partnerCompany.id, only_approved: true });
        }

        if (isFetchData) {
          if (requisitesList.length > 0) {
            setRequisites(requisitesList.map((item) => ({ id: item.id, name: item.name })));
          }
        }
      } else {
        setRequisites([]);
      }
    };

    fetchData();

    return () => {
      isFetchData = false;
    };

  }, []);

  const schema = yup.object().shape({
    period_months: yup.number(),
    payment_method: yup.string(),
    promo_code: yup.string(),
    partner_email: yup.string().when('payment_method', {
      is: (value: string) => value === 'CARD_RU' && partnerCompany.paymentsLocked,
      then: yup.string().email(t('error.form.email_format')).required(t('error.form.empty_field')),
      otherwise: yup.string().nullable()
    }),
    company_requisite_id: yup.number().when('payment_method', {
      is: 'BANK_TRANSFER',
      then: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
      otherwise: yup.number().transform((current, original) =>  original === '' ? null : current).nullable(),
    }),
    dialogs: yup.number().transform((current, original) =>  original === '' ? null : current)
      .nullable().min(DIALOGS_LIMITS.min, t('error.form.min_value', { limit: DIALOGS_LIMITS.min }))
      .max(DIALOGS_LIMITS.max, t('error.form.max_value', { limit: DIALOGS_LIMITS.max }))
  });

  const methods = useForm<PartnerInvoiceSchema>({
    mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: { period_months: 1, payment_method: 'CARD_RU' }
  });

  const periodMonths = useWatch({ control: methods.control, name: 'period_months' });
  const paymentMethod = useWatch({ control: methods.control, name: 'payment_method' });
  const promo = useWatch({ control: methods.control, name: 'promo_code' });
  const dialogsInput = useWatch({ control: methods.control, name: 'dialogs' });
  const [dialogs] = useDebounce(dialogsInput, 400);

  useEffect(() => {
    if (itemsSubscriptions.length > 0) {
      fetchInvoices(methods.getValues());
    }
  }, [periodMonths]);

  useEffect(() => {
    if (promo) checkPromo();
  }, [promo]);

  useEffect(() => {
    if (methods.formState.errors.dialogs === undefined) {
      getInvoice({
        ...methods.getValues(),
        subscriptions: itemsSubscriptions.map((item) => item.id)
      }, partnerCompany.id, true).then((res) => {
        if (res) setInvoices(res);
      });
    }
  }, [dialogs]);

  const fetchInvoices = async (data: PartnerInvoiceSchema) => {
    const invoices = await getInvoice(
      { ...data, subscriptions: itemsSubscriptions.map((item) => item.id) }, partnerCompany.id, true);
    if (invoices) setInvoices(invoices);
  };

  const onClickPromoCode = (promoCodeValue: string) => {
    methods.setValue('promo_code', promoCodeValue);
  };

  const checkPromo = async () => {
    const data = methods.getValues();
    const invoices = await getInvoice(
      { ...data, subscriptions: itemsSubscriptions.map((item) => item.id) }, partnerCompany.id, true);
    setPromoCodeTest(!!invoices);
    if (!invoices) methods.setValue('promo_code', '');
  };

  const onSubmit = async (data: PartnerInvoiceSchema) => {
    const invoices = await getInvoice(
      { ...data, subscriptions: itemsSubscriptions.map((item) => item.id) }, partnerCompany.id, false);
    if (invoices?.previewUrl) {
      dispatch(addAlertWithCustomText({
        message: t('partner_cabinet.payments.create.submit_fulfilled'), type: 'info' }));
      setOnPay(false);
      window.open(invoices.previewUrl, '_blank');
    }
  };

  if (!invoices) return <></>;

  return (
    <div className="clientInvoicesCreate">
      <h1>{t('partner_cabinet.clients.create.title')}</h1>
      <FormProvider {...methods}>
        <PeriodSelector label={t('partner_cabinet.clients.create.period')} name="period_months" />
        {invoices.receipt.items.length > 0 && <SelectedSubscriptions invoices={invoices} />}
        {hasDialogs &&
          <Input name='dialogs' label={t('partner_cabinet.payments.create.dialogs.label')}
            placeholder={t('partner_cabinet.payments.create.dialogs.placeholder')}
            inputType='number' min={0} step={100} />
        }
        <PaymentMethod label={t('partner_cabinet.clients.create.methods_payment')} name="payment_method"
          clientPaymentsLocked={partnerCompany.paymentsLocked}/>
        {paymentMethod === 'BANK_TRANSFER' &&
          <Dropdown label={t('partner_cabinet.payments.create.requisites')} name='company_requisite_id'
            items={requisites} labelKey='name' valueKey='id' placeholder={t('select')} />
        }
        {paymentMethod === 'CARD_RU' && partnerCompany.paymentsLocked &&
          <Input name='partner_email' label={t('partner_cabinet.clients.create.email')}
            placeholder={t('partner_cabinet.payments.create.email.placeholder')} autoComplete='on' />
        }
        {iHavePromoCode ? (<>
          <PromoCode setPromo={onClickPromoCode} label={t('partner_cabinet.payments.create.promo_code.label')} />
          {promoCodeTest && <span>{t('partner_cabinet.payments.create.promo_code.success')}</span>}
        </>) : (
          <p className="promoCodeText" onClick={() => setIHavePromoCode(true)}>
            {t('partner_cabinet.payments.create.promo_code.caption')}
          </p>
        )}
        <Button color='orange' textType='regular' text={t('create')} type='submit'
          onClick={methods.handleSubmit(onSubmit)} />
      </FormProvider>
    </div>
  );
};

export default ClientInvoicesCreate;
