import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Counter from '../Counter';
import Textarea from '../Textarea';
import './WabaTemplateFooter.scss';


const FOOTER_MAX_LENGTH = 60;

function WabaTemplateFooter() {
  const { t } = useTranslation();

  const text = useWatch({ name: 'footer' });
  const category = useWatch({ name: 'category' });

  return (
    <div className='wabaTemplateFooter'>
      <div className='wabaTemplateFooter__header'>
        <h3>{t('templates.waba_list.form.footer.title')}</h3>
      </div>
      <Textarea name='footer' className='regularText' autoComplete='off' maxLength={FOOTER_MAX_LENGTH}
        placeholder={t('templates.waba_list.form.footer.placeholder')} readOnly={category === 'AUTHENTICATION'} />
      <Counter counter={text ? text.length : 0} maximum={FOOTER_MAX_LENGTH}
        text='templates.waba_list.form.character.character' />
    </div>
  );
}

export default WabaTemplateFooter;
