import { TariffType } from '../utils/dialogs';
import { CurrenciesType } from '../views/Settings/CompanySettings/currency';
import { AvailableQuestioning, CompanyOnboarding, CompanyOnboardingUpdate, QuestioningCreate } from './types';
import axiosInstance from './AxiosInstance';


export type Company = {
  id: number;
  name: string;
  ownerId: number;
  isPartner: boolean;
  paymentsLocked?: boolean;
  timezone: string;
  paymentCurrency: CurrenciesType;
  waba360DialogPartnerId?: TariffType;
  hadConnections?: boolean;
};

type GenericConnection<T, B extends string> = {
  id: number;
  name: string;
  lastStatus:{
    statusType:"good"|"bad"
    statusSubtype:"normal" | "generic_authorization_error" | "generic_connection_error" | "whatsapp_qr_not_scanned"
  }|null
  radistToken: string;
  subscriptionId: number;
  type: B;
  params: T;
};

export type AmoCRMConnection = GenericConnection<ConnectionAmoCRMParams, 'amocrm'>;
export type BitrixConnection = GenericConnection<ConnectionBitrixParams, 'bitrix'>;
export type WhatsAppConnection = GenericConnection<ConnectionWhatsAppParams, 'whatsapp'>;
export type TelegramConnection = GenericConnection<ConnectionTelegramParams, 'telegram' > ;
export type TelegramBotConnection = GenericConnection<ConnectionTelegramBotParams, 'telegram_bot' > ;
export type OdnoklassnikiConnection = GenericConnection<ConnectionOdnoklassnikiParams, 'odnoklassniki'>;
export type WabaConnection = GenericConnection<ConnectionWabaParams, 'waba'>;
export type TinkoffConnection = GenericConnection<ConnectionTinkoffParams, 'tinkoff'>;
export type ModulBankConnection = GenericConnection<ConnectionModulBankParams, 'modulbank'>;
export type SberbankConnection = GenericConnection<ConnectionSberbankParams, 'sberbank'>;
export type LifepayConnection = GenericConnection<ConnectionLifepayParams, 'lifepay'>;
export type ChatAPIInstagramConnection = GenericConnection<ConnectionChatAPIInstagramParams, 'chatapi_instagram'>;
export type YClientsConnection = GenericConnection<ConnectionYClientsParams, 'yclients'>;
export type PaykeeperConnection = GenericConnection<ConnectionPaykeeperParams, 'paykeeper'>;
export type PaypalConnection = GenericConnection<ConnectionPaypalParams, 'paypal'>;
export type BepaidConnection = GenericConnection<ConnectionBepaidParams, 'bepaid'>;
export type YookassaConnection = GenericConnection<ConnectionYookassaParams, 'yookassa'>;

export type NewConnection =
  | AmoCRMConnection
  | BitrixConnection
  | WhatsAppConnection
  | TelegramConnection
  | TelegramBotConnection
  | OdnoklassnikiConnection
  | WabaConnection
  | TinkoffConnection
  | ModulBankConnection
  | SberbankConnection
  | LifepayConnection
  | ChatAPIInstagramConnection
  | YClientsConnection
  | PaykeeperConnection
  | PaypalConnection
  | BepaidConnection
  | YookassaConnection;

export type ConnectionAmoCRMParams = {
  domain: string;
  accountId: string;
  clientUuid: string;
  widgetCode: string;
};

export type ConnectionBitrixParams = {
  domain: string;
  accountId: string;
  scope: string;
};

export type ConnectionWhatsAppParams = {
  phone: string;
  chatsBlackList: string[];
  groupChatsEnabled: boolean;
  qrUrl?: string;
};

export type ConnectionOdnoklassnikiParams = {
  apiKey: string;
  groupId: string;
};

export type ConnectionTelegramParams = {
  radistToken: string;
  groupChatsEnabled: boolean;
  userId: string;
  username?: string;
  phone?: string;
};

export type ConnectionTelegramBotParams = {
    botId: string,
    botUsername: string
}

export type ConnectionWabaParams = {
  phone: string;
  facebookCatalogEnabled: boolean;
  facebookBusinessId: number;
  wabaBusinessId: number;
  wabaAutoTemplatesEnabled: boolean;
};

export type ConnectionTinkoffParams = {
  taxation: string;
  terminalId: string;
  invoiceLifetimeMinutes: number;
};

export type ConnectionModulBankParams = {
  taxation: TaxationTypes;
  merchantId: string;
  isTesting: boolean;
  paymentMethods: Array<string>;
  invoiceLifetimeMinutes: number;
};

export type ConnectionSberbankParams = {
  password: string;
  taxation: TaxationTypes;
  login: string;
  isTesting: boolean;
  failUrl: string;
  successUrl: string;
  invoiceLifetimeMinutes: number;
};

export type ConnectionLifepayParams = {
  taxation: string;
  login: string;
};

export type ConnectionChatAPIInstagramParams = {
  username: string;
};

export type ConnectionYClientsParams = {
  timezone: string;
  companyId: number;
};

export type ConnectionPaykeeperParams = {
  userName: string;
  serverUrl: string;
  invoiceLifetimeMinutes: number;
};

export type ConnectionPaypalParams = {
  clientId: string;
  isTesting: boolean;
  invoiceLifetimeMinutes: number;
};

export type ConnectionBepaidParams = {
  shopId: string;
  isTesting: boolean;
  erip: boolean;
  invoiceLifetimeMinutes: number;
};

export type ConnectionYookassaParams = {
  shopId: string;
  returnUrl: string;
  taxation: TaxationTypes;
  invoiceLifetimeMinutes: number;
};

export type Companies = {
  count: number;
  companies: Company[];
};

export type InviteLink = {
  url: string;
};

export type WabaStats = {
  connection_id: number;
  since: string;
  until: string;
  stats: Stats;
};
export type Stats = {
  totalTemplates: number;
  paidTemplates: number;
  totalInbound: number;
  totalOutbound: number;
};
export type Members = {
  count: number;
  companyId: number;
  members: Array<UsersType>;
  currentMemberRole?: RoleType;
};
export type UsersType = {
  roleId: number;
  isOwner: true
  login: string;
  id: number;
  userId: number;
  email: string;
  displayName: string;
  companyId:number
};

export type Connections = {
  count: number;
  connections: NewConnection[];
};

export type AllTypesConnectedType =
  'amocrm'
  |'bitrix'
  |'whatsapp'
  |'telegram'
  |'telegram_bot'
  |'odnoklassniki'
  |'waba'
  |'tinkoff'
  |'modulbank'
  |'sberbank'
  |'lifepay'
  |'chatapi_instagram'
  |'yclients'
  |'paykeeper'
  |'bepaid'
  | 'yookassa'

export type ConnectionLimits = {
  type: string,
  currentValue: number,
  maxValue: number
}

export type ConnectionLimitsRequest = {
  companyId: number,
  limitType: string
}

export type ResGetAllConnForChatType = {
  connectionId: number;
  name: string;
  type: AllTypesConnectedType;
  createChat: {
    withPhone: boolean;
    withUsername: boolean;
  };
  subscription: {
    id: number | null;
    paidUntil: string | null;
  };
};

export type ReqGetAllConnForChatType = {
  companyId: number;
};

export type CreateCompanyRequest = {
  name: string;
  promo_code?: string;
  timezone: string,
  phone?: string;
  payment_currency?: string;
};

export type UpdateCompanyParam = {
  companyId:string
  name: string,
  timezone: string,
  payment_currency: string,
};

export type UserInfo = {
  id: string;
  login: string;
  email: string;
  scopes: Array<string>;
  roles: Array<string>;
  locale: string
  name: string
};

export type ProfileSettings = {
  userId: number;
  language: string;
  notificationsEnabled: boolean;
  firstLogin: boolean;
};

export type EditProfileSettings = {
  language: string;
  notifications_enabled: boolean;
};

export type MarkChatAnswered = {
  reset: boolean;
};

export type TinkoffSchema = {
  name: string;
  params: {
    terminal_id: string;
    terminal_password: string;
    taxation: TaxationTypes;
  };
};

export type SberSchema = {
  name: string;
  params: {
    login: string;
    password: string;
    is_testing: boolean;
    success_url: string;
    fail_url: string;
    taxation: TaxationTypes;
  };
};

export type ModulBankSchema = {
  name: string;
  params: {
    merchant_id: string;
    merchant_password: string;
    is_testing: boolean;
    taxation: TaxationTypes;
    payment_methods:PaymentTypes;
  };
};

export type PayKeeperSchema = {
  name: string;
  params: {
    server_url: string;
    user_name: string;
    password: string;
  };
};

export type WhatsAppSchema = {
  name: string;
};

export type TelegramSchema = {
  name:string
  params:{
  phone: string;
  code?:number
  phone_code_hash?:string
  password?:string
  session_id:string
} };

export type TelegramBotSchema = {
  name:string
  params?:{
  bot_token:string
  }
};

export type TinkoffEditSchema = {
    taxation: string;
    terminal_password: string;
};

export type SberbankEditSchema = {
    password: string;
    success_url: string;
    fail_url: string;
    is_testing?: boolean;
    taxation: string;
}

export type PayPalEditSchema = {
  name: string;
  params?: {
    secret?: string;
    is_testing?: boolean;
  };
};

export type ModulbankEditSchema = {
  name: string;
  params: {
    merchant_password: string;
    is_testing: boolean;
    taxation: TaxationTypes;
    payment_methods: PaymentTypes;
    invoice_lifetime_minutes: number;
  };
};

export type PayKeeperEditSchema = {
    user_name?:string
    password: string;
};

export type YookassaEditSchema = {
  name: string;
  params: {
    return_url?: string;
    taxation: TaxationTypes;
    invoice_lifetime_minutes: number;
  };
};

export type TaxationTypes = 'osn' | 'usn_income' | 'usn_income_outcome' | 'envd' | 'esn' | 'patent';
export type PaymentTypes = 'card_sbp' |'card' | 'sbp'

export type PostTinkoffConnectionRequestData = {
  company_id: number;
  name: string;
  type: 'tinkoff';
  params: {
    terminal_id: string;
    terminal_password: string;
    taxation: TaxationTypes;
  };
};

export type ConnectionStatus = {
  status: 'qr' | 'loading' | 'not_paid' | 'ok' | 'conflict' | 'not_created';
  qrCode?: string;
};

export type ConnectionBlacklist = {
  id: number;
  phone: string;
  createdAt: string;
};

export type RoleType={
  id:number
  name:string,
  systemType:string|null
  permissions:Array<string>
  mockId?:number
}

export type RemoveMemberType={
  companyId:number,
  memberId:number
}
export type AddRoleParamType={
  companyId: string;
  name:string
  permissions?:Array<string>
}

export type ChangeRoleParamType={
  companyId: string;
  roleId:number;
  name:string
  permissions?:Array<string>
}

export type DeleteRoleParamType={
  companyId: number;
  roleId:number;
  replacement_role_id:number
}

export type ChangeRoleMemberParamType={
  companyId: string;
  memberId:number
  roleId:number
}

export type CompaniesListRequest = {
  limit?: number,
  offset?: number,
};

export type MarkChatAnsweredReqParams = {
  connectionId: number;
  chatId: number;
  companyId: number
}

export const addRoleMemberAPI = async (requestOption:AddRoleParamType): Promise<RoleType> =>{
  const { companyId,...rest }=requestOption
  return axiosInstance.post(`/companies/${companyId}/roles/`,rest  );
}
export const changePermissionRoleMemberAPI = async (requestOption:ChangeRoleParamType): Promise<RoleType> =>{
  const { companyId,roleId,...rest }=requestOption
  return axiosInstance.put(`/companies/${companyId}/roles/${roleId}`,rest);
}

export const deleteRoleMemberAPI = async (requestOption:DeleteRoleParamType): Promise<void> =>
  axiosInstance.delete(`/companies/${requestOption.companyId}/roles/${requestOption.roleId}`,{ data:{
    replacement_role_id:requestOption.replacement_role_id
  } } );

export const changeRoleMemberAPI = async (requestOption:ChangeRoleMemberParamType): Promise<UsersType> =>{
  const { companyId,roleId,memberId }=requestOption
  return axiosInstance.patch(`/companies/${companyId}/members/${memberId}`,{ role_id:roleId });
}

export const getConnections = async (id: number): Promise<Connections> =>
  axiosInstance.get(`companies/${id}/connections/`);

export const getConnForChatAPI = async (companyId: number ): Promise<Array<ResGetAllConnForChatType>> =>
  axiosInstance.get(`companies/${companyId}/messaging/chats/sources/`);

export const getCompanies = async (requestOptions: CompaniesListRequest): Promise<Companies> =>
  axiosInstance.get('/companies/', { params: { ...requestOptions } });

export const getCompany = async(companyId: number): Promise<Company> => axiosInstance.get(`/companies/${companyId}`);

export const getCompanyName = async (requestOption: string): Promise<{ companyName: string }> =>
  axiosInstance.get(`/companies/name/${requestOption}`);

export const getConnectionLimits = async (requestOption: ConnectionLimitsRequest): Promise<ConnectionLimits> =>
  axiosInstance.get(`/companies/${requestOption.companyId}/limits/${requestOption.limitType}`);

export const getProfileSettings = async (): Promise<ProfileSettings> => axiosInstance.get('/users/me/profile');

export const patchProfileSettings = async (requestOption: EditProfileSettings): Promise<ProfileSettings> =>
  axiosInstance.patch('/users/me/profile', {
    language: requestOption.language,
    notifications_enabled: requestOption.notifications_enabled,
  });

export const createCompany = async (requestOption: CreateCompanyRequest): Promise<Company> =>
  axiosInstance.post('/companies/', requestOption);

export const updateCompanyAPI = async (requestOption: UpdateCompanyParam): Promise<Company> =>
  axiosInstance.patch(`/companies/${requestOption.companyId}`, { ...requestOption });

export const joinCompany = async (requestOption: string): Promise<UsersType> =>
  axiosInstance.post('/companies/join', undefined, {
    params: {
      code: requestOption,
    },
  });

export const getLink = async (company_id: number): Promise<InviteLink> =>
  axiosInstance.get(`/companies/${company_id}/invite_link`);

export const getWabaStats = async (requestOption: Omit<WabaStats, 'stats'>  & {companyId:number}): Promise<WabaStats> =>
  axiosInstance.get(`companies/${requestOption.companyId}/connections/${requestOption.connection_id}/stats/total`, {
    params: {
      connection_id: requestOption.connection_id,
      since: requestOption.since,
      until: requestOption.until,
    },
  });

export const getMembers = async (companyId: number): Promise<Members> =>
  axiosInstance.get(`/companies/${companyId}/members/`);

export const refreshLink = async (company_id: number): Promise<InviteLink> =>
  axiosInstance.post(`/companies/${company_id}/invite_link`);


export const getRolesCompanyAPI = async (companyId: number): Promise<Array<RoleType>> =>
  axiosInstance.get(`/companies/${companyId}/roles/`);

export const getConnectionBlacklist = async (
  requestOption: {companyId:number, connectionId:number, limit?:number}): Promise<ConnectionBlacklist[]> => {
    const URL = new URLSearchParams();
    URL.set('limit', `${requestOption.limit}`);
    URL.set('company_id', requestOption.companyId.toString());
    URL.set('connection_id', requestOption.connectionId.toString());
    return axiosInstance.get(`companies/${requestOption.companyId}/connections/${requestOption.connectionId}/chats_blacklist/`, {
      params: URL,
    })
};

export const patchMemberRole = async (requestOption: {
  companyId: number;
  memberId: number;
  role: string;
}): Promise<UsersType> =>
  axiosInstance.patch(`/companies/${requestOption.companyId}/members/${requestOption.memberId}`, {
    role: requestOption.role,
  });


export const deleteMember = async (requestOption: RemoveMemberType):Promise<void> =>
  axiosInstance.delete(`/companies/${requestOption.companyId}/members/${requestOption.memberId}`)

export const getUserInfo = async (): Promise<UserInfo> => axiosInstance.get('/users/me');

export const markChatAnswered = async (requestOption: MarkChatAnsweredReqParams): Promise<MarkChatAnswered> =>
  axiosInstance.post(`companies/${requestOption.companyId}/messaging/chats/mark_answered/${requestOption.connectionId}/${requestOption.chatId}`);

export const unsetFirstLogin = async (): Promise<void> => axiosInstance.post('/users/me/profile/first_login');

export const getCompanyOnboarding = async (companyId: number): Promise<CompanyOnboarding> =>
  axiosInstance.get(`/companies/${companyId}/onboarding/`);

export const updateCompanyOnboarding =
  async (requestOptions: CompanyOnboardingUpdate): Promise<CompanyOnboarding> => {
    const { company_id, ...rest } = requestOptions;
    return axiosInstance.patch(`/companies/${company_id}/onboarding/`, rest);
};

export const getAvailableQuestioning = async (): Promise<AvailableQuestioning> => {
  return axiosInstance.get(`/users/me/user_forms/`);
};

export const createQuestioningAPI = async (requestOption: QuestioningCreate): Promise<AvailableQuestioning> => {
  return axiosInstance.post(`/users/me/user_forms/`, requestOption);
};
