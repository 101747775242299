import React, { useContext } from 'react';
import './WabaFileLoad.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import { wabaModeration } from '../Context';
import { configWabaTemplateFile, useWabaTemplateFileType } from "../utils";
import { WabaTemplateComponentHeader, WabaTemplateComponentHeaderCreateRequest } from "../../../templatesTypes";
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";


type Props = {
  header: WabaTemplateComponentHeader | WabaTemplateComponentHeaderCreateRequest,
}

const WabaFileLoad = ({ header }:Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onAttachmentFile, setOnAttachmentFile } = useContext(wabaModeration);
  const currentType = configWabaTemplateFile[header.format];

  const onLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files && e.target.files[0].size > currentType.maxSize){
      dispatch(addAlertWithCustomText({ message: 'Слишком большой файл', type: 'alarm' }));
    } else if (e.target.files) {
      setOnAttachmentFile(e.target.files[0]);
    }
  };

  const onDeleteFile = () => {
    setOnAttachmentFile(undefined);
  };

  return (
    <div className="wabaFileLoad">
      {onAttachmentFile ? (
        <>
          {useWabaTemplateFileType(header.format, onAttachmentFile)}
          <span className='wabaFileLoad__preview_delete' onClick={onDeleteFile}><DeleteIcon /></span>
        </>
      ) : (
        <>
          {currentType.icon}
          <p className="smallText">{t(currentType.title)}</p>
          <p className="smallText">{t(currentType.description)}</p>
          <input type="file" accept={currentType.accept} onChange={(e) => onLoadFile(e)} />
        </>
      )}
    </div>
  );
};

export default WabaFileLoad;
