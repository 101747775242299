import React from 'react';
import './GoBackWrapper.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBack } from "../../assets/arrow-back.svg";
import Button from '../Button';


type GoBackWrapperProps = {
  title: string;
  children: React.ReactNode;
};

const GoBackWrapper = ({ title, children }: GoBackWrapperProps) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const gotoBack = () => goBack();

  return (
    <>
      <div className="goBackWrapper">
        <Button color='white' textType='small' text={t('back')} onClick={gotoBack} image={<ArrowBack />} />
        <h1>{title}</h1>
      </div>
      {children}
    </>
  );
};

export default GoBackWrapper;
