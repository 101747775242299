import React, { useContext } from 'react';
import './RequisiteItem.scss';
import { useTranslation } from 'react-i18next';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { CompanyRequisite } from '../../PartnerCabinetTypes';
import { requisiteItemContext } from '../utils';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg';
import Button from '../../../../components/Button';


type Props = {
  requisite: CompanyRequisite;
};

const RequisiteItem = ({ requisite }: Props) => {
  const { t } = useTranslation();
  const { onDelete, onEdit } = useContext(requisiteItemContext);

  const onEditHandler = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    e.stopPropagation();
    if (onEdit) onEdit(requisite);
  };

  const onDeleteHandler = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete(requisite);
  };

  return (
    <div className="requisiteItem">
      <p className="regularText">{requisite.name}</p>
      <p className="monospaceRegText">{requisite.tin}</p>
      <div className={`requisiteItem__status ${requisite.approvalStatus}`}>
        <p className="regularText middle">
          {t(`partner_cabinet.requisites.statuses.${requisite.approvalStatus.toLowerCase()}`)}
        </p>
        <div className="requisiteItem__status_buttons">
          <WrapperPermission permission="partnersEdit">
            <>
              <Button color="white" textType="small" image={<EditIcon />} onClick={onEditHandler} id='edit' />
              <Button color="white" textType="small" image={<DeleteIcon />} onClick={onDeleteHandler} id='delete' />
            </>
          </WrapperPermission>
        </div>
      </div>
    </div>
  );
};

export default RequisiteItem;
