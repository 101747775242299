import Clients from "./Clients";
import Profile from "./Profile";
import Payments from "./Payments";
import MyRequisites from "./MyRequisites";
import Notifications from "./Notifications";
import { ReactComponent as Receipt } from '../../assets/receipt.svg';
import { ReactComponent as UserIcon } from '../../assets/user-icon.svg';
import { ReactComponent as UserCircle } from '../../assets/user-circle.svg';
import { ReactComponent as RequisitesIcon } from '../../assets/briefcase.svg';
import { ReactComponent as NotificationIcon } from '../../assets/notification.svg';


export const partnersTabs = [
  { id: 'clients', icon: <UserIcon />, Component: Clients, url: '/partners/clients' },
  { id: 'payments', icon: <Receipt />, Component: Payments, url: '/partners/payments' },
  { id: 'profile', icon: <UserCircle />, Component: Profile, url: '/partners/profile' },
  { id: 'requisite', icon: <RequisitesIcon />, Component: MyRequisites, url: '/partners/requisite' },
  { id: 'notifications', icon: <NotificationIcon />, Component: Notifications, url: '/partners/notifications' }
];
