import React, { useEffect, useState } from 'react';
import './FilePayloadContent.scss';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Textarea from '../../Components/Textarea/Textarea';
import { ReactComponent as UploadIcon } from '../../../../assets/upload.svg';
import { templateTypeViews } from '../../../Chat/components/ChatField/WabaTemplateComponent/consts';
import { CreateInteractiveTemplateData, CustomTemplate } from '../../templatesTypes';
import RemoveActionButton from '../../Components/RemoveActionButton/RemoveActionButton';


type Props = {
  payloadType: string;
  onChangeAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  editTemplateData?: CustomTemplate;
  setEditTemplateFileUrl: React.Dispatch<React.SetStateAction<{ fileUrl: string; documentName: string | null } | null>>;
  editTemplateDataFileUrl: {
    fileUrl: string;
    documentName: string | null;
  } | null;
  fileAttachmentError: boolean;
};

type AcceptedTypes = {
  [key: string]: string;
};

const acceptedTypes: AcceptedTypes = {
  image: 'image/jpeg, image/png',
  video: 'video/mp4',
  document: '.pdf',
};

type DropzoneTextContent = {
  [key: string]: {
    firstRow: string;
    secondRow: string;
  };
};

const fileSizeValidation = (file: File) => {
  if (file.size > 104857600) {
    return {
      code: 'size-to-large',
      message: 'The file size is more than 100 MB',
    };
  }
  return null;
};

const FilePayloadContent: React.FC<Props> = ({
  payloadType,
  onChangeAttachedFile,
  editTemplateData,
  setEditTemplateFileUrl,
  editTemplateDataFileUrl,
  fileAttachmentError,
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<CreateInteractiveTemplateData>();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes[payloadType],
    validator: fileSizeValidation,
  });
  const [currentOpenedEmojiPickerId, setCurrentOpenedEmojiPickerId] = useState<number>();
  const [attachedFile, setAttachedFile] = useState<File>();

  const dropzoneTextContent: DropzoneTextContent = {
    image: {
      firstRow: t('templates.add_interactive_templates.template_header.header_dropzone.image.title'),
      secondRow: t('templates.add_interactive_templates.template_header.header_dropzone.image.subtitle'),
    },
    video: {
      firstRow: t('templates.add_interactive_templates.template_header.header_dropzone.video.title'),
      secondRow: t('templates.add_interactive_templates.template_header.header_dropzone.video.subtitle'),
    },
    document: {
      firstRow: t('templates.add_interactive_templates.template_header.header_dropzone.document.title'),
      secondRow: t('templates.add_interactive_templates.template_header.header_dropzone.document.subtitle'),
    },
  };

  useEffect(() => {
    onChangeAttachedFile(acceptedFiles[0]);
    setAttachedFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  useEffect(() => {
    onChangeAttachedFile(undefined);
    setAttachedFile(undefined);
    if (payloadType !== editTemplateData?.params.header.type) {
      setEditTemplateFileUrl(null);
    }
  }, [payloadType]);

  const onRemoveAttachment = () => {
    onChangeAttachedFile(undefined);
    setAttachedFile(undefined);
    setEditTemplateFileUrl(null);
  };

  const getAttachmentPreview = () => {
    if (attachedFile || editTemplateDataFileUrl) {
      const TemplatePreviewComponent = templateTypeViews.get(payloadType);

      if (TemplatePreviewComponent) {
        return (
          <TemplatePreviewComponent
            attachedFile={attachedFile}
            key={attachedFile?.name || editTemplateData?.id}
            setAttachedFiles={onRemoveAttachment}
            width={220}
            height={170}
            removeTrashIcon
            customTemplateEditPayload={editTemplateDataFileUrl || undefined}
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="filePayloadContent">
      {attachedFile || editTemplateDataFileUrl ? (
        <div className="filePayloadContent__attachmentPreview">
          {getAttachmentPreview()}
          <RemoveActionButton onRemoveAction={onRemoveAttachment} />
        </div>
      ) : (
        <>
          <div className={`filePayloadContent__dropzone ${fileAttachmentError && 'error'}`} {...getRootProps()}>
            <div className="filePayloadContent__dropzone_text">
              <UploadIcon />
              <p>{dropzoneTextContent[payloadType].firstRow}</p>
              <p>{dropzoneTextContent[payloadType].secondRow}</p>
            </div>
            <input className="filePayloadContent__dropzone_input" type="file" {...getInputProps()} />
          </div>
          {fileAttachmentError && (
            <p className="filePayloadContent__dropzone_errorMessage">
              {t('templates.add_interactive_templates.template_header.header_dropzone.errors.attachment')}
            </p>
          )}
        </>
      )}
      <Textarea
        title={t('templates.add_interactive_templates.template_header.header_body_title')}
        subtitle={t('templates.add_interactive_templates.template_header.header_body_subtitle')}
        emojiPicker={{
          isOpened: currentOpenedEmojiPickerId === 1,
          setCurrentOpenedEmojiPickerId,
          id: 1,
          onPickEmoji: methods.setValue,
          textAreaValue: methods.getValues,
          textAreaId: 'waba_interactive.body',
        }}
        minRows={2}
        maxRows={4}
        {...methods.register('waba_interactive.body', {
          minLength: {
            value: 1,
            message: t('templates.add_interactive_templates.template_header.header_body_errors.min_length'),
          },
          maxLength: {
            value: 1024,
            message: t('templates.add_interactive_templates.template_header.header_body_errors.max_length'),
          },
          required: true,
        })}
        maxLength={1024}
        errorMessage={
          (methods.formState.errors.waba_interactive?.body?.type === 'required' && (
            <span className="errorMessage">
              {t('templates.add_interactive_templates.template_header.header_body_errors.required')}
            </span>
          )) ||
          (methods.formState.errors.waba_interactive?.body && (
            <span className="errorMessage">{methods.formState.errors.waba_interactive.body.message}</span>
          ))
        }
      />
      <Textarea
        title={t('templates.add_interactive_templates.template_header.header_footer_title')}
        subtitle={t('templates.add_interactive_templates.template_header.header_footer_subtitle')}
        emojiPicker={{
          isOpened: currentOpenedEmojiPickerId === 2,
          setCurrentOpenedEmojiPickerId,
          id: 2,
          onPickEmoji: methods.setValue,
          textAreaValue: methods.getValues,
          textAreaId: 'waba_interactive.footer',
        }}
        minRows={2}
        maxRows={4}
        {...methods.register('waba_interactive.footer', {
          minLength: {
            value: 1,
            message: t('templates.add_interactive_templates.template_header.header_footer_errors.min_length'),
          },
          maxLength: {
            value: 60,
            message: t('templates.add_interactive_templates.template_header.header_footer_errors.max_length'),
          },
        })}
        maxLength={60}
        errorMessage={
          methods.formState.errors.waba_interactive?.footer && (
            <span className="errorMessage">{methods.formState.errors.waba_interactive.footer.message}</span>
          )
        }
      />
    </div>
  );
};

export default FilePayloadContent;
