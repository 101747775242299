import React, { useEffect, useState } from 'react';
import './ChatField.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomTemplate, WabaTemplate } from '../../../Templates/templatesTypes';
import Search from '../../../../components/Search/Search';
import { fetchCustomTemplates, fetchWabaTemplates } from '../../../Templates/templatesSlice';
import { customTemplatesSelector, wabaInteractiveSelector, wabaTemplatesSelector } from '../../../Templates/templatesSelector';
import { allConnectionsForChatSelector, currentCompanySelector } from '../../companiesSelector';
import { currentChatSelector } from '../../chatsSelector';
import { getTemplatesChat } from './helpers';

const listTemplates=[
  { template:'whatsApp',name:'WhatsApp' },
  { template:'my',name:'chats.templates.my' },
  { template:'interactive',name:'chats.templates.interactive' },
]

type Props = {
  textAreaIsBlocked: boolean;
  setSelectedWabaTemplate: React.Dispatch<React.SetStateAction<WabaTemplate | null | undefined>>;
  setSelectedCustomTemplate: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>>;
  setSelectedInteractiveTemplate: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>>;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  onSelectTab: (e: React.MouseEvent<HTMLDivElement>) => void;
  searchedValueOfTemplate: string;
  setSearchedValueOfTemplate: React.Dispatch<React.SetStateAction<string>>;
  setText: React.Dispatch<React.SetStateAction<string>>;
  onCloseDropdown: (e: React.MouseEvent<unknown, MouseEvent>) => void;
};

const Templates: React.FC<Props> = ({
  textAreaIsBlocked,
  setActiveTab,
  setSelectedWabaTemplate,
  setSelectedCustomTemplate,
  setSelectedInteractiveTemplate,
  activeTab,
  onSelectTab,
  searchedValueOfTemplate,
  setSearchedValueOfTemplate,
  onCloseDropdown,
}) => {
  const dispatch = useDispatch();
  const currentCompany = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const customTemplates = useSelector(customTemplatesSelector);
  const wabaTemplates = useSelector(wabaTemplatesSelector);
  const allConnections = useSelector(allConnectionsForChatSelector);
  const interactiveTemplates = useSelector(wabaInteractiveSelector);
  const currentCompanyId = useSelector(currentCompanySelector);
  const currentChat = useSelector(currentChatSelector);
  const [isCurrentChatTypeWhatsApp, setIsCurrentChatTypeWhatsApp] = useState(false);
  const [currentConnectionType, setCurrentConnectionType] = useState('');
  const { t } = useTranslation()

  const myTemplatesList = ['whatsapp', 'telegram', 'telegram_bot']

  useEffect(() => {
    if (allConnections) {
      const currentConnection = allConnections.find(
        (connection) => currentChat.connectionId === connection.connectionId);
      if (currentConnection) {
        if (myTemplatesList.includes(currentConnection.type)) {
          setActiveTab('my');
        }
        if (!isCurrentChatTypeWhatsApp && currentConnection
          && currentConnection.type === 'whatsapp'
          || currentConnection.type === 'telegram_bot'
        ) {
          setIsCurrentChatTypeWhatsApp(true);
        }
        setCurrentConnectionType(currentConnection.type);
      }
    }
  }, []);

  const availableTemplatesTypes = () => {
    if (currentConnectionType && !myTemplatesList.includes(currentConnectionType)) {
      return (
        <div className="templates__upperBlock__templatesSwitch">
          {listTemplates.map(i=>
            <div
              key={i.template}
              id={i.template}
              className={
              `templates__upperBlock__templatesSwitch_item ${activeTab === i.template ? 'active':''}`
            }
              onClick={onSelectTab}
            >
              <p>{t(i.name)}</p>
            </div>
          )}
        </div>
      );
    }
    return null;
  };


  useEffect(() => {
    dispatch(fetchCustomTemplates({ companyId: currentCompanyId.id }));
  }, [currentCompanyId]);

  useEffect(() => {
    if (currentChat) {
      const chatConnection = allConnections.find(
        (currentConnection) => currentConnection.connectionId === currentChat.connectionId
      );

      if (chatConnection && !myTemplatesList.includes(chatConnection.type)
        && currentCompany?.params.companyId) {
        dispatch(fetchWabaTemplates({
          companyId:Number(currentCompany?.params.companyId),
          connectionId: currentChat.connectionId as number }));
      }
    }
  }, [currentChat.connectionId]);

  return (
    <div className="templates">
      <div className={`templates__upperBlock ${textAreaIsBlocked || (isCurrentChatTypeWhatsApp && 'blocked')}`}>
        {!textAreaIsBlocked && availableTemplatesTypes()}
        <Search onChangeSearchInput={setSearchedValueOfTemplate} value={searchedValueOfTemplate} />
      </div>
      <div className="template">{getTemplatesChat(
        wabaTemplates,
        searchedValueOfTemplate,
        activeTab,
        setSelectedWabaTemplate,
        customTemplates,
        onCloseDropdown,
        interactiveTemplates,
        setSelectedInteractiveTemplate,
        setSelectedCustomTemplate
      )}</div>
    </div>
  );
};

export default Templates;


