import '../Checkbox/Checkbox.scss';
import { useFormContext } from 'react-hook-form';


type CheckControlProps = {
  label: string;
  name: string;
  value: string;
  disabled?:boolean
};

const CheckControl = ({ label, name, value,disabled }: CheckControlProps) => {
  const { register } = useFormContext();

  return(
    <label className="checkbox" >
      <input {...register(name)} type="checkbox" value={value} disabled={disabled}/>
      <span className="checkmark"/>
      {label}
    </label>
  )
}

export default CheckControl;