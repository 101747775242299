import './ClientNotifications.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useClientsChannels } from '../../ulils/utils';
import ChannelIcon from '../../../Notifications/components/ChannelIcon';


const ClientNotifications = () => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const channels = useClientsChannels();

  return (
    <div className='clientNotifications'>
      <h4>{t('partner_cabinet.clients.channels_notification')}</h4>
      {channels.length === 0
       ?  <p className="smallText linkText">
            <Trans i18nKey='partner_cabinet.clients.channels_notification_link'>
              Add<Link to={`${url?.url}/partners/notifications`}>link</Link>
            </Trans>
          </p>
       : channels.map((channel) => (
        <p key={channel.id} className="regularText">
          <ChannelIcon channel={channel.type} />
          {channel.type === 'whatsapp' && parsePhoneNumberFromString(channel.name)?.formatInternational()}
          {channel.type !== 'whatsapp' && channel.name}
        </p>
      ))}
    </div>
  );
};

export default ClientNotifications;
